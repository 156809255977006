import { ASSET_TYPE_TRANSLATIONS_ENGLISH } from '@/modules/asset-types/translations/en';
import { DASHBOARD_TRANSLATIONS_ENGLISH } from '@/modules/dashboards/translations/en';
import { EVENT_TRANSLATIONS_ENGLISH } from '@/modules/events/translations/en';
import { IMPORTER_TRANSLATIONS_ENGLISH } from '@/modules/importer/translations/en';
import { APPLICATION_LOG_TRANSLATIONS_ENGLISH } from '@/modules/application-log';
import { ResourceLanguage } from 'i18next';
import { DEVICE_TRANSLATIONS_ENGLISH } from '@/modules/devices/translations/en';
import { TRACKER_TRANSLATIONS_ENGLISH } from '@/modules/trackers/translations/en';
import { LABELS_TRANSLATIONS_ENGLISH } from '@/modules/labels/translations/en';
import { BALANCE_TRANSLATIONS_ENGLISH } from '@/modules/balances/translations/en';
import { MAP_TRANSLATIONS_ENGLISH } from '@/modules/map/translations/en';
import { LOCATION_TRANSLATIONS_ENGLISH } from '@/modules/locations/translations/en';
import { SETTINGS_TRANSLATIONS_ENGLISH } from '@/modules/settings/translations/en';

export const enTranslations: ResourceLanguage = {
  translation: {
    ...APPLICATION_LOG_TRANSLATIONS_ENGLISH,
    ...ASSET_TYPE_TRANSLATIONS_ENGLISH,
    ...BALANCE_TRANSLATIONS_ENGLISH,
    ...DASHBOARD_TRANSLATIONS_ENGLISH,
    ...DEVICE_TRANSLATIONS_ENGLISH,
    ...EVENT_TRANSLATIONS_ENGLISH,
    ...IMPORTER_TRANSLATIONS_ENGLISH,
    ...LABELS_TRANSLATIONS_ENGLISH,
    ...LOCATION_TRANSLATIONS_ENGLISH,
    ...MAP_TRANSLATIONS_ENGLISH,
    ...SETTINGS_TRANSLATIONS_ENGLISH,
    ...TRACKER_TRANSLATIONS_ENGLISH,
    sign_in: 'Sign In',
    search: 'Search',
    filter: 'Filter',
    delete: 'Delete',
    save: 'Save',
    noData: 'No data',
    image_upload: 'Upload Image',
    image_upload_new: 'Upload New Image',
    image_remove: 'Remove Image',
    showing_first: 'Showing first {{amount}}',
    details: 'Details',
    unknown: 'Unknown',
    at: 'at',
    total: 'Total',
    totals: 'Totals',
    code: 'Code',
    type: 'Type',
    identifier: 'Identifier',
    name: 'Name',
    overview: 'Overview',
    export_excel: 'Export to Excel',
    refresh: 'Refresh',
    select_all: 'Select All',
    deselect_all: 'Deselect All',
    assets: 'Assets',
    occurred_at: 'Occurred At',
    last_seen: 'Last seen',
    general: 'General',
    error_loading_data: 'Error loading data',
    error_loading_data_verbose: 'There was an error loading the data.',
    error_occurred: 'An error occurred',
    cancel: 'Cancel',
    copied: 'Copied!',
  },
};
