import { MAP_ROUTES } from '@/modules/map';
import { ASSET_ROUTES } from '@/modules/assets';
import { DASHBOARD_ROUTES } from '@/modules/dashboards';
import App from '@/App';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { ApplicationLayout, PublicLayout, LoginPage } from '@/modules/application';
import { CONFIGURATION_ROUTES } from '@/modules/configuration';
import { LOCATION_ROUTES } from '@/modules/locations';
import { EVENT_ROUTES } from '@/modules/events';
import { ErrorPage } from '@/modules/application/pages/ErrorPage';
import MobileLayout from '@/modules/mobile/layouts/MobileLayout';
import { MOBILE_ROUTES } from '@/modules/mobile/routes/mobile.routes';
import { BALANCE_ROUTES } from '@/modules/balances/routes/balance.routes';
import { TRANSACTION_ROUTES } from '@/modules/transactions/routes/transactions.routes';
import FirstAccessibleRoute from '@/components/permissions/FirstAccessibleRoute';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        element: <PublicLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: 'login',
            element: <LoginPage />,
          },
        ],
      },
      {
        path: 'app',
        element: <ApplicationLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: (
              <FirstAccessibleRoute
                parentPath="/app"
                routes={[
                  /**
                   * All App Module Routes go below
                   */
                  ...DASHBOARD_ROUTES,
                  ...MAP_ROUTES,
                  ...ASSET_ROUTES,
                  ...CONFIGURATION_ROUTES,
                  ...LOCATION_ROUTES,
                  ...EVENT_ROUTES,
                  ...BALANCE_ROUTES,
                  ...TRANSACTION_ROUTES,
                ]}
              />
            ),
          },
          /**
           * All App Module Routes go below
           */
          ...DASHBOARD_ROUTES,
          ...MAP_ROUTES,
          ...ASSET_ROUTES,
          ...CONFIGURATION_ROUTES,
          ...LOCATION_ROUTES,
          ...EVENT_ROUTES,
          ...BALANCE_ROUTES,
          ...TRANSACTION_ROUTES,
        ],
      },
      {
        path: 'mobile',
        element: <MobileLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: (
              <FirstAccessibleRoute
                parentPath="/mobile"
                routes={[
                  /**
                   * All Mobile Module Routes go below
                   */
                  ...MOBILE_ROUTES,
                ]}
              />
            ),
          },
          ...MOBILE_ROUTES,
        ],
      },
      {
        path: '*',

        element: <Navigate to="/app" replace />,
      },
    ],
  },
]);
