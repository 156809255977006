import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BackButton } from '@/components/BackButton';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import LabelFormApi from '@/modules/labels/components/label-form/LabelFormApi';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Skeleton, Tab } from '@mui/material';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';

import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { labelService } from '../../api/label.service';
import { LabelModel } from '../../models/LabelModel';
import { useTranslation } from '@/lib';

interface LabelDetailPageProps {
  isCreate?: boolean;
}

export const LabelDetailPage: FunctionComponent<LabelDetailPageProps> = ({ isCreate }) => {
  const { labelId } = useParams();
  const { t } = useTranslation();

  const [value, setValue] = useState('1');
  const [label, setLabel] = useState<LabelModel>();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (labelId) {
      labelService.getById(Number.parseInt(labelId)).then((label) => {
        if (label.isSuccess) {
          setLabel(label.payload);
        }
      });
    }
  }, []);
  return (
    <TabContext value={value}>
      <TabbedLayout
        topBar={
          <TabbedLayoutTopBar>
            <BackButton pathname="../" />
            <TabbedLayoutTopBarHeader icon={null}>
              {isCreate ? `${t('label.new')}` : label?.dto.name ? `${t('label.label_one')} - ${label.dto.name}` : <Skeleton width={200} />}
            </TabbedLayoutTopBarHeader>
            <TabbedLayoutTopBarVerticalSeperator />
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('details')} value="1" />
            </TabList>
          </TabbedLayoutTopBar>
        }
      >
        <TabbedPageLayout>
          <TabbedPageLayoutBody>
            <div className="flex h-full flex-grow flex-col ">
              <TabPanel className="p-0 pb-20 " value="1" style={{ flexGrow: 1 }}>
                <LabelFormApi isCreate={isCreate} labelId={labelId ? Number.parseInt(labelId) : undefined} />
              </TabPanel>
            </div>
          </TabbedPageLayoutBody>
        </TabbedPageLayout>
      </TabbedLayout>
    </TabContext>
  );
};
