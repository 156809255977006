/* eslint-disable no-useless-escape */
import axios, { type AxiosProgressEvent, type AxiosRequestConfig, type AxiosError, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import { isNil, isEmpty } from 'lodash-es';
import { ApiResponse } from './api-response';
import { ApiResponseCode } from './api-response-code';
import { encodeQueryString } from './api-utils';

const DEFAULT_HEADERS: RawAxiosRequestHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// Type guard to check if an error is an AxiosError
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAxiosError<T = unknown, D = unknown>(error: any): error is AxiosError<T, D> {
  return error.isAxiosError === true;
}

class BaseClient {
  public baseUrl = 'unset';
  public token = '';

  public setBaseUrl(env: string, tenant: string) {
    this.baseUrl = `https://api-${env}-${tenant}.posito.io/`;
  }

  public setBaseUrlOverride(devApiUrl: string) {
    this.baseUrl = devApiUrl;
  }

  public setToken(token: string) {
    this.token = token;
  }

  public getHeaders(multipart = false): RawAxiosRequestHeaders | undefined {
    const defaultHeaders = DEFAULT_HEADERS;

    if (multipart) {
      return undefined;
    }

    if (!isNil(this.token)) {
      defaultHeaders.Authorization = `Bearer ${this.token}`;
    }

    return defaultHeaders;
  }

  public async post<T>(uri: string, data: unknown, parameters: Record<string, unknown> = {}) {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }
    try {
      const config: AxiosRequestConfig = {
        headers: this.getHeaders(),
        withCredentials: false,
        baseURL: this.baseUrl,
      };

      const response = await axios.post<T>(uri, data, config);

      return ApiResponse.responseWithPayload(response.data, response.status);
    } catch (error) {
      if (isAxiosError<T>(error)) {
        if (error && error.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(error.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  public async patch<T>(uri: string, data: unknown, parameters: Record<string, unknown> = {}) {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }
    try {
      const config: AxiosRequestConfig = {
        headers: this.getHeaders(),
        withCredentials: false,
        baseURL: this.baseUrl,
      };

      const response = await axios.patch<T>(uri, data, config);

      return ApiResponse.responseWithPayload(response.data, response.status);
    } catch (error) {
      if (isAxiosError<T>(error)) {
        if (error && error.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(error.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  public async put<T>(uri: string, data: unknown, parameters: Record<string, unknown> = {}) {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }
    try {
      const config: AxiosRequestConfig = {
        headers: this.getHeaders(),
        withCredentials: false,
        baseURL: this.baseUrl,
      };

      const response = await axios.put<T>(uri, data, config);

      return ApiResponse.responseWithPayload(response.data, response.status);
    } catch (error) {
      if (isAxiosError<T>(error)) {
        if (error && error.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(error.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  public async delete<T>(uri: string) {
    try {
      const config: AxiosRequestConfig = {
        headers: this.getHeaders(),
        withCredentials: false,
        baseURL: this.baseUrl,
      };

      const response = await axios.delete<T>(uri, config);

      return ApiResponse.responseWithPayload(response.data, response.status);
    } catch (error) {
      if (isAxiosError<T>(error)) {
        if (error && error.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(error.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  public async get<T>(uri: string, data: Record<string, unknown> = {}) {
    if (Object.keys(data).length > 0) {
      uri = `${uri}?${encodeQueryString(data)}`;
    }

    try {
      const response = await axios({
        method: 'get',
        url: uri,
        baseURL: this.baseUrl,
        withCredentials: false,
        headers: this.getHeaders(),
      });
      return new ApiResponse<T>({
        payload: response.data as T,
        responseCode: ApiResponseCode.SUCCESS,
        httpResponseCode: response.status,
      });
    } catch (error) {
      if (isAxiosError<T>(error)) {
        if (error && error.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(error.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  public postWithoutCredentials<T>(uri: string, data: unknown, parameters: Record<string, unknown> = {}) {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }

    return axios
      .post<T>(uri, data, {
        withCredentials: false,
      })
      .then((res: AxiosResponse<T, unknown>) => res.data);
  }

  public getRaw<T>(uri: string, data = {}) {
    if (Object.keys(data).length > 0) {
      uri = `${uri}?${encodeQueryString(data)}`;
    }

    return axios
      .get<T>(uri, {
        headers: this.getHeaders(),
        withCredentials: false,
      })
      .then((res: AxiosResponse<T, unknown>) => res);
  }

  // public upload(uri: string, data: any) {
  //   return fetch(uri, {
  //     headers: this.getHeaders(true),
  //     method: 'POST',
  //     body: data,
  //   });
  // }

  public async uploadFiles<T>(uri: string, formData: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) {
    try {
      const response = await axios.post<T>(uri, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.token}`,
        },
        baseURL: this.baseUrl,
        onUploadProgress,
      });
      return new ApiResponse<T>({
        payload: response.data as T,
        responseCode: ApiResponseCode.SUCCESS,
        httpResponseCode: response.status,
      });
    } catch (error) {
      if (isAxiosError<T>(error)) {
        if (error && error.response) {
          return ApiResponse.responseWithCodeAndMessage<T>(this.getErrorResponse(error.response));
        }
      }
      return ApiResponse.responseWithCode<T>(ApiResponseCode.UNKNOWN_ERROR, 0);
    }
  }

  /* public async downloadFile<T>(
    uri: string,
    data: any,
    parameters: any = {}
  ): Promise<{
    filename: string
    data: any
  }> {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`
    }

    const headers = this.getHeaders()
    headers['Access-Control-Expose-Headers'] = 'Content-Disposition'

    const response = await axios.post<T>(uri, data, {
      headers: this.getHeaders(),
      withCredentials: false,
      responseType: 'blob',
    })

    // Extract filename from header
    const filename = response.headers['content-disposition']
      .split(';')
      .find((n: string) => n.includes('filename='))
      .replace('filename=', '')
      .replace(/\"/g, '')
      .trim()

    return {
      filename,
      data: response.data,
    }
  } */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getErrorResponse(axiosResponse: AxiosResponse<any, any>): {
    message: string;
    code: ApiResponseCode;
    httpResponseCode?: number | undefined;
  } {
    return {
      message: !isNil(axiosResponse.data.message) && !isEmpty(axiosResponse.data.message) ? axiosResponse.data.message : 'Unknown error',
      code:
        !isNil(axiosResponse.data.statusCode) && !isEmpty(axiosResponse.data.statusCode)
          ? (axiosResponse.data.statusCode as ApiResponseCode)
          : ApiResponseCode.UNKNOWN_ERROR,
      httpResponseCode: axiosResponse?.status,
    };
  }
}

const apiClient = new BaseClient();

const mapboxClient = new BaseClient();
mapboxClient.baseUrl = 'https://api.mapbox.com/geocoding/v5/';

export { apiClient, mapboxClient };
