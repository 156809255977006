import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { LayoutActionsProvider } from '@/providers/layout-actions-provider/LayoutActionsProvider';
import { useState, useEffect, FunctionComponent } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { locationService } from '../api/locations/location.service';
import { LocationModel } from '../types/LocationModel';
import { BackButton } from '@/components/BackButton';
import NavigationTabs from '@/modules/application/components/NavigationTabs';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import { Alert, Skeleton, Tooltip } from '@mui/material';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabMenuItem } from '@/modules/application/types/MenuItem';

export const LocationDetailsLayout: FunctionComponent = () => {
  const { locationId } = useParams();
  const locationIdNumber = locationId ? Number.parseInt(locationId) : 0;
  const isCreate = locationId === 'create';

  const [location, setLocation] = useState<LocationModel | undefined>(undefined);

  useEffect(() => {
    if (locationId) {
      locationService.getById(Number.parseInt(locationId)).then((location) => {
        if (location.isSuccess) {
          setLocation(location.payload);
        }
      });
    }
  }, []);

  const tabItems: TabMenuItem[] = [];

  if (isCreate) {
    tabItems.push({
      activeRouteIds: ['/app/locations/create'],
      label: 'Details',
      url: '/app/locations/create',
    });
  } else {
    tabItems.push({
      activeRouteIds: [`/app/locations/${locationIdNumber}/details`],
      label: 'Details',
      url: `/app/locations/${locationIdNumber}/details`,
    });
    tabItems.push({
      activeRouteIds: [`/app/locations/${locationIdNumber}/events`],
      label: 'Events',
      url: `/app/locations/${locationIdNumber}/events`,
    });
    tabItems.push({
      activeRouteIds: [`/app/locations/${locationIdNumber}/balance`],
      label: 'Balance',
      url: `/app/locations/${locationIdNumber}/balance`,
    });
    tabItems.push({
      activeRouteIds: [`/app/locations/${locationIdNumber}/assets`],
      label: 'Assets',
      url: `/app/locations/${locationIdNumber}/assets`,
    });
  }

  return (
    <LayoutActionsProvider>
      <TabbedLayout
        topBar={
          <TabbedLayoutTopBar>
            <BackButton pathname="/app/locations/overview" />
            <TabbedLayoutTopBarHeader icon={null}>
              {isCreate ? 'New Location' : location?.dto.name ? `Location - ${location.dto.name}` : <Skeleton width={200} />}
            </TabbedLayoutTopBarHeader>

            <TabbedLayoutTopBarVerticalSeperator />
            <NavigationTabs tabItems={tabItems} />
          </TabbedLayoutTopBar>
        }
      >
        <TabbedPageLayout>
          <TabbedPageLayoutBody>
            <div className="flex h-full flex-grow flex-col ">
              {location?.dto.isSystem && (
                <Alert severity="info" className="mb-4">
                  This location is a system location and it cannot be deleted nor can the code be changed.
                  <Tooltip
                    title=" System Locations are created by Posito and are used for
                  certain predefined functionalities. Posito uses the Location Code to identify the System Location and thus cannot be changed."
                  >
                    <span className="font-semibold pl-1 underline decoration-dotted">More Info</span>
                  </Tooltip>
                </Alert>
              )}
              <Outlet />
            </div>
          </TabbedPageLayoutBody>
        </TabbedPageLayout>
      </TabbedLayout>
    </LayoutActionsProvider>
  );
};
