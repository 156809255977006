import { Navigate, RouteObject } from 'react-router-dom';
import { DevicesPage } from '../pages/configuration/DevicePage';
import { DeviceDetailPage } from '../pages/configuration/DeviceDetailPage';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import RequirePermissionRoute from '@/components/permissions/RequirePermissionRoute';

export const DEVICE_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'Devices',
    children: [
      {
        index: true,
        element: (
          <RequirePermissionRoute requiredPermission={Permission.DEVICES_VIEW}>
            <Navigate to="overview" replace />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'overview',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.DEVICES_VIEW}>
            <DevicesPage />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'create',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.DEVICES_EDIT}>
            <DeviceDetailPage isCreate={true} />
          </RequirePermissionRoute>
        ),
      },
      {
        path: 'detail/:deviceId',
        element: (
          <RequirePermissionRoute requiredPermission={Permission.DEVICES_VIEW}>
            <DeviceDetailPage />
          </RequirePermissionRoute>
        ),
      },
    ],
  },
];
