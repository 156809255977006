export enum UserSettingType {
  'STRING' = 'STRING',
  'NUMBER' = 'NUMBER',
  'BOOLEAN' = 'BOOLEAN',
  'JSON' = 'JSON',
}

// String can be either JSON or an actual String value
export type UserSettingValue = number | string | boolean | object;

export enum UserSettingKey {
  'DARK_MODE_ENABLED' = 'DARK_MODE_ENABLED',
  'BALANCE_OVERVIEW_COLUMN_STATE' = 'BALANCE_OVERVIEW_COLUMN_STATE',
  'BALANCE_OVERVIEW_HIERARCHICAL_COLUMN_STATE' = 'BALANCE_OVERVIEW_HIERARCHICAL_COLUMN_STATE',
}

export interface UserSettingReadDTO {
  id: number;
  key: UserSettingKey;
  type: UserSettingType;
  value: UserSettingValue;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface UserSettingUpdateDTO {
  key: UserSettingKey;
  type: UserSettingType;
  value: UserSettingValue;
}
