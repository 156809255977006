import { Point } from 'geojson';

export interface IGeocoderContext {
  id: string;
  wikidata: string;
  text: string;
}

export interface IGeocoderFeature {
  id: string;
  type: 'Feature';
  place_type: Array<string>;
  relevance: number;
  properties: unknown;
  address: string;
  text: string;
  place_name: string;
  bbox: [number, number, number, number];
  /**
   * The coordinates of the feature’s center in the form [longitude,latitude]. This may be the literal centroid of the feature’s geometry, or the center of human activity within the feature (for example, the downtown area of a city).
   */
  center: [number, number];
  geometry: Point;
  context: Array<IGeocoderContext>;
}

export interface IGeocoderResult {
  type: 'FeatureCollection';
  query: Array<string | number>;
  features: Array<IGeocoderFeature>;
  attribution: string;
}

export interface IAddress {
  address?: string;
  postcode?: string;
  place?: string;
  locality?: string;
  country?: string;
  region?: string;
  disctrict?: string;
  neighborhood?: string;
  poi?: string;
}

/**
 * Various types of geographic features availabled in the Mapbox geocoder.
 *
 * @see https://docs.mapbox.com/api/search/#data-types
 */
export enum MapboxPlaceType {
  COUNTRY = 'country',
  REGION = 'region',
  POSTCODE = 'postcode',
  DISTRICT = 'district',
  PLACE = 'place',
  LOCALITY = 'locality',
  NEIGHBORHOOD = 'neighborhood',
  ADDRESS = 'address',
  POI = 'poi',
}

/**
 * The geocoding API includes two different endpoints: mapbox.places and mapbox.places-permanent.
 *
 * @see https://docs.mapbox.com/api/search/#mapboxplaces
 */
export enum MapboxEndpoint {
  PLACES = 'mapbox.places',
  PLACES_PERMANENT = 'mapbox.places-permanent',
}
