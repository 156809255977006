import { MenuItemGroup } from '@/modules/application';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const SETTING_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'Settings',
    children: [
      {
        activeRouteIds: ['/app/configuration/settings/events'],
        label: 'Events',
        url: '/app/configuration/settings/events',
        requiredPermission: Permission.API_SETTINGS_VIEW,
      },
      {
        activeRouteIds: ['/app/configuration/settings/connectors'],
        label: 'Connectors',
        url: '/app/configuration/settings/connectors',
        requiredPermission: Permission.CONNECTORS_VIEW,
      },
      {
        activeRouteIds: ['/app/configuration/settings/api'],
        label: 'API',
        url: '/app/configuration/settings/api',
        requiredPermission: Permission.API_SETTINGS_VIEW,
      },
    ],
  },
];
