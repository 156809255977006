import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { LocationCreateFormSchema, LocationFormModel, LocationFormSchema } from './LocationFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import LocationFormDisplay from './LocationFormDisplay';

interface LocationFormLogicProps {
  initialValues: LocationFormModel;
  onSubmit: (data: LocationFormModel) => Promise<boolean>;
  isCreate?: boolean;
  locationId?: number;
}

const LocationFormLogic: FunctionComponent<LocationFormLogicProps> = ({ initialValues, onSubmit, isCreate, locationId }) => {
  const form = useForm<LocationFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? LocationCreateFormSchema : LocationFormSchema),
  });

  const handleSubmit = async (data: LocationFormModel) => {
    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return <LocationFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} locationId={locationId} />;
};

export default LocationFormLogic;
