import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BackButton } from '@/components/BackButton';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import TrackerFormApi from '@/modules/trackers/components/tracker-form/TrackerFormApi';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Skeleton, Tab } from '@mui/material';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { trackerService } from '../../api/trackers/tracker.service';
import { TrackerModel } from '@/modules/trackers/types/TrackerModel';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { useTranslation } from '@/lib';

interface TrackerDetailPageProps {
  isCreate?: boolean;
}

export const TrackerDetailPage: FunctionComponent<TrackerDetailPageProps> = ({ isCreate }) => {
  const { trackerId } = useParams();
  const { t } = useTranslation();

  const [value, setValue] = useState('1');
  const [tracker, setTracker] = useState<TrackerModel | undefined>(undefined);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (trackerId) {
      trackerService.getById(Number.parseInt(trackerId)).then((tracker) => {
        if (tracker.isSuccess) {
          setTracker(tracker.payload);
        }
      });
    }
  }, []);
  return (
    <TabContext value={value}>
      <TabbedLayout
        topBar={
          <TabbedLayoutTopBar>
            <BackButton pathname="../" />
            <TabbedLayoutTopBarHeader icon={null}>
              {isCreate ? t('tracker.new') : tracker?.uniqueIdentifier ? `${t('tracker.tracker_one')} - ${tracker.uniqueIdentifier}` : <Skeleton width={200} />}
            </TabbedLayoutTopBarHeader>
            <TabbedLayoutTopBarVerticalSeperator />
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('details')} value="1" />
            </TabList>
          </TabbedLayoutTopBar>
        }
      >
        <TabbedPageLayout>
          <TabbedPageLayoutBody>
            <div className="flex h-full flex-grow flex-col ">
              <TabPanel className="p-0" value="1" style={{ flexGrow: 1, height: '100%' }}>
                <TrackerFormApi isCreate={isCreate} trackerId={trackerId ? Number.parseInt(trackerId) : undefined} />
              </TabPanel>
            </div>
          </TabbedPageLayoutBody>
        </TabbedPageLayout>
      </TabbedLayout>
    </TabContext>
  );
};
