import { BASE_GRID_OPTIONS, PoGrid } from '@/components/grid/PoGrid';
import { Button } from '@mui/material';
import { FunctionComponent, useMemo, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AssetModel } from '../types/AssetModel';
import { assetService } from '../api/assets/asset.service';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import {
  CellClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  MenuItemDef,
  RowDoubleClickedEvent,
  SortChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import TextFilter from '@/components/filterbar/filters/TextFilter';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import FilterBar from '@/components/filterbar/FilterBar';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import LocationFilter from '@/components/filterbar/filters/LocationFilter';
import AssetTypeFilter from '@/components/filterbar/filters/AssetTypeFilter';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import { WidgetsRounded } from '@mui/icons-material';
import LinkCellRenderer, { LinkCellRendererParams } from '@/components/grid/cells/LinkCellRenderer';
import { TabMenuItem } from '@/modules/application/types/MenuItem';
import { TrackerType } from '@/modules/trackers';
import { TrackerUniqueIdentifierChip } from '@/modules/trackers/components/TrackerUniqueIdentifierChip';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import { PagePaginationResultDto, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { AssetSortOption } from '../api/assets/asset.contracts';
import { ColumnID } from '@/components/grid/column-ids';
import dayjs from 'dayjs';
import { useExportToExcel } from '@/hooks/useExportToExcel';
import NumberFilter from '@/components/filterbar/filters/NumberFilter';
import { PeriodInDaysCellRenderer } from '@/components/grid/cells/PeriodInDaysCellRenderer';
import { MoveAssetsToLocationModal } from '@/modules/locations/components/MoveAssetsToLocationModal';
import { ActionBar } from '@/modules/application';
import { useApiCall } from '@/hooks/useApiCall';
import PaginationControls from '@/components/grid/PaginationControls';
import PagedResultDataText from '@/components/filterbar/PagedResultDataText';

const ASSET_FETCH_LIMIT = 1000;

export const AssetsPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<AssetModel[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isMoveAssetModalOpen, setIsMoveAssetModalOpen] = useState(false);

  const { setFiltersToUrl, filters } = useQueryParamsFilters(
    {
      pageNumber: searchParams.get('pageNumber') ? Number.parseInt(searchParams.get('pageNumber')!) : 1,
    },
    CacheKey.EVENT_FILTERS,
  );

  const {
    data: assetData,
    isLoading: assetIsLoading,
    isError: assetIsError,
    fetchData: fetchAssets,
    setApiCallArg,
  } = useApiCall<PagePaginationResultDto<AssetModel>>(() =>
    assetService.get(
      {
        searchText: filters.current.searchText,
        locationId: filters.current.locationId,
        assetTypeId: filters.current.assetTypeId,
        stayTimeInDays: filters.current.stayTimeInDays,
      },
      {
        page: filters.current.pageNumber,
        limit: ASSET_FETCH_LIMIT,
        order: filters.current.sortDirection,
        sort: filters.current.sortOption,
      },
    ),
  );

  const { exportToExcel } = useExportToExcel();

  const onCellClicked = useCallback((params: CellClickedEvent<AssetModel>) => {
    if (params.column.getColId() === ColumnID.SELECTION_CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const columns: ColDefOrGroup<AssetModel>[] = useMemo(() => {
    const columns: ColDefOrGroup<AssetModel>[] = [
      {
        colId: ColumnID.SELECTION_CHECKBOX,
        checkboxSelection: true,
        headerCheckboxSelection: false,
        resizable: false,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        lockVisible: true,
        sortable: false,
      },
      {
        colId: ColumnID.ASSET_CODE,
        field: 'code',
      },
      {
        colId: ColumnID.ASSET_TYPE_NAME,
        headerName: 'Type',
        valueGetter: (params: ValueGetterParams<AssetModel>) => {
          return params.data?.assetType.name;
        },
      },
      {
        colId: ColumnID.LOCATION_NAME,
        field: 'location.name',
        headerName: 'Location',
        cellRenderer: LinkCellRenderer,
        cellRendererParams: (params: ICellRendererParams<AssetModel>): LinkCellRendererParams => ({
          pathname: `/app/locations/${params.data?.location?.id}`,
        }),
      },
      {
        colId: ColumnID.FIRST_EVENT_DATE,
        field: 'firstEventDate',
        headerName: 'First Event Date',
        cellRenderer: RelativeDateCellRenderer,
        valueFormatter: (params: ValueFormatterParams<AssetModel>) => {
          return params.value ? new Date(params.value).toISOString() : '';
        },
      },
      {
        colId: ColumnID.LAST_EVENT_DATE,
        field: 'lastEventDate',
        cellRenderer: RelativeDateCellRenderer,
        flex: 0,
        valueFormatter: (params: ValueFormatterParams<AssetModel>) => {
          return params.value ? new Date(params.value).toISOString() : '';
        },
      },
      {
        colId: ColumnID.STAYTIME,
        field: 'locationEnteredDate',
        headerName: 'Stay Time',
        flex: 0,
        cellRenderer: PeriodInDaysCellRenderer,
        valueFormatter: (params: ValueFormatterParams<AssetModel>) => {
          return params.value ? new Date(params.value).toISOString() : '';
        },
      },
    ];

    if (assetData) {
      // Get maximum number of trackers on any asset
      const maxTrackers = assetData.data.reduce((max, asset) => {
        return Math.max(max, asset.trackers.length);
      }, 0);

      // Add tracker columns
      for (let i = 0; i < maxTrackers; i++) {
        columns.push({
          headerName: `Tracker ${i + 1}`,
          cellClass: 'flex items-center ',
          // For last column set flex to 1 to fill remaining space
          flex: i === maxTrackers - 1 ? 1 : 0,
          sortable: false,
          cellRenderer: (params: ICellRendererParams<AssetModel>) => {
            if (!params.data || !params.data.trackers[i]) {
              return '';
            }

            switch (params.data.trackers[i].type) {
              case TrackerType.Barcode:
                return (
                  <div className="mt-0.5 flex h-8 py-1">
                    <TrackerUniqueIdentifierChip
                      trackerType={params.data.trackers[i].type}
                      uniqueIdentifier={params.data.trackers[i].barcode}
                    />
                  </div>
                );

              case TrackerType.RFID:
                return (
                  <div className="mt-0.5 flex h-8 py-1">
                    <TrackerUniqueIdentifierChip trackerType={params.data.trackers[i].type} uniqueIdentifier={params.data.trackers[i].epc} />
                  </div>
                );

              case TrackerType.UnaTag:
                return (
                  <div className="mt-0.5 flex h-8 py-1">
                    <TrackerUniqueIdentifierChip
                      trackerType={params.data.trackers[i].type}
                      uniqueIdentifier={params.data.trackers[i].deviceId}
                    />
                  </div>
                );

              default:
                return undefined;
            }
          },
        });
      }
    }

    return columns;
  }, [assetData]);

  const handleSearch = (filterValues: FilterValues, page?: number) => {
    setFiltersToUrl({
      ...filterValues,
      pageNumber: page ?? filters.current.pageNumber,
    });

    clearSelection();

    setApiCallArg(() =>
      assetService.get(
        {
          searchText: filterValues.searchText,
          locationId: filterValues.locationId,
          assetTypeId: filterValues.assetTypeId,
          stayTimeInDays: filterValues.stayTimeInDays,
        },
        {
          page: filterValues.pageNumber,
          limit: ASSET_FETCH_LIMIT,
          order: filterValues.sortDirection,
          sort: filterValues.sortOption,
        },
      ),
    );
  };

  const assetTabItems: TabMenuItem[] = [
    {
      activeRouteIds: ['/app/assets/overview'],
      label: 'Overview',
      url: '/app/assets/overview',
    },
  ];

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);

    // Set initial sort state to grid
    if (filters.current.sortOption && filters.current.sortDirection) {
      // Convert sort option to column id
      let columnId: ColumnID | undefined = ColumnID.FIRST_EVENT_DATE;
      switch (filters.current.sortOption) {
        case AssetSortOption.FIRST_EVENT_DATE:
          columnId = ColumnID.FIRST_EVENT_DATE;
          break;
        case AssetSortOption.LAST_EVENT_DATE:
          columnId = ColumnID.LAST_EVENT_DATE;
          break;
        case AssetSortOption.LOCATION_NAME:
          columnId = ColumnID.LOCATION_NAME;
          break;
        case AssetSortOption.ASSET_TYPE_NAME:
          columnId = ColumnID.ASSET_TYPE_NAME;
          break;
        case AssetSortOption.ASSET_CODE:
          columnId = ColumnID.ASSET_CODE;
          break;
        case AssetSortOption.LOCATION_ENTER_DATE:
          columnId = ColumnID.STAYTIME;
          break;
        default:
          break;
      }

      event.api.applyColumnState({
        state: [
          {
            colId: columnId,
            sort: filters.current.sortDirection === PageSortOrder.ASC ? 'asc' : 'desc',
          },
        ],
        defaultState: { sort: null },
      });
    }
  };

  const onSortChanged = (event: SortChangedEvent) => {
    if (event.columns && event.columns.length > 0 && event.source === 'uiColumnSorted') {
      console.log('Sort Changed', event.columns);

      const changedColumn = event.columns[0];

      const changedColumnId = changedColumn.getColId();
      let sortOption: AssetSortOption | undefined = undefined;

      switch (changedColumnId) {
        case ColumnID.FIRST_EVENT_DATE:
          sortOption = AssetSortOption.FIRST_EVENT_DATE;
          break;
        case ColumnID.LAST_EVENT_DATE:
          sortOption = AssetSortOption.LAST_EVENT_DATE;
          break;
        case ColumnID.LOCATION_NAME:
          sortOption = AssetSortOption.LOCATION_NAME;
          break;
        case ColumnID.ASSET_TYPE_NAME:
          sortOption = AssetSortOption.ASSET_TYPE_NAME;
          break;
        case ColumnID.ASSET_CODE:
          sortOption = AssetSortOption.ASSET_CODE;
          break;
        case ColumnID.STAYTIME:
          sortOption = AssetSortOption.LOCATION_ENTER_DATE;
          break;
        default:
          break;
      }

      let sortOrder: PageSortOrder | undefined = undefined;

      switch (changedColumn.getSort()) {
        case 'asc':
          sortOrder = PageSortOrder.ASC;
          break;
        case 'desc':
          sortOrder = PageSortOrder.DESC;
          break;
        default:
          sortOption = undefined; // If there is no sort direction, remove the sort option
          break;
      }

      handleSearch(
        {
          ...filters.current,
          sortOption,
          sortDirection: sortOrder,
        },
        1,
      );
    }
  };

  const customGridOptions: GridOptions<AssetModel> = useMemo(
    () => ({
      ...BASE_GRID_OPTIONS,
      onRowDoubleClicked(event: RowDoubleClickedEvent<AssetModel, unknown>) {
        navigate(`../${event.data?.id}`);
      },
      onGridReady: onGridReady,
      onSortChanged: onSortChanged,
      getContextMenuItems: (params) => {
        const result: (string | MenuItemDef)[] = [
          'copy',
          'separator',
          {
            name: 'Export to Excel',
            action: () => {
              onExportToExcelClicked(params.api);
            },
          },
        ];
        return result;
      },

      // Selection
      getRowId: (params) => params.data.dto.id.toString(),
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      onCellClicked: (event) => {
        onCellClicked(event);
      },
      onSelectionChanged: (event) => {
        // if (event.source === 'apiSelectAll') {
        //   setSelectedRows(event.api.getSelectedRows());
        // }
        console.log({ event });

        console.log('Selected Rows', event.api.getSelectedRows());

        setSelectedRows(event.api.getSelectedRows());
      },
    }),
    [filters.current, onGridReady],
  );

  async function onExportToExcelClicked(gridApi: GridApi<AssetModel> | undefined) {
    if (gridApi) {
      const data: AssetModel[] = [];

      const dataPage1 = await assetService.get(
        {
          searchText: filters.current.searchText,
          locationId: filters.current.locationId,
          assetTypeId: filters.current.assetTypeId,
          stayTimeInDays: filters.current.stayTimeInDays,
        },
        {
          page: 1,
          limit: ASSET_FETCH_LIMIT,
          order: filters.current.sortDirection ?? PageSortOrder.ASC,
          sort: filters.current.sortOption,
        },
      );

      if (dataPage1.isSuccess) {
        data.push(...dataPage1.payload.data);
      }

      // If there are more pages, fetch them
      if (dataPage1.payload.totalPages > 1) {
        for (let i = 2; i <= dataPage1.payload.totalPages; i++) {
          const dataPage = await assetService.get(
            {
              searchText: filters.current.searchText,
              locationId: filters.current.locationId,
              assetTypeId: filters.current.assetTypeId,
              stayTimeInDays: filters.current.stayTimeInDays,
            },
            {
              page: i,
              limit: ASSET_FETCH_LIMIT,
              order: filters.current.sortDirection ?? PageSortOrder.ASC,
              sort: filters.current.sortOption,
            },
          );

          if (dataPage.isSuccess) {
            data.push(...dataPage.payload.data);
          }
        }
      }

      if (data.length > 0) {
        exportToExcel(
          data.map((asset) => ({
            code: asset.code,
            type: asset.assetType.name,
            location: asset.location?.name,
            firstEventDate: asset.firstEventDate,
            lastEventDate: asset.lastEventDate,
            stayTime: asset.locationEnteredDate ? dayjs(new Date()).diff(dayjs(asset.locationEnteredDate), 'days') : undefined,
            // Loop over trackers, but destructuring to get tracker1, tracker2, etc.
            ...asset.trackers.reduce<Record<string, string>>((acc, tracker, index) => {
              switch (tracker.type) {
                case TrackerType.Barcode:
                  acc[`tracker${index + 1}`] = tracker.barcode ?? '';
                  break;
                case TrackerType.RFID:
                  acc[`tracker${index + 1}`] = tracker.epc ?? '';
                  break;
                case TrackerType.UnaTag:
                  acc[`tracker${index + 1}`] = tracker.deviceId ?? '';
                  break;
                default:
                  acc[`tracker${index + 1}`] = '';
                  break;
              }
              return acc;
            }, {}),
          })),
          `trackers_${dayjs().format('YYYY_MM_DD_HHmmss')}.xlsx`,
        );
      }
    }
  }

  // Assets moving
  function onMoveSelectedAssetsClicked() {
    setIsMoveAssetModalOpen(true);
  }

  function onMoveSelectedAssetsClosed() {
    setIsMoveAssetModalOpen(false);
    clearSelection();
  }

  function clearSelection() {
    setSelectedRows([]);

    if (gridApi) {
      gridApi.deselectAll();
    }
  }

  function onAssetsMovedByGrid() {
    // refetch data
    // if (gridApi) {
    //   setDataSource(gridApi);
    // }
  }

  const onPageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    handleSearch(
      {
        ...filters.current,
        pageNumber: page,
      },
      page,
    );
  };

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          header="Assets"
          headerIcon={<WidgetsRounded fontSize={'small'} />}
          tabItems={assetTabItems}
          actions={
            <RequirePermissionComponent permission={Permission.ASSETS_EDIT}>
              <Button variant="contained" onClick={() => navigate({ pathname: '../create' })}>
                Create
              </Button>
            </RequirePermissionComponent>
          }
        />
      }
    >
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col ">
          {assetIsError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <>
              <div className="mb-2 flex items-center justify-between">
                <FilterBar onSearch={handleSearch} initialFilterValues={filters.current} showAsPopover>
                  <div className="flex flex-col gap-y-2">
                    <div className="w-72">
                      <TextFilter label="Search" />
                    </div>
                    <div className="w-72">
                      <LocationFilter label="Location" />
                    </div>
                    <div className="w-72">
                      <AssetTypeFilter label="Asset Type" />
                    </div>
                    <div className="w-72">
                      <NumberFilter label="Minimum Stay Time (days)" filterFieldName={FilterFieldName.stayTimeInDays} />
                    </div>
                  </div>
                  <FilterBarSearchButton isLoading={assetIsLoading} />
                </FilterBar>
              </div>
              <PoGrid
                isLoading={assetIsLoading}
                colDefs={columns}
                gridOptions={customGridOptions}
                disableResizeColumnsToFit
                rowData={assetData?.data}
                disableDefaultGridOptions
              />
              <div className="flex items-center gap-x-4">
                <PaginationControls
                  isLoading={assetIsLoading}
                  totalPageCount={assetData?.totalPages ?? 1}
                  currentPage={filters.current.pageNumber ?? 1}
                  totalElements={assetData?.totalElements ?? 0}
                  onChange={onPageChanged}
                />
                <PagedResultDataText
                  data={assetData}
                  //  name={t('asset.event', { count: assetData?.totalElements ?? 0 })}
                  name="Assets"
                />
              </div>
            </>
          )}
        </div>
        <ActionBar visible={selectedRows.length > 0}>
          <Button variant="outlined" color="primary" onClick={clearSelection}>
            Clear Selection
          </Button>
          <Button variant="contained" onClick={onMoveSelectedAssetsClicked}>
            Move Assets..
          </Button>
        </ActionBar>
        <MoveAssetsToLocationModal
          selectedAssetIds={selectedRows.map((lam) => lam.dto.id)}
          isOpen={isMoveAssetModalOpen}
          onClose={onMoveSelectedAssetsClosed}
          onAssetsMoved={onAssetsMovedByGrid}
        />
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
