import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { AssetTypeModel } from '../types/AssetTypeModel';
import { assetTypeService } from '../api/asset-types/asset-type.service';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, SelectChangeEvent, IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';

interface AssetTypeSelectProps {
  selectedAssetTypeId?: number;
  onChange: (selectedAssetType?: AssetTypeModel) => void;
  helperText?: string;
  label?: string;
}

const AssetTypeSelect: FunctionComponent<AssetTypeSelectProps> = ({ selectedAssetTypeId, onChange, helperText, label = 'Asset Type' }) => {
  const [assetTypes, setAssetTypes] = useState<AssetTypeModel[]>([]);

  async function getAllAssetTypes() {
    const assetTypes = await assetTypeService.getAll();
    if (assetTypes.isSuccess) {
      setAssetTypes(assetTypes.payload);
    }
  }
  useEffect(() => {
    getAllAssetTypes();
  }, []);

  function onAssetTypeChanged(event: SelectChangeEvent<number>, child: ReactNode) {
    const selectedAssetType = assetTypes.find((at) => at.id === event.target.value);

    onChange(selectedAssetType);
  }
  return (
    <FormControl className="w-full">
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={selectedAssetTypeId && assetTypes.length > 0 ? selectedAssetTypeId : ''}
        label={label}
        onChange={onAssetTypeChanged}
        endAdornment={
          selectedAssetTypeId && (
            <IconButton className=" absolute right-3  mr-6 flex " color="inherit" onClick={() => onChange(undefined)} aria-label="close">
              <Clear />
            </IconButton>
          )
        }
      >
        {assetTypes.map((assetType) => {
          return (
            <MenuItem key={assetType.id} value={assetType.id}>
              {assetType.name}
            </MenuItem>
          );
        })}
      </Select>
      {helperText && <FormHelperText>{helperText} </FormHelperText>}
    </FormControl>
  );
};

export default AssetTypeSelect;
