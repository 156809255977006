import React, { FunctionComponent } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface PeriodToggleButtonProps {
  onChange: (event: React.MouseEvent<HTMLElement>, newPeriodInDays: number) => void;
  periodInDays: number;
}

const PeriodToggleButton: FunctionComponent<PeriodToggleButtonProps> = ({ onChange, periodInDays }) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newPeriodInDays: number) => {
    if (newPeriodInDays) onChange(event, newPeriodInDays);
  };

  return (
    <div className="flex items-center">
      <Tooltip title="Group balances over time">
        <ToggleButtonGroup size="small" value={periodInDays} exclusive onChange={handleChange} aria-label="group balances over time">
          <ToggleButton value={14} aria-label="group by hour">
            <div className="text-xs">14 Days</div>
          </ToggleButton>
          <ToggleButton value={30} aria-label="group by day">
            <div className="text-xs">30 Days</div>
          </ToggleButton>
          <ToggleButton value={90} aria-label="group by week">
            <div className="text-xs">90 Days</div>
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </div>
  );
};

export default PeriodToggleButton;
