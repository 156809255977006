import { Autocomplete, TextField } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { trackerService } from '../api/trackers/tracker.service';
import { TrackerModel } from '../types/TrackerModel';
import { TrackerUniqueIdentifierChip } from './TrackerUniqueIdentifierChip';

interface TrackerSelectProps {
  onSelected: (selectedTracker: TrackerModel | null) => void;
  exludedTrackerIds?: number[];
  clearSelectionOnSelect?: boolean;
}

export const TrackerSelect: FunctionComponent<TrackerSelectProps> = ({ onSelected, exludedTrackerIds, clearSelectionOnSelect }) => {
  const [selectedOption, setSelectedOption] = useState<TrackerModel | null>(null);
  const [inputValue, setInputValue] = useState('');

  const handleOptionChange = (event: unknown, value: TrackerModel | null) => {
    if (!clearSelectionOnSelect) {
      setSelectedOption(value);
    } else {
      setInputValue('');
      loadOptions('');
      setSelectedOption(null);
    }
    onSelected(value);
  };

  const [options, setOptions] = useState<TrackerModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadOptions = async (query: string) => {
    setIsLoading(true);
    try {
      const response = await trackerService.getAll({ searchText: query, limit: 100 });
      if (response.isSuccess) {
        let trackers = response.payload;
        if (exludedTrackerIds) {
          trackers = response.payload.filter((t) => !exludedTrackerIds.includes(t.id));
        }
        setOptions(trackers);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadOptions('');
  }, []);

  useEffect(() => {
    if (exludedTrackerIds) {
      loadOptions('');
    }
  }, [exludedTrackerIds]);

  return (
    <Autocomplete
      value={selectedOption}
      inputValue={inputValue}
      className="w-full"
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.uniqueIdentifier}
      onInputChange={(event, newInputValue) => {
        loadOptions(newInputValue);
        setInputValue(newInputValue);
      }}
      filterOptions={(x) => x}
      onChange={(_, value) => {
        handleOptionChange(_, value);
      }}
      loading={isLoading}
      renderInput={(params) => <TextField {...params} label="Select a Tracker" InputLabelProps={{ shrink: true }} />}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <TrackerUniqueIdentifierChip trackerType={option.type} uniqueIdentifier={option.uniqueIdentifier} />
            {/* <span className="text-gray-600 dark:text-gray-400">&nbsp;({option.name})</span> */}
          </li>
        );
      }}
    />
  );
};
