import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

interface RequirePermissionRouteProps {
  children: JSX.Element;
  requiredPermission: Permission;
}

const RequirePermissionRoute: FunctionComponent<RequirePermissionRouteProps> = ({ children, requiredPermission }) => {
  const { hasPermission } = useUser();
  const location = useLocation();

  const hasRequiredPermission = hasPermission(requiredPermission);

  if (!hasRequiredPermission) {
    // Redirect them to the start page, or error page if not authorized
    toast.error('You do not have permission to view this page', {
      updateId: 'no-permission',
      toastId: 'no-permission',
    });
    return <Navigate to="/app" state={{ from: location }} replace />;
  }

  return children;
};

export default RequirePermissionRoute;
