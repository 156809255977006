import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { eventService } from '@/modules/events/api/events/event.service';
import { EventModel } from '@/modules/events/types/EventModel';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { LocationEventsGrid } from './LocationEventsGrid';
import { useParams } from 'react-router-dom';

export const LocationEventsTab: FunctionComponent = () => {
  const { locationId } = useParams();
  if (!locationId) {
    return;
  }
  const locationIdNumber = Number.parseInt(locationId);

  const { data, isLoading, isError, fetchData } = useApiCall<EventModel[]>(() => eventService.get({ locationId: locationIdNumber }));
  return (
    <div className="flex h-full flex-1 flex-grow flex-col ">
      {isLoading ? <LoadingBlock /> : <LocationEventsGrid data={data} isError={isError}></LocationEventsGrid>}
    </div>
  );
};
