import { ActionBar } from '@/modules/application';
import { Card, CardContent, Typography, TextField } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { AssetTypeFormModel } from './AssetTypeFormModel';
import { LoadingButton } from '@mui/lab';
import { ActionBarDeleteButton } from '@/modules/application/components/ActionBarDeleteButton';
import { assetTypeService } from '../../api/asset-types/asset-type.service';
import { useNavigate, useParams } from 'react-router-dom';
import { isFinite, isNil } from 'lodash-es';
import { ApiResponseCode } from '@/lib/api/api-response-code';
import { toast } from 'react-toastify';
import ImageUpload from '@/components/inputs/ImageUpload';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

interface AssetTypeFormDisplayProps {
  form: UseFormReturn<AssetTypeFormModel>;
  onSubmit: (data: AssetTypeFormModel) => void;
  isCreate?: boolean;
}

const AssetTypeFormDisplay: FunctionComponent<AssetTypeFormDisplayProps> = ({ form, onSubmit, isCreate = false }) => {
  const { formState, register, handleSubmit, getValues, control, watch } = form;
  const { errors, isSubmitting } = formState;
  const [isDeleting, setIsDeleting] = useState(false);
  const hasAssetTypeEditPermission = useUser().hasPermission(Permission.ASSET_TYPES_EDIT);
  const { assetTypeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onDeleteConfirmed = async () => {
    if (isNil(assetTypeId)) return;

    const assetTypeIdNumber = Number.parseInt(assetTypeId);

    if (!isFinite(assetTypeIdNumber)) return;

    setIsDeleting(true);

    const deleteAssetTypeResponse = await assetTypeService.delete(assetTypeIdNumber);

    if (deleteAssetTypeResponse.isSuccess) {
      toast.success(t('assetTypes.deleted'));
      navigate('../');
    } else {
      if (deleteAssetTypeResponse.responseCode === ApiResponseCode.ASSET_TYPE_HAS_ASSETS) {
        toast(
          t('assetTypes.error_hasAssets'),
          {
            type: 'error',
            autoClose: 10000,
          },
        );
      } else {
        toast(t('assetTypes.error_delete'), {
          type: 'error',
        });
      }
    }
    setIsDeleting(false);
  };

  function onImageUploaded(file: File) {
    console.log(file);
    form.setValue('image', file);
  }

  function onImageRemoved() {
    console.log('Image removed');
    form.setValue('image', null);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3 ">
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              {t('assetTypes.details')}
            </Typography>
            <div className="flex w-full flex-col gap-y-2">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.name !== undefined} helperText={errors.name?.message} label={t('assetTypes.name')} {...field} />
                )}
              />

              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.code !== undefined} helperText={errors.code?.message} label={t('assetTypes.code')} {...field} />
                )}
              />

              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.description !== undefined}
                    helperText={errors.description?.message}
                    label={t('assetTypes.description')}
                    {...field}
                  />
                )}
              />

              <Controller
                name="widthInMm"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.widthInMm !== undefined}
                    helperText={errors.widthInMm?.message}
                    label={t('assetTypes.width')}
                    type="number"
                    {...field}
                  />
                )}
              />

              <Controller
                name="heightInMm"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.heightInMm !== undefined}
                    helperText={errors.heightInMm?.message}
                    label={t('assetTypes.height')}
                    type="number"
                    {...field}
                  />
                )}
              />

              <Controller
                name="depthInMm"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.depthInMm !== undefined}
                    helperText={errors.depthInMm?.message}
                    label={t('assetTypes.depth')}
                    type="number"
                    {...field}
                  />
                )}
              />

              <Controller
                name="volumeInCubicMm"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.volumeInCubicMm !== undefined}
                    helperText={errors.volumeInCubicMm?.message}
                    label={t('assetTypes.volume')}
                    type="number"
                    {...field}
                  />
                )}
              />

              <Controller
                name="modelYear"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.modelYear !== undefined}
                    helperText={errors.modelYear?.message}
                    label={t('assetTypes.modelYear')}
                    type="number"
                    {...field}
                  />
                )}
              />
              <Controller
                name="monetaryValue"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.monetaryValue !== undefined}
                    helperText={errors.monetaryValue?.message}
                    label={t('assetTypes.monetaryValue')}
                    type="number"
                    {...field}
                  />
                )}
              />
            </div>
          </CardContent>
        </Card>
        <Card variant="outlined" className="self-start">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              {t('assetTypes.image')}
            </Typography>
            <ImageUpload
              onImageUpload={onImageUploaded}
              onRemoveImage={onImageRemoved}
              initialImageUrl={form.getValues('imageThumbnailUrl') ?? undefined}
            />
            {errors.image && <div className="text-red-500">{errors.image.message}</div>}
          </CardContent>
        </Card>
      </div>

      <ActionBar visible={hasAssetTypeEditPermission}>
        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
        <ActionBarDeleteButton
          deleteConfirmationQuestion={t('assetTypes.delete_confirmation')}
          isVisible={!isCreate}
          isLoading={isDeleting}
          onDeleteConfirmed={onDeleteConfirmed}
        />
      </ActionBar>
    </form>
  );
};

export default AssetTypeFormDisplay;
