export const TRACKER_TRANSLATIONS_ENGLISH = {
    tracker: {
      title: 'Trackers',
      add: 'Add Tracker',
      new: 'New Tracker',
      device_identifier: 'Device Identifier',
      unique_identifier: 'Unique Identifier',
      tracker_one: 'Tracker',
      tracker_other: 'Trackers',
      unknown: 'Unknown Tracker',
      created: 'Tracker created',
      updated: 'Tracker updated',
      deleted: 'Tracker deleted',
      delete_confirm: 'Are you sure you want to delete this tracker?',

      type: {
        title: 'Type',
        barcode: 'Barcode',
        rfid: 'RFID',
        unatag: 'UnaTag',
      },

      errors: {
        no_permission: 'You do not have permission to edit trackers',
        duplicate: {
          barcode: 'The Barcode is already in use, and must be unique. Please provide a different one.',
          epc: 'The Epc is already in use, and must be unique. Please provide a different one.',
          deviceId: 'The Device ID is already in use, and must be unique. Please provide a different one.',
        },
        duplicate_code: 'The code is already in use, and must be unique. Please provide a different one.',
        creating: 'Error Creating Tracker',
        updating: 'Error Updating Tracker',
        deleting: 'Error Deleting Tracker',
      }
    }
  };