import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { FunctionComponent, useEffect, useState } from 'react';

import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import { DashboardWidgetProvider } from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DasshboardWidgetConfigurationDrawer';
import { useTimerEndListener } from '@/providers/timer-provider/useTimerEndListener';
import { balanceService } from '@/modules/balances/api/balances/balance.service';
import { ReactECharts } from '@/components/chart/ReactEcharts';
import { EChartsOption } from 'echarts';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import { BalanceLocationDTO } from '@/modules/balances/api/balances/balance.contracts';
import { GroupPerTimeType } from './group-per-time-type.enum';
import dayjs from 'dayjs';
import { BalanceOverTimeWidgetConfiguration } from './BalanceOverTimeWidgetConfiguration';
import BalanceOverTimeWidgetConfigurationForm from './BalanceOverTimeWidgetConfigurationForm';
import BetaLabel from '@/components/BetaLabel';
import { isNil } from 'lodash-es';
import { useDashboardContext } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { BalanceOverTimeWidgetCatalogItem } from './BalanceOverTimeWidgetCatalogItem';
import { useTranslation } from '@/lib';

type BalancesOverTimeWidgetProps = IDashboardWidget<BalanceOverTimeWidgetConfiguration>;

const BalancesOverTimeWidget: FunctionComponent<BalancesOverTimeWidgetProps> = ({ configuration, id, layout }) => {
  const [balancesOverTime, setBalancesOverTime] = useState<BalanceLocationDTO[]>([]);
  const { updateWidgetConfiguration, updateWidget, widgets } = useDashboardContext();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  // Listens to the timer context
  useTimerEndListener(handleTimerEnd);

  const BALANCE_OVER_TIME_WIDGET_CATALOG_ITEM = new BalanceOverTimeWidgetCatalogItem(t);

  async function fetchBalancesOverTime() {
    // Migrate the configuration to the new configuration
    if (isNil(configuration.periodInDays)) {
      updateWidgetConfiguration<BalanceOverTimeWidgetConfiguration>(id, {
        periodInDays: BALANCE_OVER_TIME_WIDGET_CATALOG_ITEM.configuration.periodInDays,
      });
    }

    const response = await balanceService.getLocationBalanceOverTime({
      locationId: configuration.locationId,
      periodInDays: configuration.periodInDays,
    });
    if (response.isSuccess) {
      // Migrate the configuration to the new configuration
      if (
        layout.maxH !== BALANCE_OVER_TIME_WIDGET_CATALOG_ITEM.layout.maxH ||
        layout.maxW !== BALANCE_OVER_TIME_WIDGET_CATALOG_ITEM.layout.maxW
      ) {
        updateWidget(id, {
          layout: {
            ...layout,
            maxH: BALANCE_OVER_TIME_WIDGET_CATALOG_ITEM.layout.maxH,
            maxW: BALANCE_OVER_TIME_WIDGET_CATALOG_ITEM.layout.maxW,
          },
        });
      }

      // If no asset type ids are set, set the first 5 asset type ids
      if (isNil(configuration.assetTypeIds)) {
        const firstFive = response.payload.splice(0, 5);
        setBalancesOverTime(firstFive);
        updateWidgetConfiguration<BalanceOverTimeWidgetConfiguration>(id, {
          assetTypeIds: firstFive.map((balanceLocation) => balanceLocation.assetTypeId),
        });
      } else {
        setBalancesOverTime(response.payload.filter((balanceLocation) => configuration.assetTypeIds?.includes(balanceLocation.assetTypeId)));
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchBalancesOverTime();
  }, [configuration.balanceOverTimeType, configuration.locationId, configuration.assetTypeIds, configuration.periodInDays]);

  function handleTimerEnd() {
    fetchBalancesOverTime();
  }

  // Get highest balance
  let highestBalance = 0;
  balancesOverTime.forEach((balanceLocation) => {
    balanceLocation.balances.forEach((balance) => {
      if (balance.balance > highestBalance) {
        highestBalance = balance.balance;
      }
    });
  });

  // Calculate left margin based on highest balance
  let leftMargin = 30;
  if (highestBalance > 100) {
    leftMargin = leftMargin + 8;
  }
  if (highestBalance > 1000) {
    leftMargin = leftMargin + 8;
  }
  if (highestBalance > 10000) {
    leftMargin = leftMargin + 8;
  }
  if (highestBalance > 100000) {
    leftMargin = leftMargin + 8;
  }

  // Calculate bottom margin based on number of balances and length of asset type names
  let bottomMargin = 50;
  const balanceOverTimeNameLengthTotal =
    balancesOverTime.reduce((acc, balanceLocation) => acc + balanceLocation.assetTypeName.length, 0) * 7 + balancesOverTime.length * 12;

  // Calculate the width of the widget
  const widgetLineWidth = layout.w * 148;

  // Calculate the bottom margin based on the length of the asset type names
  if (balanceOverTimeNameLengthTotal / widgetLineWidth > 1) {
    bottomMargin = 65;
  }
  if (balanceOverTimeNameLengthTotal / widgetLineWidth > 2) {
    bottomMargin = 90;
  }
  if (balanceOverTimeNameLengthTotal / widgetLineWidth > 3) {
    bottomMargin = 115;
  }

  const option: EChartsOption = {
    grid: {
      left: leftMargin,
      top: 10,
      right: 20,
      bottom: bottomMargin,
    },
    xAxis: {
      type: 'category',
      data:
        balancesOverTime.length > 0
          ? balancesOverTime[0].balances.map((balance) => {
              // Based on configuration.balancesOverTimeType, we need to format the balance.date (a date string)
              // Use dayjs to format for example the hour, the day of the month or the week number
              switch (configuration.balanceOverTimeType) {
                case GroupPerTimeType.HOUR:
                  return dayjs(balance.date).format('HH:mm');
                case GroupPerTimeType.DAY:
                  return dayjs(balance.date).format('D MMM');
                case GroupPerTimeType.WEEK:
                  return 'Wk ' + dayjs(balance.date).week();

                default:
                  return '';
              }
            })
          : [],
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: balancesOverTime.map((balanceLocation) => ({
      name: `${balanceLocation.assetTypeName}`,
      type: 'line',
      data: balanceLocation.balances.map((balance) => balance.balance),
    })),
    tooltip: {
      trigger: 'axis',
      renderMode: 'html',
      appendTo: 'body',
      extraCssText: 'z-index: 9999999;', // This is needed to make sure the tooltip is on top of the drawer
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      bottom: 5,
      orient: 'horizontal',
      align: 'auto',
      padding: [0, 0, 0, 0],
      data: balancesOverTime.map((balanceLocation) => `${balanceLocation.assetTypeName}`),
    },
  };

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isLoading} setIsLoading={setIsLoading}>
      <DashboardWidgetContent>
        <DashboardWidgetConfigurationButton></DashboardWidgetConfigurationButton>
        <div className="flex w-full bg-green  items-center">
          <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
        </div>
        <div className="h-full w-full flex-1">
          {balancesOverTime.length === 0 ? (
            <div className="flex justify-center items-center h-full pb-6">{t('dashboard.balanceOverTimeWidget.noBalancesFound')}</div>
          ) : (
            <ReactECharts
              option={option}
              settings={{
                notMerge: true,
              }}
            />
          )}
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <BalanceOverTimeWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default BalancesOverTimeWidget;
