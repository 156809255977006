import { FunctionComponent } from 'react';
import { MainMenuItem } from '@/modules/application/types/MenuItem';
import MobileMainMenuItem from './MobileMainMenuItem';
import { useUser } from '@/modules/users/contexts/UserContext';

interface MobileMainMenuProps {
  menuItems: MainMenuItem[];
}

const MobileMainMenu: FunctionComponent<MobileMainMenuProps> = ({ menuItems }) => {
  const { hasPermission } = useUser();

  return (
    <div className="fixed bottom-0 left-0 z-50 w-full h-14 bg-gray-100 border-t border-gray-300 dark:bg-gray-800 dark:border-gray-700 ">
      <div className="grid h-full max-w-lg grid-cols-2 mx-auto font-medium">
        {menuItems
          .filter((item) => hasPermission(item.permission))
          .map((item) => (
            <MobileMainMenuItem
              key={item.url}
              label={item.label}
              icon={item.icon}
              pathname={item.url}
              activePathnames={item.activeRouteIds}
            />
          ))}
      </div>
    </div>
  );
};

export default MobileMainMenu;
