import { BASE_GRID_OPTIONS, PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridOptions, ICellRendererParams, RowDoubleClickedEvent } from 'ag-grid-community';
import LinkCellRenderer, { LinkCellRendererParams } from '@/components/grid/cells/LinkCellRenderer';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { AssetTypeTotalModel } from '@/modules/asset-types';
import { DisplayMode } from '@/types/display-mode.enum';

interface LocationBalanceGridProps {
  data?: AssetTypeTotalModel[];
  isError: boolean;
  noBorder?: boolean;
  isLoading?: boolean;
  balanceDisplayMode?: DisplayMode;
}

export const LocationBalanceGrid: FunctionComponent<LocationBalanceGridProps> = ({
  data,
  isError,
  noBorder,
  isLoading,
  balanceDisplayMode,
}) => {
  const navigate = useNavigate();

  const customGridOptions: GridOptions<AssetTypeTotalModel> = {
    ...BASE_GRID_OPTIONS,
    onRowDoubleClicked(event: RowDoubleClickedEvent<AssetTypeTotalModel, unknown>) {
      navigate(`/app/configuration/assets/types/${event.data?.id}`);
    },
  };

  const columns: ColDefOrGroup<AssetTypeTotalModel>[] = [
    {
      field: 'code',
      headerName: 'Asset Type Code',
    },
    {
      field: 'name',
      headerName: 'Asset Type Name',
      cellRenderer: LinkCellRenderer,
      cellRendererParams: (params: ICellRendererParams<AssetTypeTotalModel>): LinkCellRendererParams => ({
        pathname: params.data?.id ? `/app/configuration/assets/types/${params.data?.id}` : undefined,
      }),
    },
  ];

  if (balanceDisplayMode === DisplayMode.HIERARCHICAL) {
    columns.push({
      field: 'hierarchicalBalance',
      headerName: 'Balance',
      flex: 1,
    });
  } else {
    columns.push({
      field: 'individualBalance',
      headerName: 'Balance',
      flex: 1,
    });
  }

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      {isError ? (
        <ErrorLoadingDataAlert />
      ) : (
        <PoGrid
          noBorder={noBorder}
          isLoading={isLoading}
          colDefs={columns}
          rowData={data}
          gridOptions={customGridOptions}
          disableDefaultGridOptions
        />
      )}
    </div>
  );
};
