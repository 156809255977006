import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { TopAssetTypesMetric, TopAssetTypesWidgetConfiguration } from './TopAssetTypesWidgetConfiguration';

import TopAssetTypesWidgetConfigurationForm from './TopAssetTypesWidgetConfigurationForm';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import { DashboardWidgetProvider } from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DasshboardWidgetConfigurationDrawer';
import { useTimerEndListener } from '@/providers/timer-provider/useTimerEndListener';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import { assetTypeService } from '@/modules/asset-types/api/asset-types/asset-type.service';
import { AssetByAssetCountDTO, AssetByAssetMonetaryValueDTO } from '@/modules/asset-types/api/asset-types/asset-type.contracts';
import { useFormatting } from '@/hooks/useFormatting';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type TopAssetTypesWidgetProps = IDashboardWidget<TopAssetTypesWidgetConfiguration>;

const TopAssetTypesWidget: FunctionComponent<TopAssetTypesWidgetProps> = ({ configuration, id, layout }) => {
  const { formatNumber } = useFormatting();
  const [topAssetTypesByAssetCount, setTopAssetTypesByAssetCount] = useState<AssetByAssetCountDTO[]>([]);
  const [topAssetTypesByAssetMonetaryValue, setTopAssetTypesByAssetMonetaryValue] = useState<AssetByAssetMonetaryValueDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Listens to the timer context
  useTimerEndListener(handleTimerEnd);

  async function fetchData() {
    switch (configuration.metric) {
      case TopAssetTypesMetric.assetCount: {
        const total = await assetTypeService.getTopAssetTypesByAssetCount();
        if (total.isSuccess) {
          setTopAssetTypesByAssetCount(total.payload);
          setIsLoading(false);
        }
        break;
      }

      case TopAssetTypesMetric.assetTotalMonetaryValue: {
        const assetTypesByAssetMonetaryValue = await assetTypeService.getTopAssetTypesByAssetMonetaryValue();
        if (assetTypesByAssetMonetaryValue.isSuccess) {
          setTopAssetTypesByAssetMonetaryValue(assetTypesByAssetMonetaryValue.payload);
          setIsLoading(false);
        }
        break;
      }
    }
  }
  useEffect(() => {
    fetchData();
  }, [configuration.metric]);

  function handleTimerEnd() {
    fetchData();
  }

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isLoading} setIsLoading={setIsLoading}>
      <DashboardWidgetContent>
        <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
        <DashboardWidgetConfigurationButton></DashboardWidgetConfigurationButton>

        {configuration.metric === TopAssetTypesMetric.assetCount && topAssetTypesByAssetCount.length > 0 && (
          <div className="relative h-full overflow-y-auto">
            <div className="grid grid-cols-2 justify-between ">
              <div className="bg-gray-200 px-2 py-2 text-xs font-bold uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                Asset Type
              </div>
              <div className="bg-gray-200 px-2 py-2 text-right text-xs font-bold uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                Count
              </div>
              {topAssetTypesByAssetCount.map((assetType) => (
                <Fragment key={assetType.id}>
                  <Link
                    component={RouterLink}
                    to={`/app/configuration/assets/types/${assetType.id}`}
                    underline="hover"
                    className="whitespace-nowrap px-2 py-2 font-medium text-gray-900 dark:text-white"
                  >
                    {assetType.name}
                  </Link>
                  <div className=" px-2 py-2 text-right">{formatNumber(assetType.count)}</div>
                  <div className="col-span-2 h-px bg-gray-300 dark:bg-gray-700"></div>
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {configuration.metric === TopAssetTypesMetric.assetCount && topAssetTypesByAssetCount.length === 0 && (
          <div className=" flex h-full items-center justify-center ">No Asset Types found.</div>
        )}
        {configuration.metric === TopAssetTypesMetric.assetTotalMonetaryValue && topAssetTypesByAssetMonetaryValue.length > 0 && (
          <div className="relative h-full overflow-y-auto">
            <div className="grid grid-cols-2 justify-between ">
              <div className="bg-gray-200 px-2 py-2 text-xs font-bold uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                Asset Type
              </div>
              <div className="bg-gray-200 px-2 py-2 text-right text-xs font-bold uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                Total Value
              </div>
              {topAssetTypesByAssetMonetaryValue.map((assetType) => (
                <Fragment key={assetType.id}>
                  <Link
                    component={RouterLink}
                    to={`/app/configuration/assets/types/${assetType.id}`}
                    underline="hover"
                    className="whitespace-nowrap px-2 py-2 font-medium text-gray-900 dark:text-white"
                  >
                    {assetType.name}
                  </Link>
                  <div className=" px-2 py-2 text-right">{formatNumber(assetType.totalAssetValue)}</div>
                  <div className="col-span-2 h-px bg-gray-300 dark:bg-gray-700"></div>
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {configuration.metric === TopAssetTypesMetric.assetTotalMonetaryValue && topAssetTypesByAssetMonetaryValue.length === 0 && (
          <div className=" flex h-full items-center justify-center ">No Asset Types found.</div>
        )}
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <TopAssetTypesWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default TopAssetTypesWidget;
