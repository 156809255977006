import { ActionBar } from '@/modules/application';
import { Card, CardContent, Typography, TextField, MenuItem, Select, FormHelperText, FormControl, InputLabel } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { TrackerFormModel } from './TrackerFormModel';
import { LoadingButton } from '@mui/lab';
import { TrackerType } from '../../api/trackers/tracker.contracts';
import { trackerService } from '../../api/trackers/tracker.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ActionBarDeleteButton } from '@/modules/application/components/ActionBarDeleteButton';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useUser } from '@/modules/users/contexts/UserContext';
import { useTranslation } from '@/lib';

interface TrackerFormDisplayProps {
  form: UseFormReturn<TrackerFormModel>;
  onSubmit: (data: TrackerFormModel) => void;
  isCreate?: boolean;
  trackerId?: number;
}

const TrackerFormDisplay: FunctionComponent<TrackerFormDisplayProps> = ({ form, onSubmit, isCreate = false, trackerId }) => {
  const { formState, handleSubmit, control, watch } = form;
  const { errors, isSubmitting } = formState;
  const hasEditTrackerPermission = useUser().hasPermission(Permission.TRACKERS_EDIT);

  const navigate = useNavigate();
  const { t } = useTranslation();

  async function deleteTracker() {
    console.log({ trackerId });

    if (trackerId) {
      const response = await trackerService.delete(trackerId);
      if (!response.isSuccess) {
        toast.error(t('trackers.errors.deleting'));
      } else {
        toast.success(t('trackers.deleted'));
        navigate('/app/configuration/trackers/overview');
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3 ">
        {/* {JSON.stringify({ trackerId, isCreate })} */}
        {/* <div>
              {formState && JSON.stringify(formState)}
              {errors && JSON.stringify(errors)}
            </div> */}
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              Details
            </Typography>
            <div className="flex w-full flex-col gap-y-2">
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel id="demo-simple-select-helper-label">{t('tracker.type.title')}</InputLabel>
                    <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" label={'Type'} {...field}>
                      <MenuItem value={TrackerType.RFID}>{t('tracker.type.rfid')}</MenuItem>
                      <MenuItem value={TrackerType.Barcode}>{t('tracker.type.barcode')}</MenuItem>
                      <MenuItem value={TrackerType.UnaTag}>{t('tracker.type.unatag')}</MenuItem>
                    </Select>
                    <FormHelperText>{errors.type?.message} </FormHelperText>
                  </FormControl>
                )}
              />

              {watch('type') === TrackerType.RFID && (
                <Controller
                  name="epc"
                  control={control}
                  render={({ field }) => (
                    <TextField error={errors.epc !== undefined} helperText={errors.epc?.message} label={t('tracker.type.rfid')} {...field} />
                  )}
                />
              )}

              {watch('type') === TrackerType.Barcode && (
                <Controller
                  name="barcode"
                  control={control}
                  render={({ field }) => (
                    <TextField error={errors.barcode !== undefined} helperText={errors.barcode?.message} label={t('tracker.type.barcode')} {...field} />
                  )}
                />
              )}

              {watch('type') === TrackerType.UnaTag && (
                <Controller
                  name="deviceId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      error={errors.deviceId !== undefined}
                      helperText={errors.deviceId?.message}
                      label={t('tracker.device_identifier')}
                      {...field}
                    />
                  )}
                />
              )}

              {/* <TextField id="outlined-basic" label="Password" variant="outlined" /> */}
            </div>
          </CardContent>
        </Card>
      </div>

      <ActionBar visible={hasEditTrackerPermission}>
        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
        {t('save')}
        </LoadingButton>
        <ActionBarDeleteButton
          deleteConfirmationQuestion={t('tracker.delete_confirm')}
          onDeleteConfirmed={deleteTracker}
          isVisible={!isCreate}
          isLoading={isSubmitting}
        />
      </ActionBar>
    </form>
  );
};

export default TrackerFormDisplay;
