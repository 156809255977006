import {
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnVisibleEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { ColDef, GridApi, ColumnState } from 'ag-grid-enterprise';
import { useEffect, useCallback, useRef } from 'react';
import { debounce, differenceBy, isBoolean, isNil } from 'lodash-es';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { useUserSettings } from '@/modules/users/contexts/UserSettingsContext';

interface GridColumnState {
  columnState: ColumnState[] | undefined;
  isLoading: boolean;
  isError: boolean;
  setColumnStateGridApi: (gridApi: GridApi) => void;
  handleColumnStateChange: (
    event: ColumnPinnedEvent | ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent | ColumnRowGroupChangedEvent | SortChangedEvent,
  ) => void;
  applyStateToDefinitions: (colDefs: ColDef[]) => void;
  isAutoSaveEnabled: boolean;
  setIsAutoSaveEnabled: (isAutoSaveEnabled: boolean) => void;
  setDefaultColumnState: (columnState: ColumnState[]) => void;
}

export function useGridColumnState(userSettingKey: UserSettingKey): GridColumnState {
  const { getUserSettingValueByKey, setUserSettingByKey } = useUserSettings();

  const initialColumnStateRef = useRef(getUserSettingValueByKey<ColumnState[]>(userSettingKey) ?? []);
  const isLoadingRef = useRef(false);
  const isErrorRef = useRef(false);
  const gridApiRef = useRef<GridApi | null>(null);
  const isAutoSaveEnabledRef = useRef(false);
  const defaultColumnStateRef = useRef<ColumnState[]>([]);

  const handleColumnStateChange = (
    event: ColumnPinnedEvent | ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent | ColumnRowGroupChangedEvent | SortChangedEvent,
  ) => {
    if (event.source === 'sizeColumnsToFit') {
      return;
    }
    if (gridApiRef.current && isAutoSaveEnabledRef.current) {
      debouncedSaveColumnStateFn();
    }
  };

  const saveColumnState = useCallback(async () => {
    if (gridApiRef.current) {
      const columnState = gridApiRef.current.getColumnState();
      isLoadingRef.current = true;
      initialColumnStateRef.current = columnState;
      const success = await setUserSettingByKey(userSettingKey, columnState, false);
      isErrorRef.current = !success;
      isLoadingRef.current = false;
    }
  }, [userSettingKey, setUserSettingByKey]);

  const debouncedSaveColumnStateFn = useCallback(
    debounce(() => {
      saveColumnState();
    }, 500),
    [saveColumnState],
  );

  const setDefaultColumnState = (columnState: ColumnState[]) => {
    defaultColumnStateRef.current = columnState;
  };

  const applyStateToDefinitions = useCallback((colDefs: ColDef[]) => {
    const unmigratedState = initialColumnStateRef.current;
    const userColumnState = updateUserColumnStateWithNewDefaultState(defaultColumnStateRef.current, unmigratedState);

    if (userColumnState && userColumnState.find) {
      colDefs.forEach((def) => {
        const stateForCol = userColumnState.find((s) => s.colId === def.colId);
        if (!stateForCol) {
          return;
        }
        if (isBoolean(stateForCol.hide)) {
          def.hide = stateForCol.hide;
        } else {
          def.hide = true;
        }

        // Handle sorting
        if (stateForCol.sort && (stateForCol.sort === 'asc' || stateForCol.sort === 'desc')) {
          def.sort = stateForCol.sort;
        }

        if (!isNil(stateForCol.sortIndex) && stateForCol.sortIndex >= 0) {
          def.sortIndex = stateForCol.sortIndex;
        }

        // Handle grouping
        if (isBoolean(stateForCol.rowGroup)) {
          def.rowGroup = stateForCol.rowGroup;
        }

        if (!isNil(stateForCol.rowGroupIndex) && stateForCol.rowGroupIndex >= 0) {
          def.rowGroupIndex = stateForCol.rowGroupIndex;
        }

        // Handle width
        if (stateForCol.width && def.width !== stateForCol.width) {
          def.width = stateForCol.width;
        }

        // Check if the column is pinned
        if (isBoolean(stateForCol.pinned) || stateForCol.pinned === 'left' || stateForCol.pinned === 'right') {
          def.pinned = stateForCol.pinned;
        } else {
          def.pinned = null;
        }
      });

      colDefs.sort((a, b) => {
        const aIndex = userColumnState.findIndex((s) => s.colId === a.colId);
        const bIndex = userColumnState.findIndex((s) => s.colId === b.colId);

        return aIndex - bIndex;
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      gridApiRef.current = null;
    };
  }, []);

  const setColumnStateGridApi = (api: GridApi) => {
    gridApiRef.current = api;
  };

  const setIsAutoSaveEnabled = (value: boolean) => {
    isAutoSaveEnabledRef.current = value;
  };

  /**
   * Migrates the column state of the user, with the default.
   *
   * New Columns are injected before their next neighbor in the default state
   *
   * @param defaultColumnstate The default column state
   * @param userColumnStates The user's column state
   */
  function updateUserColumnStateWithNewDefaultState(defaultColumnstate: ColumnState[], userColumnStates: ColumnState[]) {
    // Get the fields the current users' state is missing
    const missingFields = differenceBy(defaultColumnstate, userColumnStates, 'colId');

    // For each missing field
    missingFields.forEach((missingField) => {
      // Find the position in the default state
      const missingFieldIndex = defaultColumnstate.findIndex((el) => el.colId === missingField.colId);

      // Get the field next to it, in the default state
      const fieldAfter = defaultColumnstate.slice(missingFieldIndex).find((field) => !missingFields.some((mf) => mf.colId === field.colId));

      // If there is a field after it, and the user has that field in their state
      if (fieldAfter && userColumnStates.some((el) => el.colId === fieldAfter.colId)) {
        // Find the position of that field in the current user's state
        const fieldAfterIndex = userColumnStates.findIndex((el) => el.colId === fieldAfter.colId);

        // Insert it at that position
        userColumnStates.splice(fieldAfterIndex, 0, missingField);
      }
    });

    return userColumnStates;
  }

  return {
    columnState: initialColumnStateRef.current,
    isLoading: isLoadingRef.current,
    isError: isErrorRef.current,
    setColumnStateGridApi,
    handleColumnStateChange,
    applyStateToDefinitions,
    isAutoSaveEnabled: isAutoSaveEnabledRef.current,
    setIsAutoSaveEnabled,
    setDefaultColumnState,
  };
}
