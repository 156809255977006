import { FunctionComponent } from 'react';
import { EventModel } from '@/modules/events/types/EventModel';
import { useDate } from '@/hooks/useDate';
import { PlaceRounded } from '@mui/icons-material';
import { TrackerTypeLabel } from '@/modules/trackers/components/TrackerTypeLabel';

interface MobileEventCardProps {
  event: EventModel;
}

const MobileEventCard: FunctionComponent<MobileEventCardProps> = ({ event }) => {
  const { formateRelativeDate } = useDate();

  return (
    <div key={event.id} className="flex flex-col rounded-lg shadow-md overflow-hidden">
      <div className="bg-gray-200 dark:bg-gray-800 p-1.5 text-sm flex justify-between">
        <div>{formateRelativeDate(event.occurredAt)}</div>
        <div className="first-letter:uppercase font-bold">{event.type}</div>
      </div>
      <div className="py-1.5 px-2 bg-gray-100 dark:bg-gray-850">
        <div className="flex justify-between items-center">
          <div>
            <div className="text-lg font-bold">{event.trackerUniqueIdentifier}</div>
          </div>
          <div className="tracking-wide font-medium">
            <TrackerTypeLabel trackerType={event.tracker.type} />
          </div>
        </div>

        <div className="text-sm flex items-center">
          <PlaceRounded className="mr-1 text-orange-600" fontSize="inherit" />
          {event.location.name}
        </div>
      </div>
    </div>
  );
};

export default MobileEventCard;
