import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { eventService } from '@/modules/events/api/events/event.service';
import { EventModel } from '@/modules/events/types/EventModel';
import { AssetEventsGrid } from './AssetEventsGrid';
import LoadingBlock from '@/components/feedback/LoadingBlock';

interface AssetEventsTabProps {
  assetId: number;
}

export const AssetEventsTab: FunctionComponent<AssetEventsTabProps> = ({ assetId }) => {
  const { data, isLoading, isError, fetchData } = useApiCall<EventModel[]>(() => eventService.get({ assetId }));
  return (
    <div className="flex h-full flex-1 flex-grow flex-col ">
      {isLoading ? <LoadingBlock /> : <AssetEventsGrid data={data} isError={isError}></AssetEventsGrid>}
    </div>
  );
};
