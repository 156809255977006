import { FunctionComponent } from 'react';
import MobilePage from '../components/MobilePage';
import MobilePageContent from '../components/MobilePageContent';
import MobilePageHeader from '../components/MobilePageHeader';
import { useApiCall } from '@/hooks/useApiCall';
import { eventService } from '@/modules/events/api/events/event.service';
import { EventModel } from '@/modules/events/types/EventModel';
import dayjs from 'dayjs';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import ErrorBlock from '@/components/feedback/ErrorBlock';
import MobileEventCard from '../components/MobileEventCard';

const EVENT_FETCH_LIMIT = 50;

interface MobileEventsPageProps {}

const MobileEventsPage: FunctionComponent<MobileEventsPageProps> = () => {
  const { data, isLoading, isError } = useApiCall<EventModel[]>(() =>
    eventService.get({
      dateFrom: dayjs().subtract(1, 'month').startOf('day').utc(true),
      limit: EVENT_FETCH_LIMIT,
    }),
  );

  return (
    <MobilePage>
      <MobilePageHeader title="Events" />

      <MobilePageContent>
        <div className="flex flex-col flex-1  gap-y-4 ">
          <div className="text-sm mt-2">Showing the {EVENT_FETCH_LIMIT} most recent Events.</div>
          {isLoading && <LoadingBlock />}
          {isError && <ErrorBlock />}
          {!isLoading && !isError && data?.map((event) => <MobileEventCard event={event} key={event.id} />)}
        </div>
      </MobilePageContent>
    </MobilePage>
  );
};

export default MobileEventsPage;
