import { useState, useEffect, useContext, useRef, useMemo, FunctionComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty, merge } from 'lodash-es';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-enterprise';
import { ColorModeContext } from '@/modules/application';
import LoadingBlock from '../feedback/LoadingBlock';
import { GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { v4 } from 'uuid';

export interface PoGridProps {
  colDefs: ColDef[];
  rowData?: unknown[];
  gridOptions?: GridOptions;
  onRowDoubleClickedCallback?: (row: RowDoubleClickedEvent) => void;
  isLoading?: boolean;
  disableResizeColumnsToFit?: boolean;
  noBorder?: boolean;
  disableDefaultGridOptions?: boolean;
  disableRowData?: boolean;
}

export const BASE_GRID_OPTIONS: GridOptions = {
  scrollbarWidth: 15,
  multiSortKey: 'ctrl',
  defaultColDef: {
    headerClass: 'text-only-header',
    resizable: true,
    sortable: true,
  },
  popupParent: document.body,
};

export const PoGrid: FunctionComponent<PoGridProps> = ({
  colDefs,
  rowData = [],
  gridOptions,
  onRowDoubleClickedCallback,
  isLoading = false,
  disableResizeColumnsToFit = false,
  noBorder = false,
  disableDefaultGridOptions = false,
  disableRowData = false,
}) => {
  const [internalGridOptions, setInternalGridOptions] = useState<GridOptions>(gridOptions ?? {});
  const { mode } = useContext(ColorModeContext);
  const gridRef = useRef<AgGridReact<unknown>>(null);
  const gridUuid = useRef(v4()).current;

  if (gridOptions) {
    gridOptions.gridId = gridUuid;
  }

  useEffect(() => {
    if (disableDefaultGridOptions) {
      return;
    }
    const defaultCellClasses: string[] = [];

    const baseGridOptions: GridOptions = {
      gridId: gridUuid,
      scrollbarWidth: 15,
      multiSortKey: 'ctrl',
      onRowDoubleClicked: (row: RowDoubleClickedEvent) => onRowDoubleClicked(row),
      defaultColDef: {
        headerClass: 'text-only-header',
        resizable: true,
        sortable: true,
        cellClass: defaultCellClasses,
      },
      popupParent: document.body,
    };

    setInternalGridOptions(!isEmpty(gridOptions) ? merge(baseGridOptions, gridOptions) : baseGridOptions);
  }, [gridOptions]);

  useEffect(() => {
    setLoadingOverlayComponent();
  }, [isLoading]);

  function setLoadingOverlayComponent() {
    if (gridRef.current && gridRef.current.api) {
      if (isLoading) {
        gridRef.current.api.showLoadingOverlay();
      } else {
        gridRef.current.api.hideOverlay();
      }
    }
  }

  const onGridReady = (params: GridReadyEvent) => {
    if (!disableResizeColumnsToFit) {
      params.api.sizeColumnsToFit();
    }

    setLoadingOverlayComponent();
  };

  const onGridReadyCustom = (params: GridReadyEvent) => {
    if (internalGridOptions.onGridReady) {
      internalGridOptions.onGridReady(params);
    }
    setLoadingOverlayComponent();
  };

  const onRowDoubleClicked = (row: RowDoubleClickedEvent) => {
    if (onRowDoubleClickedCallback) {
      onRowDoubleClickedCallback(row);
    }
  };

  const loadingOverlayComponent = useMemo(() => {
    return CustomLoadingOverlayComponent;
  }, []);

  const gridClasses = useMemo(() => {
    let classes = mode === 'dark' ? 'ag-theme-quartz-dark' : 'ag-theme-quartz';
    if (noBorder) {
      classes = classes.concat(' no-borders');
    }
    return classes;
  }, [mode]);

  return (
    <div className="flex h-full flex-1 flex-shrink-0 flex-grow flex-row relative ">
      <div className={`${gridClasses} flex-grow overflow-hidden `}>
        <AgGridReact
          ref={gridRef}
          key={gridUuid}
          gridId={gridUuid}
          columnDefs={colDefs}
          rowData={disableRowData ? undefined : rowData}
          gridOptions={internalGridOptions}
          onGridReady={disableDefaultGridOptions ? onGridReadyCustom : onGridReady}
          loadingOverlayComponent={loadingOverlayComponent}
          overlayNoRowsTemplate={isLoading ? 'Loading...' : 'No Rows to Display'}
        />
      </div>
    </div>
  );
};

export function CustomLoadingOverlayComponent(props: ICellRendererParams & { loadingMessage: string }) {
  return <LoadingBlock />;
}
