import { useApiCall } from '@/hooks/useApiCall';
import { LocationMap } from '@/modules/map/components/LocationMap';
import { Skeleton } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { assetService } from '../api/assets/asset.service';

interface AssetLocationMapProps {
  assetId: number;
}

const AssetLocationMap: FunctionComponent<AssetLocationMapProps> = ({ assetId }) => {
  const { data, isLoading } = useApiCall(() => assetService.getPosition(assetId));
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return (
    <>
      {isLoading || !isMounted ? (
        <Skeleton variant="rectangular" height={350} className="rounded-lg" />
      ) : !data ? (
        <div>No GPS Location found.</div>
      ) : (
        <LocationMap locationMarker={data} />
      )}
    </>
  );
};

export default AssetLocationMap;
