export enum TrackerType {
  RFID = 0,
  Barcode = 1,
  UnaTag = 2,
}

export interface TrackerReadDTO extends TrackerCreateDTO {
  id: number;

  asset?: {
    id: number;
    code: string;
  };

  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface TrackerUpdateDTO extends TrackerCreateDTO {}
export interface TrackerCreateDTO {
  type: TrackerType;
  epc?: string | null | undefined;
  barcode?: string | null | undefined;
  deviceId?: string | null | undefined;
}
