export const APPLICATION_LOG_TRANSLATIONS_ENGLISH = {
  applicationLog: {
    title: 'Application Log',
    messageId: 'Message ID',
    logItem_one: 'Log item',
    logItem_other: 'Log items',
    occurredAt: 'Occurred At',
    message: 'Message',
    
    severity: {
        title: 'Severity',
        error: 'Error',
        warning: 'Warning',
        info: 'Info',
        all: 'All Severities',
    },
    category: {
        title: 'Category',
        import: 'Import',
        eventProcessing: 'Event Processing',
        other: 'Other',
        all: 'All Categories',
    }
  }
};
