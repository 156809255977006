import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { BalanceOverTimeWidgetConfiguration } from './BalanceOverTimeWidgetConfiguration';
import { useDashboardContext } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { useTranslation } from '@/lib';
import { LocationSelect } from '@/modules/locations/components/LocationSelect';
import { useApiCall } from '@/hooks/useApiCall';
import { assetTypeService } from '@/modules/asset-types/api/asset-types/asset-type.service';
import { AssetTypeModel } from '@/modules/asset-types/types/AssetTypeModel';
import CheckboxList from '@/components/inputs/CheckboxList';

interface BalanceOverTimeWidgetConfigurationFormProps {
  configuration: BalanceOverTimeWidgetConfiguration;
  id: string;
}

const BalanceOverTimeWidgetConfigurationForm: FunctionComponent<BalanceOverTimeWidgetConfigurationFormProps> = ({ configuration, id }) => {
  const { data, isLoading, isError, fetchData } = useApiCall<AssetTypeModel[]>(() => assetTypeService.getAll());
  const { widgets, updateWidget, updateWidgetConfiguration } = useDashboardContext();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-3 ">
      <TextField
        label="Name"
        value={configuration.title}
        onChange={(e) => {
          updateWidgetConfiguration<BalanceOverTimeWidgetConfiguration>(id, {
            title: e.currentTarget.value,
          });
        }}
      />

      <LocationSelect
        // exludedLocationIds={[configuration.locationId]}
        selectedLocationId={configuration.locationId}
        onSelected={(selectedLocation) => {
          if (selectedLocation) {
            updateWidgetConfiguration<BalanceOverTimeWidgetConfiguration>(id, {
              title: "Balance over time for '" + selectedLocation.dto.name + "'",
              locationId: selectedLocation?.dto.id,
            });
          }
        }}
        helperText={'Select a Location to filter on.'}
      />

      <FormControl>
        <InputLabel>Period</InputLabel>
        <Select
          label={'Period'}
          value={configuration.periodInDays}
          onChange={(e) => {
            updateWidgetConfiguration<BalanceOverTimeWidgetConfiguration>(id, {
              periodInDays: e.target.value as number,
            });
          }}
        >
          <MenuItem key={1} value={30}>
            {t(`dashboard.balance_over_time_period.last_30_days`)}
          </MenuItem>
          <MenuItem key={2} value={90}>
            {t(`dashboard.balance_over_time_period.last_90_days`)}
          </MenuItem>
        </Select>
      </FormControl>

      <div>
        <div className="text-sm">Asset Types</div>
        <Typography variant="caption"> Select up to 5 Asset Types to display</Typography>
        <CheckboxList<AssetTypeModel>
          getLabel={(item) => item.name}
          getValue={(item) => item.id.toString()}
          items={data ?? ([] as AssetTypeModel[])}
          selectedItems={
            configuration.assetTypeIds
              ? data?.filter(
                  (assetType) => configuration.assetTypeIds?.some((selectedAssetTypeId) => selectedAssetTypeId === assetType.id),
                ) ?? ([] as AssetTypeModel[])
              : []
          }
          onChange={(selectedItems) => {
            updateWidgetConfiguration<BalanceOverTimeWidgetConfiguration>(id, {
              assetTypeIds: selectedItems.map((item) => item.id),
            });
          }}
          maxSelectedItems={5}
        />
      </div>
      {/* <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Group by</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label={'Group by'}
          defaultValue={configuration.balanceOverTimeType}
          onChange={(e) => {
            updateWidget<BalanceOverTimeWidgetConfiguration>(id, {
              configuration: {
                title: 'Balances per ' + lowerCase(e.target.value as GroupPerTimeType),
                balanceOverTimeType: e.target.value as GroupPerTimeType,
                locationId: configuration.locationId,
              },
            });
          }}
        >
          {Object.values(GroupPerTimeType).map((totalType, i) => {
            if (isNumber(totalType)) {
              return;
            }
            return (
              <MenuItem key={totalType} value={totalType}>
                {t(`dashboard.group_per_time_type.${totalType}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl> */}
    </div>
  );
};

export default BalanceOverTimeWidgetConfigurationForm;
