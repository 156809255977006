import { DTO } from '@/types/dto-wrapper';
import { AssetTypeReadDTO } from '../api/asset-types/asset-type.contracts';

export class AssetTypeModel extends DTO<AssetTypeReadDTO> {
  constructor(dto: AssetTypeReadDTO) {
    super(dto);
  }

  static fromDTO(dto: AssetTypeReadDTO): AssetTypeModel {
    return new AssetTypeModel(dto);
  }

  toDTO(): AssetTypeReadDTO {
    return this.dto;
  }

  get id(): number {
    return this.dto.id;
  }

  get name(): string {
    return this.dto.name;
  }

  get code(): string {
    return this.dto.code;
  }

  get description(): string {
    return this.dto.description;
  }

  get widthInMm(): number {
    return this.dto.widthInMm;
  }

  get heightInMm(): number {
    return this.dto.heightInMm;
  }

  get depthInMm(): number {
    return this.dto.depthInMm;
  }

  get volumeInCubicMm(): number {
    return this.dto.volumeInCubicMm;
  }

  get modelYear(): number {
    return this.dto.modelYear;
  }

  get monetaryValue(): number | undefined {
    return this.dto.monetaryValue;
  }

  get count(): number {
    return this.dto.count;
  }
}
