
export const DEVICE_TRANSLATIONS_ENGLISH = {
    device: {
      title: 'Devices',
      add: 'Add Device',
      name: 'Name',
      code: 'Code',
      brand: 'Brand',
      type: 'Type',
      serialNumber: 'Serial Number',
      lastEventReceivedAt: 'Last Event Received At',
      device: 'Device',
      new: 'New Device',
      details: 'Details',
      error_create: 'Error creating device',
      error_update: 'Error updating device',
      create: 'Device created',
      update: 'Device updated',
      noPermission: 'You do not have permission to edit devices',
      code_helper: 'This code can be used in the Posito Scan app to link the app to this Device.',
    },
  };
  