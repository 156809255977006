import { ApiResponse } from '@/lib/api/api-response';
import { useState, useEffect, useCallback, useRef } from 'react';

export function useApiCall<T>(initialApiCallArg: () => Promise<ApiResponse<T>>) {
  const apiCallArgRef = useRef<() => Promise<ApiResponse<T>>>(initialApiCallArg); // ✨ Use useRef here
  const [data, setData] = useState<T>();
  const [response, setResponse] = useState<ApiResponse<T>>();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const setApiCallArg = useCallback((newApiCallArg: () => Promise<ApiResponse<T>>) => {
    apiCallArgRef.current = newApiCallArg; // ✨ Update the current value of the ref
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await apiCallArgRef.current(); // ✨ Use current value of the ref
      setResponse(result);
      setIsError(!result.isSuccess);
      setData(result.payload);
    } catch (error) {
      console.log({ error });

      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading, isError, fetchData, response, setApiCallArg };
}
