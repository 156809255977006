import { useDate } from '@/hooks/useDate';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FunctionComponent } from 'react';

export const PeriodInDaysCellRenderer: FunctionComponent<ICellRendererParams<unknown>> = (props: ICellRendererParams<unknown>) => {
  const { formatPeriodInDaysFromNow, formatDateTimestamp } = useDate();

  return (
    <Tooltip title={'Location entered at: ' + formatDateTimestamp(props.value)} placement="left">
      <div>{props.value && formatPeriodInDaysFromNow(props.value) + ' days'}</div>
    </Tooltip>
  );
};
