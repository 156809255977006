import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import AssetTypeFormLogic from './AssetTypeFormLogic';
import { AssetTypeFormModel } from './AssetTypeFormModel';
import { useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash-es';
import { assetTypeService } from '../../api/asset-types/asset-type.service';
import { AssetTypeModel } from '../../types/AssetTypeModel';
import { ApiResponseCode } from '@/lib/api/api-response-code';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

interface AssetTypeFormApiProps {
  isCreate?: boolean;
  assetTypeId?: number;
}

const AssetTypeFormApi: FunctionComponent<AssetTypeFormApiProps> = ({ isCreate, assetTypeId }) => {
  const navigate = useNavigate();
  const hasAssetTypeEditPermission = useUser().hasPermission(Permission.ASSET_TYPES_EDIT);
  const { t } = useTranslation();

  const handleSubmit = async (data: AssetTypeFormModel) => {
    if (!hasAssetTypeEditPermission) {
      toast.error(t('assetTypes.noPermission'));
      return false;
    }

    // Process data
    if (isCreate) {
      const response = await assetTypeService.create({
        name: data.name,
        code: data.code,
        description: data.description,
        widthInMm: data.widthInMm,
        heightInMm: data.heightInMm,
        depthInMm: data.depthInMm,
        volumeInCubicMm: data.volumeInCubicMm,
        modelYear: data.modelYear,
        monetaryValue: data.monetaryValue,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast(t('assetTypes.duplicate'));
        } else {
          toast(t('assetType.error_create'), {
            type: 'error',
          });
        }
      } else {
        toast.success(t('assetType.create'));
        navigate('/app/configuration/assets/types/');
      }
    } else if (isNumber(assetTypeId)) {
      const response = await assetTypeService.update(assetTypeId, {
        name: data.name,
        code: data.code,
        description: data.description,
        widthInMm: data.widthInMm,
        heightInMm: data.heightInMm,
        depthInMm: data.depthInMm,
        volumeInCubicMm: data.volumeInCubicMm,
        modelYear: data.modelYear,
        monetaryValue: data.monetaryValue,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast(t('assetTypes.duplicate'));
        } else {
          toast(t('assetType.error_upload'), {
            type: 'error',
          });
        }
      } else {
        // Upload image if it exists
        if (data.image) {
          const imageResponse = await assetTypeService.uploadImage(assetTypeId, data.image);
          if (!imageResponse.isSuccess) {
            toast(t('assetType.error_image_upload'), {
              type: 'error',
            });
          }
          // Remove image if it exists and is explicitly set to null
        } else if (data.image === null) {
          const imageResponse = await assetTypeService.removeImage(assetTypeId);
          if (!imageResponse.isSuccess) {
            toast(t('assetType.error_image_remove'), {
              type: 'error',
            });
          }
        }

        toast.success(t('assetType.update'));
        navigate('/app/configuration/assets/types/');
      }
    }
    return true;
  };

  if (isCreate || !assetTypeId) {
    const newAssetType: AssetTypeFormModel = {
      name: '',
      code: '',
      description: '',
    };
    return <AssetTypeFormLogic initialValues={newAssetType} onSubmit={handleSubmit} isCreate />;
  } else {
    const { data, isLoading, isError, fetchData } = useApiCall<AssetTypeModel>(() => assetTypeService.getById(assetTypeId));

    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>No Data Error</div>;

    return <AssetTypeFormLogic initialValues={data.dto} onSubmit={handleSubmit} isCreate={isCreate} />;
  }
};

export default AssetTypeFormApi;
