import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent, useState } from 'react';
import { EventModel, EventModelFieldNames } from '../types/EventModel';
import { useApiCall } from '@/hooks/useApiCall';
import { eventService } from '../api/events/event.service';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { GridOptions, ICellRendererParams, RowDoubleClickedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import LinkCellRenderer, { LinkCellRendererParams } from '../../../components/grid/cells/LinkCellRenderer';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import FilterBar from '@/components/filterbar/FilterBar';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import LocationFilter from '@/components/filterbar/filters/LocationFilter';
import AssetFilter from '@/components/filterbar/filters/AssetFilter';
import DateFilter from '@/components/filterbar/filters/DateFilter';
import { TrackerTypeCellRenderer } from '@/components/grid/cells/TrackerTypeCellRenderer';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import EventInfoDrawer from '../components/info-drawer/EventInfoDrawer';
import { GpsAccuracyLevelCellRenderer, formatGpsAccuracy } from '@/components/grid/cells/GpsAccuracyCellRenderer';
import ListSelectFilter from '@/components/filterbar/filters/ListSelectFilter';
import { EventType } from '../api/events/event.contracts';
import { trackerTypeToString } from '@/modules/trackers/lib/tracker.utils';
import { useTranslation } from '@/lib';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import ResultDataText from '@/components/filterbar/ResultDataText';

const EVENT_FETCH_LIMIT = 1000;

export const EventsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const { filters, setFiltersToUrl } = useQueryParamsFilters(
    {
      dateFrom: dayjs().subtract(1, 'month').startOf('day').utc(true),
    },
    CacheKey.EVENT_FILTERS,
  );

  const { data, isLoading, isError, setApiCallArg } = useApiCall<EventModel[]>(() =>
    eventService.get({
      dateFrom: filters.current.dateFrom,
      dateTo: filters.current.dateTo,
      locationId: filters.current.locationId,
      assetId: filters.current.assetId,
      eventType: filters.current.eventType,
      limit: EVENT_FETCH_LIMIT,
    }),
  );

  const customGridOptions: GridOptions<EventModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<EventModel, unknown>) {
      setSelectedEventId(event.data?.id);
      setIsDrawerOpen(true);
    },
    getRowId: (data) => data.data.id.toString(),
    defaultCsvExportParams: {
      fileName: `Events export.csv`,
    },
    defaultExcelExportParams: {
      fileName: `Events export.xlsx`,
    },
  };

  const columns: ColDefOrGroup<EventModel>[] = [
    {
      field: EventModelFieldNames.AssetCode,
      headerName: t('event.asset_code'),
    },
    {
      field: EventModelFieldNames.OccurredAt,
      cellRenderer: RelativeDateCellRenderer,
      headerName: t('event.occurred_at'),
    },
    {
      field: EventModelFieldNames.LocationName,
      headerName: t('event.location'),
      cellRenderer: LinkCellRenderer,
      cellRendererParams: (params: ICellRendererParams<EventModel>): LinkCellRendererParams => ({
        pathname: `/app/locations/${params.data?.location.id}`,
      }),
    },
    {
      field: EventModelFieldNames.TrackerType,
      headerName: t('event.tracker_type'),
      cellRenderer: TrackerTypeCellRenderer,
      valueFormatter: (params: ValueFormatterParams<EventModel>) => {
        return trackerTypeToString(params.data?.tracker?.type, t) ?? '';
      },
    },
    {
      colId: 'type',
      headerName: t('event.type.title'),
      cellClass: 'first-letter:uppercase',
      valueGetter: (params: ValueGetterParams<EventModel>) => {
        return t(`event.type.${params.data?.type}`);
      },
    },
    {
      colId: 'source',
      headerName: t('event.source'),
      valueGetter: (params: ValueGetterParams<EventModel>) => {
        return t(`event.event_source.${params.data?.eventSource}`);
      },
    },
    {
      headerName: t('event.coordinates_accuracy'),
      field: EventModelFieldNames.GpsAccuracyLevel,
      cellRenderer: GpsAccuracyLevelCellRenderer,
      valueFormatter: (params: ValueFormatterParams<EventModel>) => {
        if (params.data?.gpsAccuracyLevel) return formatGpsAccuracy(params.data?.gpsAccuracyLevel);
        else {
          return t('unknown');
        }
      },
    },
    {
      headerName: t('event.coordinates_source'),
      field: EventModelFieldNames.GpsSourceType,
      valueGetter: (params: ValueGetterParams<EventModel>) => {
        return t(`event.gps_source.${params.data?.gpsSourceType}`);
      },
    },
  ];

  const handleSearch = (filterValues: FilterValues) => {
    console.log(JSON.stringify(filterValues));
    if (!validateFilterValues(filterValues)) {
      return;
    }

    setApiCallArg(() =>
      eventService.get({
        locationId: filterValues.locationId,
        assetId: filterValues.assetId,
        dateFrom: filterValues.dateFrom,
        dateTo: filterValues.dateTo,
        eventType: filterValues.eventType,
        limit: EVENT_FETCH_LIMIT,
      }),
    );

    setFiltersToUrl(filterValues);
  };

  function onFilterChange(filterValues: FilterValues) {
    console.log(JSON.stringify(filterValues));
  }

  function validateFilterValues(filterValues: FilterValues): boolean {
    // If the daterange is larger than 1 month, show a warning
    if (filterValues.dateFrom && filterValues.dateTo) {
      // Date from cannot be after date to
      if (filterValues.dateFrom.isAfter(filterValues.dateTo)) {
        toast.warning(t('event.errors.date_from_after'));
        return false;
      } else if (filterValues.dateTo.isBefore(filterValues.dateFrom)) {
        toast.warning(t('event.errors.date_to_before'));
        return false;
      }

      const diffDays = filterValues.dateTo.diff(filterValues.dateFrom, 'days');
      if (diffDays > 31) {
        toast.warning(t('event.errors.too_large_daterange'));
        return false;
      }
    } else if (filterValues.dateFrom) {
      const diffDays = dayjs().diff(filterValues.dateFrom, 'days');
      console.log({ diffDays, dateFrom: filterValues.dateFrom });

      if (diffDays > 31) {
        toast.warning(t('event.errors.too_large_daterange'));
        return false;
      }
    } else if (filterValues.dateTo) {
      const diffDays = dayjs().diff(filterValues.dateTo, 'days');
      if (diffDays > 31) {
        toast.warning(t('event.errors.too_large_daterange'));
        return false;
      }
    }
    return true;
  }

  return (
    <TabbedPageLayout>
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col  ">
          {isError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <>
              <div className="mb-2 flex items-center justify-between">
                <div className="flex items-center">
                  <FilterBar onSearch={handleSearch} onChange={onFilterChange} initialFilterValues={filters.current} showAsPopover>
                    <div className="flex flex-col gap-y-2">
                      <div className="w-full">
                        <LocationFilter label="Location" />
                      </div>
                      <div className="w-full">
                        <AssetFilter label="Asset" />
                      </div>
                      <div className="flex gap-x-2">
                        <div className="w-48">
                          {/* .utc(true) takes the date as is without converting from local time first */}
                          <DateFilter
                            label="Date From"
                            filterField={FilterFieldName.dateFrom}
                            processValue={(date) => date.startOf('day').utc(true)}
                          />
                        </div>
                        <div className="w-48">
                          <DateFilter
                            label="Date To"
                            filterField={FilterFieldName.dateTo}
                            processValue={(date) => date.endOf('day').utc(true)}
                          />
                        </div>
                      </div>
                      <ListSelectFilter
                        label="Event Type"
                        filterFieldName={FilterFieldName.eventType}
                        options={[
                          EventType.Incoming,
                          EventType.Inventory,
                          EventType.Observation,
                          EventType.Outgoing,
                          EventType.ADMINISTRATIVE,
                          null,
                        ]}
                        renderOption={(item) => {
                          switch (item) {
                            case null:
                              return <div className="italic">{t('event.type.all')}</div>;
                            default:
                              return t(`event.type.${item}`);
                          }
                        }}
                        valueExtractor={(item) => item}
                      />
                    </div>
                    <FilterBarSearchButton isLoading={isLoading} />
                  </FilterBar>
                  {data !== undefined && !isLoading && (
                    <ResultDataText data={data} name={t('event.event', {count: data.length ?? 0})} maxLength={EVENT_FETCH_LIMIT} />
                  )}
                </div>
              </div>
              <PoGrid isLoading={isLoading} colDefs={columns} rowData={data} gridOptions={customGridOptions} />
            </>
          )}
        </div>
        {selectedEventId !== undefined && (
          <EventInfoDrawer eventId={selectedEventId} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
        )}
      </TabbedPageLayoutBody>
    </TabbedPageLayout>
  );
};
