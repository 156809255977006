import { Navigate, RouteObject } from 'react-router-dom';
import AssetsLayout from '../layouts/AssetsLayout';
import { AssetsPage } from '../pages/AssetsPage';
import { AssetDetailPage } from '../pages/AssetDetailPage';

export const ASSET_ROUTES: RouteObject[] = [
  {
    path: 'assets',
    element: <AssetsLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        element: <AssetsPage />,
      },
      {
        path: 'create',
        element: <AssetDetailPage isCreate={true} />,
      },
      {
        path: ':assetId',
        element: <AssetDetailPage />,
      },
    ],
  },
];
