import React, { useState, useRef, useEffect } from 'react';
import { BarcodeDetector as BarcodeDetectorPolyfill } from 'barcode-detector';
import { toast } from 'react-toastify';

interface BarcodeScannerProps {
  onBarcodeScanned: (barcode: string) => void;
}

/**
 * Use chrome://flags/#unsafely-treat-insecure-origin-as-secure in Chrome to allow camera access on localhost
 *
 * Add the host with port (e.g. http://localhost:3000) to the list
 * @returns
 */
const BarcodeScanner: React.FC<BarcodeScannerProps> = ({ onBarcodeScanned }) => {
  const [barcode, setBarcode] = useState<string | null>(null);
  const [deviceNames, setDeviceNames] = useState<string[]>([]);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    let cameraStream: MediaStream | null = null;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints: MediaStreamConstraints = {
        video: {
          facingMode: 'environment',
        },
      };

      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const videoDevices = devices.filter((device) => device.kind === 'videoinput');

          const lastCamera = videoDevices[videoDevices.length - 1];

          if (lastCamera) {
            constraints.video = { deviceId: lastCamera.deviceId };
          }

          return navigator.mediaDevices.getUserMedia(constraints);
        })
        .then((stream) => {
          if (videoRef.current) {
            cameraStream = stream;
            videoRef.current.srcObject = stream;
            videoRef.current.play();
            startBarcodeDetection();
          }
        })
        .catch((err) => console.error(err));

      return () => {
        if (cameraStream) {
          videoRef.current?.pause();
          cameraStream.getTracks().forEach((track) => track.stop());
        }
      };
    } else {
      toast.error('Media Devices are not supported by this browser.');
      console.error('Media Devices are not supported by this browser.');
    }
    return;
  }, []);

  const startBarcodeDetection = async () => {
    if (!('BarcodeDetector' in window)) {
      toast.error('Barcode Detector is not supported by this browser.');
      console.error('Barcode Detector is not supported by this browser.');
      return;
    }

    try {
      const barcodeDetector = new BarcodeDetectorPolyfill();
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d', {
        willReadFrequently: true,
      });

      const captureScreenshot = () => {
        if (videoRef.current && context) {
          canvas.width = videoRef.current.videoWidth;
          canvas.height = videoRef.current.videoHeight;
          context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          barcodeDetector.detect(imageData).then((barcodes) => {
            if (barcodes.length > 0) {
              const scannedBarcode = barcodes[0].rawValue;
              setBarcode(scannedBarcode);
              onBarcodeScanned(scannedBarcode);
            }
          });
        }
      };

      const intervalId = setInterval(captureScreenshot, 1000);

      return () => {
        clearInterval(intervalId);
      };
    } catch (e) {
      console.error(e);
    }

    return;
  };

  return (
    <div>
      <video ref={videoRef} className="w-full rounded-xl border-2 border-gray-500 shadow-md ">
        <track kind="captions" />
      </video>
    </div>
  );
};

export default BarcodeScanner;
