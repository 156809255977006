import { DisplayMode } from '@/types/display-mode.enum';

export const BALANCE_TRANSLATIONS_ENGLISH = {
  balance: {
    title: 'Balances',
    hierarchical: 'Hierarchical',
    individual: 'Individual',
    total_balance: 'Total Balance',
    total_balance_helper_text: 'Balance at this location: {{balance}}, All children: {{children}}',
    incoming: 'Incoming',
    incoming_helper_text: 'Incoming at this location: {{balance}}, All children: {{children}}',
    outgoing: 'Outgoing',
    outgoing_helper_text: 'Outgoing at this location: {{balance}}, All children: {{children}}',
    return_rate: 'Return Rate',
    staytime: 'Staytime',
    staytime_helper_text: 'Stale assets at this location: {{balance}}, All children: {{children}}',
    [DisplayMode.HIERARCHICAL]: 'Hierarchical',
    [DisplayMode.INDIVIDUAL]: 'Individual',
    location_balance_helper_text: "This row shows the summed total of the balance of this location + all of it's {{count}} child locations",
  },
};
