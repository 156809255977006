import { FunctionComponent, useContext } from 'react';
import { MainMenuItemGroup } from '../types/MenuItem';
import { MainMenuBottom } from './MainMenuBottom';
import { MainMenuItem } from './MainMenuItem';
import { SettingsContext } from '@/modules/settings/contexts/settings.context';
import { SettingKey } from '@/modules/settings/api/settings/setting.contracts';
import { ColorModeContext } from '../context/ColorModeContext';
import { useUser } from '@/modules/users/contexts/UserContext';

interface MainMenuProps {
  mainMenuItemGroups: MainMenuItemGroup[];
}

export const MainMenu: FunctionComponent<MainMenuProps> = ({ mainMenuItemGroups }) => {
  const { getSettingByKey } = useContext(SettingsContext);
  const { hasPermission } = useUser();
  const isWhiteLabled = getSettingByKey(SettingKey.WHITE_LABEL_ENABLED);

  const logoUrl = getSettingByKey(SettingKey.WHITE_LABEL_LOGO_URL);
  const logoDarkModeUrl = getSettingByKey(SettingKey.WHITE_LABEL_LOGO_DARK_MODE_URL);
  const { isDarkMode } = useContext(ColorModeContext);
  const logoForCurrentModeUrl = isDarkMode ? logoDarkModeUrl : logoUrl;

  return (
    <aside className="flex  w-48 shrink-0 flex-col items-start   justify-between overflow-y-auto border-r    border-gray-200 bg-gray-100   text-gray-700  dark:border-gray-600 dark:bg-gray-800">
      {/* <!-- Side Nav Bar--> */}
      <div className="w-full">
        <div className="flex h-[50px] w-full items-center border-b   border-gray-200   dark:border-gray-600 ">
          {!isWhiteLabled?.value && (
            /* <!-- Logo Section --> */
            <div className="mx-4 flex  ">
              <a className=" flex h-6 w-6 items-center no-underline" href="/app/dashboards">
                <div className="flex">
                  <svg width="24" height="24" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="fill-gray-600 dark:fill-gray-200"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M23.2454 11.6227C23.2454 14.9394 22.1074 17.5222 20.3349 19.3712C20.207 19.5046 20.0758 19.6342 19.9415 19.7599L11.6033 28L8.01547 24.4385V17.4709C9.12408 18.2609 10.4805 18.7255 11.9455 18.7255C15.69 18.7255 18.7254 15.6901 18.7254 11.9456C18.7254 8.20117 15.69 5.16571 11.9455 5.16571C8.20108 5.16571 5.16561 8.20117 5.16561 11.9456C5.16561 12.0612 5.1685 12.1761 5.17422 12.2902H5.16557V21.6096L3.27067 19.7287C3.14794 19.6127 3.02783 19.4935 2.91051 19.3712C1.13803 17.5222 0 14.9394 0 11.6227C0 5.20366 5.20366 0 11.6227 0C18.0417 0 23.2454 5.20366 23.2454 11.6227ZM15.7592 11.9455C15.7592 14.0518 14.0517 15.7592 11.9455 15.7592C9.83923 15.7592 8.13178 14.0518 8.13178 11.9455C8.13178 9.8393 9.83923 8.13185 11.9455 8.13185C14.0517 8.13185 15.7592 9.8393 15.7592 11.9455Z"
                    />
                  </svg>
                </div>
                <div className="pl-2 font-sans text-lg font-bold tracking-wide text-gray-600 dark:text-gray-200">Posito</div>
              </a>
            </div>
          )}
          {isWhiteLabled?.value && logoForCurrentModeUrl && (
            <div className=" mx-4 flex  items-center bg-gray-100 fill-gray-700 text-gray-700     dark:bg-gray-800  dark:fill-gray-400">
              <img
                style={{
                  fill: 'currentColor',
                }}
                alt="logo"
                src={logoForCurrentModeUrl.value.toString()}
              />
            </div>
          )}
        </div>

        <div className=" mx-2 mt-4 flex  flex-col gap-0.5">
          {mainMenuItemGroups
            .filter((mmg) => mmg.children?.some((item) => hasPermission(item.permission)) ?? true)
            .map((mmg) => (
              <div key={mmg.label} className="pb-2">
                <div className=" text-xs font-semibold  text-gray-500 dark:text-gray-400  pb-1.5   px-2   flex">{mmg.label}</div>
                {mmg.children
                  ?.filter((item) => hasPermission(item.permission))
                  .map((mmi) => (
                    <MainMenuItem label={mmi.label} pathname={mmi.url} activePathnames={mmi.activeRouteIds} icon={mmi.icon} key={mmi.url} />
                  ))}
              </div>
            ))}
        </div>
      </div>

      <div className="  flex w-full border-t  border-gray-200 px-2 py-2   dark:border-gray-600">
        <MainMenuBottom />
      </div>
    </aside>
  );
};
