export const ASSET_TYPE_TRANSLATIONS_ENGLISH = {
  assetTypes: {
    title: 'Asset Types',
    assetType: 'Asset Type',
    add: 'Add Asset Type',
    image: 'Image',
    code: 'Code',
    name: 'Name',
    description: 'Description',
    modelYear: 'Model Year',
    width: 'Width (mm)',
    height: 'Height (mm)',
    depth: 'Depth (mm)',
    volume: 'Volume (cubic mm)',
    monetaryValue: 'Monetary Value',
    numberOfAssets: '# of Assets',
    new: 'New Asset Type',
    details: 'Details',
    balance: {
      title: 'Balance',
      locationCode: 'Location Code',
      locationName: 'Location',
      current: 'Current Balance',
    },
    error_create: 'Error creating asset type',
    error_update: 'Error updating asset type',
    error_image_upload: 'Error uploading image',
    error_image_remove: 'Error removing image',
    create: 'Asset type created',
    update: 'Asset type updated',
    duplicate: 'The code is already in use, and must be unique. Please provide a different one.',
    noPermission: 'You do not have permission to edit asset types',
    deleted: 'Asset type deleted',
    delete_confirmation: 'Are you sure you want to delete this asset type?',
    error_hasAssets:
      'This asset type has assets and cannot be deleted, please assign a different asset type to the current assets of this asset type',
    error_delete: 'Error deleting asset type',
    asset_types: 'Asset Types',
    asset_types_helper_text: 'Select one or more asset types.',
  },
};
