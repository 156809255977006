import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import DeviceFormLogic from './DeviceFormLogic';
import { DeviceFormModel } from './DeviceFormModel';
import { useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash-es';
import { DeviceModel } from '../../types/DeviceModel';
import { deviceService } from '../../api/device/device.service';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

interface DeviceFormApiProps {
  isCreate?: boolean;
  deviceId?: number;
}

const DeviceFormApi: FunctionComponent<DeviceFormApiProps> = ({ isCreate, deviceId }) => {
  const navigate = useNavigate();
  const hasDeviceEditPermission = useUser().hasPermission(Permission.DEVICES_EDIT);
  const { t } = useTranslation();

  const handleSubmit = async (data: DeviceFormModel) => {
    if (!hasDeviceEditPermission) {
      toast.error(t('device.noPermission'));
      return false;
    }

    // Process data
    if (isCreate) {
      const response = await deviceService.create({
        name: data.name,
        code: data.code,
        brand: data.brand,
        type: data.type,
        serialNumber: data.serialNumber,
      });

      if (!response.isSuccess) {
        toast(t('device.error_create'), {
          type: 'error',
        });
      } else {
        toast.success(t('device.create'));
        navigate('/app/configuration/devices/overview');
      }
    } else if (isNumber(deviceId)) {
      const response = await deviceService.update(deviceId, {
        name: data.name,
        code: data.code,
        brand: data.brand,
        type: data.type,
        serialNumber: data.serialNumber,
      });

      if (!response.isSuccess) {
        toast(t('device.error_update'), {
          type: 'error',
        });
      } else {
        toast.success(t('device.update'));
        navigate('/app/configuration/devices/overview/');
      }
    }
    return true;
  };

  if (isCreate || !deviceId) {
    const newDevice: DeviceFormModel = {
      name: '',
      code: '',
      brand: '',
      type: '',
      serialNumber: '',
    };
    return <DeviceFormLogic initialValues={newDevice} onSubmit={handleSubmit} isCreate />;
  } else {
    const { data, isLoading, isError, fetchData } = useApiCall<DeviceModel>(() => deviceService.getById(deviceId));

    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>{t('noData')}</div>;

    return <DeviceFormLogic initialValues={data} onSubmit={handleSubmit} isCreate={isCreate} />;
  }
};

export default DeviceFormApi;
