import { useEffect, FC, useState, Fragment } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { TrackerModel } from '../types/TrackerModel';
import { trackerService } from '../api/trackers/tracker.service';
import { TrackerTypeChip } from './TrackerTypeChip';
import { Info } from '@mui/icons-material';

interface TrackerListProps {
  onChange: (newList: number[]) => void;
  value: number[];
  assetId?: number;
  canUnlink?: boolean;
}
// define the component
export const TrackerList: FC<TrackerListProps> = ({ onChange, value, assetId, canUnlink }) => {
  // assuming items are stored in state, initiated with an empty array
  const [items, setItems] = useState<TrackerModel[]>([]);

  const handleRemove = (id: number) => {
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
    onChange(newItems.map((item) => item.id)); // triggering the callback with the new list
  };

  useEffect(() => {
    const fetchData = async () => {
      // placeholder API call
      const promises = value.map(trackerService.getById);
      const response = await Promise.all(promises);
      const data = response.map((res) => res.payload);
      console.log({ gotTheData: response });

      setItems(data);
    };

    fetchData();
  }, [value]);

  if (!value || value.length === 0) {
    return <div>No Trackers added yet.</div>;
  }
  return (
    <TableContainer className="w-full">
      <Table style={{ tableLayout: 'auto' }} size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Identifier</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <Fragment key={item.id}>
              <TableRow>
                <TableCell>
                  <TrackerTypeChip trackerType={item.type} />
                </TableCell>
                <TableCell> {item.uniqueIdentifier}</TableCell>
                <TableCell>{canUnlink && <Button onClick={() => handleRemove(item.id)}>Unlink</Button>}</TableCell>
              </TableRow>
              {item.asset && item.asset.id !== assetId && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <div className="flex items-start pb-4 text-blue-700">
                      <Info className="mr-1 mt-0.5 h-4 w-4" />
                      <span>
                        This Tracker is currently linked to: Asset with code &apos;{item.asset.code}&apos;. Saving this Asset will unlink it
                        from Asset with code &apos;{item.asset.code}&apos; and link it to this Asset.
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
