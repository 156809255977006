import { BASE_GRID_OPTIONS, PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellClickedEvent, GridApi, GridOptions, ICellRendererParams, RowDoubleClickedEvent } from 'ag-grid-community';
import LinkCellRenderer, { LinkCellRendererParams } from '@/components/grid/cells/LinkCellRenderer';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { LocationAssetModel } from '../../types/LocationAssetModel';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import { ActionBar } from '@/modules/application';
import { Button } from '@mui/material';
import { MoveAssetsToLocationModal } from '../MoveAssetsToLocationModal';
import { ColumnID } from '@/components/grid/column-ids';

interface AssetsAtLocationGridProps {
  data?: LocationAssetModel[];
  isError: boolean;
  noBorder?: boolean;
  isLoading?: boolean;
  onAssetsMoved: () => void;
}

export const AssetsAtLocationGrid: FunctionComponent<AssetsAtLocationGridProps> = ({ data, isError, noBorder, isLoading, onAssetsMoved }) => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<LocationAssetModel[]>([]);
  const [isMoveAssetModalOpen, setIsMoveAssetModalOpen] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi<LocationAssetModel> | null>(null);

  const onCellClicked = useCallback((params: CellClickedEvent<LocationAssetModel>) => {
    if (params.column.getColId() === ColumnID.SELECTION_CHECKBOX) {
      const node = params.node;
      node.setSelected(!node.isSelected());
    }
  }, []);

  const customGridOptions: GridOptions<LocationAssetModel> = {
    ...BASE_GRID_OPTIONS,
    getRowId: (params) => params.data.dto.assetId.toString(),
    onRowDoubleClicked(event: RowDoubleClickedEvent<LocationAssetModel, unknown>) {
      navigate(`/app/assets/${event.data?.dto.assetId}`);
    },
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    onCellClicked: (event) => {
      onCellClicked(event);
    },
    onSelectionChanged: (event) => {
      setSelectedRows(event.api.getSelectedRows());
    },
    onGridReady: (event) => {
      setGridApi(event.api);
    },
  };

  const columns: ColDefOrGroup<LocationAssetModel>[] = [
    {
      colId: ColumnID.SELECTION_CHECKBOX,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: false,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      suppressColumnsToolPanel: true,
      suppressMenu: true,
      lockVisible: true,
      sortable: false,
    },

    {
      field: 'dto.assetCode',
      headerName: 'Asset',
      cellRenderer: LinkCellRenderer,
      cellRendererParams: (params: ICellRendererParams<LocationAssetModel>): LinkCellRendererParams => ({
        pathname: params.data?.dto.assetId ? `/app/assets/${params.data?.dto.assetId}` : undefined,
      }),
    },
    {
      field: 'dto.assetTypeName',
      headerName: 'Type',
      cellRenderer: LinkCellRenderer,
      cellRendererParams: (params: ICellRendererParams<LocationAssetModel>): LinkCellRendererParams => ({
        pathname: params.data?.dto.assetTypeId ? `/app/configuration/assets/types/${params.data?.dto.assetTypeId}` : undefined,
      }),
    },
    {
      field: 'dto.dateArrivedAtLocation',
      headerName: 'Arrived',
      cellRenderer: RelativeDateCellRenderer,
      flex: 1,
    },
  ];

  function onMoveSelectedAssetsClicked() {
    setIsMoveAssetModalOpen(true);
  }

  function onMoveSelectedAssetsClosed() {
    setIsMoveAssetModalOpen(false);
    clearSelection();
  }

  function clearSelection() {
    setSelectedRows([]);

    if (gridApi) {
      gridApi.deselectAll();
    }
  }

  function onAssetsMovedByGrid() {
    onAssetsMoved();
  }

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      {isError ? (
        <ErrorLoadingDataAlert />
      ) : (
        <PoGrid
          noBorder={noBorder}
          isLoading={isLoading}
          colDefs={columns}
          rowData={data}
          gridOptions={customGridOptions}
          disableDefaultGridOptions
        />
      )}

      <ActionBar visible={selectedRows.length > 0}>
        <Button variant="outlined" color="primary" onClick={clearSelection}>
          Clear Selection
        </Button>
        <Button variant="contained" onClick={onMoveSelectedAssetsClicked}>
          Move Assets..
        </Button>
      </ActionBar>
      <MoveAssetsToLocationModal
        selectedAssetIds={selectedRows.map((lam) => lam.dto.assetId)}
        isOpen={isMoveAssetModalOpen}
        onClose={onMoveSelectedAssetsClosed}
        onAssetsMoved={onAssetsMovedByGrid}
      />
    </div>
  );
};
