import { PoGrid } from '@/components/grid/PoGrid';
import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiCall } from '@/hooks/useApiCall';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { deviceService } from '../../api/device/device.service';
import { DeviceModel } from '../../types/DeviceModel';
import { GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { AddOutlined } from '@mui/icons-material';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

export const DevicesPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading, isError, fetchData } = useApiCall<DeviceModel[]>(() => deviceService.getAll());

  const customGridOptions: GridOptions<DeviceModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<DeviceModel, unknown>) {
      navigate(`../detail/${event.data?.id}`);
    },
  };

  const colDefs: ColDefOrGroup<DeviceModel>[] = [
    {
      headerName: t('device.name'),
      field: 'name',
      width: 250,
    },
    {
      headerName: t('device.code'),
      field: 'code',
    },
    {
      headerName: t('device.brand'),
      field: 'brand',
    },
    {
      headerName: t('device.type'),
      field: 'type',
    },
    {
      headerName: t('device.serialNumber'),
      field: 'serialNumber',
    },
    {
      headerName: t('device.lastEventReceivedAt'),
      field: 'lastEventReceivedAt',
      cellRenderer: RelativeDateCellRenderer,
      flex: 1,
    },
  ];

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          actions={
            <RequirePermissionComponent permission={Permission.DEVICES_EDIT}>
              <Button startIcon={<AddOutlined></AddOutlined>} onClick={() => navigate('../create')}>
               {t('device.add')}
              </Button>
            </RequirePermissionComponent>
          }
        >
          <TabbedLayoutTopBarHeader icon={null}>{t('device.title')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        {isError ? (
          <ErrorLoadingDataAlert />
        ) : (
          <PoGrid isLoading={isLoading} colDefs={colDefs} rowData={data} gridOptions={customGridOptions} disableResizeColumnsToFit />
        )}
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
