import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { eventService } from '@/modules/events/api/events/event.service';
import { EventModel } from '@/modules/events/types/EventModel';
import { LocationEventsGrid } from './LocationEventsGrid';
import LoadingBlock from '@/components/feedback/LoadingBlock';

interface LocationEventsTabProps {
  locationId: number;
}

export const LocationEventsTab: FunctionComponent<LocationEventsTabProps> = ({ locationId }) => {
  const { data, isLoading, isError, fetchData } = useApiCall<EventModel[]>(() => eventService.get({ locationId }));
  return (
    <div className="mt-6 flex h-full flex-1 flex-grow flex-col">
      {isLoading ? <LoadingBlock /> : <LocationEventsGrid data={data} isError={isError}></LocationEventsGrid>}
    </div>
  );
};
