import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { RoleModel } from '../types/RoleModel';
import { Switch, Tooltip } from '@mui/material';
import { Permission } from '../api/permissions.contracts';
import { Lock } from '@mui/icons-material';
import { useUser } from '@/modules/users/contexts/UserContext';

type RoleEditorProps = {
  roles?: RoleModel[];
  onPermissionChanged: (role: RoleModel, permissions: Permission[], value: boolean) => void;
};

type PermissionEditorGroup = {
  name: string;
  permissions: PermissionEditItem[];
};

type PermissionEditItem = {
  name: string;
  description: string;
  permissions: Permission[];
};

const RoleEditor: FunctionComponent<RoleEditorProps> = ({ roles, onPermissionChanged }) => {
  const hasRolesEditPermission = useUser().hasPermission(Permission.USERS_ROLES_EDIT);
  const permissionEditorGroups: PermissionEditorGroup[] = [
    {
      name: 'Dashboards',
      permissions: [
        {
          name: 'View Dashboards',
          description: 'Allows a User to view Dashboards.',
          permissions: [Permission.DASHBOARDS_VIEW],
        },
        {
          name: 'Edit Dashboards',
          description: 'Allows a User to create, edit and delete Dashboards.',
          permissions: [Permission.DASHBOARDS_EDIT],
        },
      ],
    },
    {
      name: 'Map',
      permissions: [
        {
          name: 'View Map',
          description: 'Allows a User to view Map.',
          permissions: [Permission.MAP_VIEW],
        },
      ],
    },
    {
      name: 'Balances',
      permissions: [
        {
          name: 'View Balances',
          description: 'Allows a User to view Balances.',
          permissions: [Permission.BALANCES_VIEW],
        },
      ],
    },
    {
      name: 'Events',
      permissions: [
        {
          name: 'View Events',
          description: 'Allows a User to view Events.',
          permissions: [Permission.EVENTS_VIEW],
        },
      ],
    },
    {
      name: 'Locations',
      permissions: [
        {
          name: 'View Locations',
          description: 'Allows a User to view Locations.',
          permissions: [Permission.LOCATIONS_VIEW],
        },
        {
          name: 'Edit Locations',
          description: 'Allows a User to create, edit and delete Locations.',
          permissions: [Permission.LOCATIONS_EDIT],
        },
      ],
    },
    {
      name: 'Assets',
      permissions: [
        {
          name: 'View Assets',
          description: 'Allows a User to view Assets.',
          permissions: [Permission.ASSETS_VIEW],
        },
        {
          name: 'Edit Assets',
          description: 'Allows a User to create, edit and delete Assets.',
          permissions: [Permission.ASSETS_EDIT],
        },
      ],
    },
    {
      name: 'Asset Types',
      permissions: [
        {
          name: 'View Asset Types',
          description: 'Allows a User to view Asset Types.',
          permissions: [Permission.ASSET_TYPES_VIEW],
        },
        {
          name: 'Edit Asset Types',
          description: 'Allows a User to create, edit and delete Asset Types.',
          permissions: [Permission.ASSET_TYPES_EDIT],
        },
      ],
    },
    {
      name: 'Trackers',
      permissions: [
        {
          name: 'View Trackers',
          description: 'Allows a User to view Trackers.',
          permissions: [Permission.TRACKERS_VIEW],
        },
        {
          name: 'Edit Trackers',
          description: 'Allows a User to create, edit and delete Trackers.',
          permissions: [Permission.TRACKERS_EDIT],
        },
      ],
    },
    {
      name: 'Devices',
      permissions: [
        {
          name: 'View Devices',
          description: 'Allows a User to view Devices.',
          permissions: [Permission.DEVICES_VIEW],
        },
        {
          name: 'Edit Devices',
          description: 'Allows a User to create, edit and delete Devices.',
          permissions: [Permission.DEVICES_EDIT],
        },
      ],
    },
    {
      name: 'Labels',
      permissions: [
        {
          name: 'View Labels',
          description: 'Allows a User to view Labels.',
          permissions: [Permission.LABELS_VIEW],
        },
        {
          name: 'Edit Labels',
          description: 'Allows a User to create, edit and delete Labels.',
          permissions: [Permission.LABELS_EDIT],
        },
      ],
    },
    {
      name: 'Event Settings',
      permissions: [
        {
          name: 'View Event Settings',
          description: 'Allows a User to view Event Settings.',
          permissions: [Permission.EVENT_SETTINGS_VIEW],
        },
        {
          name: 'Edit Event Settings',
          description: 'Allows a User to create, edit and delete Event Settings.',
          permissions: [Permission.EVENT_SETTINGS_EDIT],
        },
      ],
    },
    {
      name: 'Connectors',
      permissions: [
        {
          name: 'View Connectors',
          description: 'Allows a User to view Connectors.',
          permissions: [Permission.CONNECTORS_VIEW],
        },
      ],
    },
    {
      name: 'API Settings',
      permissions: [
        {
          name: 'View API Settings',
          description: 'Allows a User to view API Settings.',
          permissions: [Permission.API_SETTINGS_VIEW],
        },
        {
          name: 'Edit API Settings',
          description: 'Allows a User to edit API Settings.',
          permissions: [Permission.API_SETTINGS_EDIT],
        },
      ],
    },
    {
      name: 'Users',
      permissions: [
        {
          name: 'View Users',
          description: 'Allows a User to view Users.',
          permissions: [Permission.USER_VIEW],
        },
        {
          name: 'Edit Users',
          description: 'Allows a User to edit Users.',
          permissions: [Permission.USER_EDIT],
        },
      ],
    },
    {
      name: 'Roles',
      permissions: [
        {
          name: 'View Roles',
          description: 'Allows a User to view Roles.',
          permissions: [Permission.USERS_ROLES_VIEW],
        },
        {
          name: 'Edit Roles',
          description: 'Allows a User to create, edit and delete Roles.',
          permissions: [Permission.USERS_ROLES_EDIT],
        },
      ],
    },
    {
      name: 'File Importer',
      permissions: [
        {
          name: 'Use File Importer',
          description: 'Allows a User to use the File Importer.',
          permissions: [Permission.FILE_IMPORTER_USE],
        },
      ],
    },
    {
      name: 'Application Log',
      permissions: [
        {
          name: 'View Application Log',
          description: 'Allows a User to view the Application Log.',
          permissions: [Permission.APPLICATION_LOG_VIEW],
        },
      ],
    },
    {
      name: 'Mobile',
      permissions: [
        {
          name: 'Mobile Connect',
          description: 'Allows a User to connect Trackers to Assets in the smartphone view of Posito.',
          permissions: [Permission.MOBILE_CONNECT],
        },
        {
          name: 'Mobile Events',
          description: 'Allows a User to view Events in the smartphone view of Posito.',
          permissions: [Permission.MOBILE_EVENTS],
        },
      ],
    },
  ];

  async function onPermissionChange(role: RoleModel, permissionGroup: PermissionEditItem, value: boolean) {
    let permissions = role.dto.permissions.map((p) => p.name);
    if (value) {
      permissions.push(...permissionGroup.permissions);
    } else {
      permissions = permissions.filter((p) => !permissionGroup.permissions.includes(p));
    }

    onPermissionChanged(role, permissions, value);

    // update localRoles
    const updatedRole = new RoleModel({
      ...role.dto,
      permissions: permissions.map((p) => ({ id: 0, name: p })),
    });

    const updatedRoles = localRoles.map((r) => (r.dto.id === role.dto.id ? updatedRole : r));
    setLocalRoles(updatedRoles);
  }

  const orderedRoles = roles?.sort((a, b) => a.dto.id - b.dto.id) ?? [];

  // keep localRoles copy in state of the roles prop
  const [localRoles, setLocalRoles] = useState<RoleModel[]>(orderedRoles ?? []);

  useEffect(() => {
    setLocalRoles(orderedRoles ?? []);
  }, [orderedRoles]);

  return (
    <div
      className="grid  rounded-md border overflow-hidden border-gray-200      dark:border-gray-600"
      style={{
        gridTemplateColumns: `1fr repeat(${localRoles?.length}, 165px)`,
      }}
    >
      {/* Header row */}
      <div className="uppercase text-xs font-semibold border-gray-200 bg-gray-100  border-b    dark:border-gray-600 dark:bg-gray-800 h-9 items-center justify-center flex">
        Actions
      </div>
      {localRoles?.map((role) => {
        return (
          <Tooltip
            key={role.dto.id}
            title={
              role.dto.isReadOnly
                ? 'This Role is a Read-Only Role and cannot be edited, to prevent locking yourself out of the system.'
                : null
            }
            placement="top"
          >
            <div
              key={role.dto.id}
              className="uppercase text-xs font-semibold border-gray-200 bg-gray-100  border-b border-l  px-1 flex-nowrap text-nowrap dark:border-gray-600 dark:bg-gray-800 h-9 items-center justify-center flex"
            >
              {role.description}
              {role.dto.isReadOnly && <Lock className="ml-1" fontSize="inherit" />}
            </div>
          </Tooltip>
        );
      })}
      {permissionEditorGroups.map((group, groupIndex) => (
        <Fragment key={group.name}>
          {/* Header row */}
          <div className="uppercase text-xs font-semibold border-gray-200  dark:border-gray-600  pt-3 tracking-wider  items-end px-3 pb-2 flex dark:text-gray-400">
            {group.name}
          </div>
          {localRoles?.map((role) => (
            <Fragment key={role.dto.id}>
              <div className={`border-l border-gray-200 dark:border-gray-600  ${role.dto.isReadOnly && 'bg-gray-850 bg-opacity-50'}  `}></div>
            </Fragment>
          ))}

          {/* Data rows */}
          {group.permissions.map((permission, index) => (
            <Fragment key={permission.name}>
              <div
                className={`px-3 py-1
                ${
                  index === group.permissions.length - 1 && groupIndex < permissionEditorGroups.length - 1
                    ? 'border-b border-gray-200 dark:border-gray-600 '
                    : ''
                }
                ${index === group.permissions.length - 1 ? 'pb-3' : ''}
                
                `}
              >
                <div className="text-base font-medium">{permission.name}</div>
                <div className="text-gray-700 text-xs dark:text-gray-400 pt-0.5">{permission.description}</div>
              </div>
              {localRoles?.map((role) => (
                <Tooltip
                  key={role.dto.id}
                  title={
                    role.dto.isReadOnly
                      ? 'This Role is a Read-Only Role and cannot be edited, to prevent locking yourself out of the system.'
                      : null
                  }
                  placement="top"
                >
                  <div
                    className={`p-2 border-l flex justify-center items-center border-gray-200 dark:border-gray-600   ${
                      index === group.permissions.length - 1 && groupIndex < permissionEditorGroups.length - 1 ? 'border-b  ' : ''
                    }
                    ${role.dto.isReadOnly && 'bg-gray-850 bg-opacity-50'} 
                    `}
                  >
                    <Switch
                      color="primary"
                      disabled={role.dto.isReadOnly || !hasRolesEditPermission}
                      checked={role.hasAllPermissions(permission.permissions)}
                      // value={role.hasPermission(permission.key)}

                      onChange={() => onPermissionChange(role, permission, !role.hasAllPermissions(permission.permissions))}
                    />
                  </div>
                </Tooltip>
              ))}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </div>
  );
};

export default RoleEditor;
