import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';

import { TrackerCreateDTO, TrackerReadDTO, TrackerUpdateDTO } from './tracker.contracts';
import { TrackerModel } from '../../types/TrackerModel';

export class TrackerService {
  public static basePath = 'tracker';

  async create(trackerToCreate: TrackerCreateDTO): Promise<ApiResponse<TrackerReadDTO>> {
    try {
      const applicationResponse = await apiClient.post<TrackerReadDTO>(TrackerService.basePath + '/', trackerToCreate);
      return applicationResponse;
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(trackerId: number, trackerToUpdate: TrackerUpdateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.patch<undefined>(`${TrackerService.basePath}/${trackerId}`, trackerToUpdate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAll(query: { searchText?: string; limit?: number } = { limit: 1000 }): Promise<ApiResponse<TrackerModel[]>> {
    try {
      const applicationResponse = await apiClient.get<TrackerReadDTO[]>(TrackerService.basePath, query);
      return applicationResponse.processPayload((payload) => {
        return payload.map(TrackerModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(id: number): Promise<ApiResponse<TrackerModel>> {
    try {
      const applicationResponse = await apiClient.get<TrackerReadDTO>(`${TrackerService.basePath}/${id}`);
      return applicationResponse.processPayload(TrackerModel.fromDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(id: number): Promise<ApiResponse<void>> {
    try {
      const applicationResponse = await apiClient.delete<void>(`${TrackerService.basePath}/${id}`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getByIdentifier(identifier: { barcode?: string; epc?: string; deviceId?: string }): Promise<ApiResponse<TrackerModel | null>> {
    try {
      const resp = await apiClient.get<TrackerReadDTO>(`${TrackerService.basePath}/by-identifier`, identifier);
      return resp.processPayload(TrackerModel.fromDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const trackerService = new TrackerService();
