import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { userService } from '../../../api/users/users.service';
import { UserModel } from '../../../types/UserModel';
import UserFormLogic from './UserFormLogic';
import { UserFormModel } from './UserFormModel';
import { useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash-es';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useUser } from '@/modules/users/contexts/UserContext';

interface UserFormApiProps {
  isCreate?: boolean;
  userId?: number;
}

const UserFormApi: FunctionComponent<UserFormApiProps> = ({ isCreate, userId }) => {
  const navigate = useNavigate();
  const hasUserEditPermission = useUser().hasPermission(Permission.USER_EDIT);

  const handleSubmit = async (data: UserFormModel) => {
    if (!hasUserEditPermission) {
      toast.error('You do not have permission to edit users');
      return false;
    }

    // Process data
    if (isCreate) {
      const response = await userService.createUser({
        email: data.email,
        name: data.name,
        password: data.password as string,
        roleIds: data.roleIds,
      });

      if (!response.isSuccess) {
        toast('Error Creating User', {
          type: 'error',
        });
      } else {
        toast.success('User created');
        navigate('/app/configuration/users/');
      }
    } else if (isNumber(userId)) {
      const response = await userService.updateUser(userId, {
        name: data.name,
        password: data.password as string,
        roleIds: data.roleIds,
      });

      if (!response.isSuccess) {
        toast('Error Updating User', {
          type: 'error',
        });
      } else {
        toast.success('User updated');
        navigate('/app/configuration/users/');
      }
    }
    // Post user/{id}/roles

    // Post user

    // return async function to submit data to backend
    return true;
  };

  if (isCreate || !userId) {
    const newUser: UserFormModel = { email: '', name: '', roleIds: [], password: '' };
    return <UserFormLogic initialValues={newUser} onSubmit={handleSubmit} isCreate />;
  } else {
    const { data, isLoading, isError, fetchData } = useApiCall<UserModel>(() => userService.getUserById(userId));

    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>No Data Error</div>;

    return (
      <UserFormLogic
        initialValues={{
          email: data.email,
          name: data.name,
          roleIds: data.roles.map((role) => role.dto.id) as [number, ...number[]],
          password: data.password,
        }}
        onSubmit={handleSubmit}
        isCreate={isCreate}
        userId={userId}
      />
    );
  }
};

export default UserFormApi;
