import { ApiResponse } from '@/lib/api/api-response';
import { mapboxClient } from '@/lib/api/base-client';
import { MAPBOX_ACCESS_TOKEN } from '@/lib/mapbox';
import { IGeocoderResult, MapboxEndpoint, MapboxPlaceType } from './mapbox.contracts';

interface Address {
  country?: string | null;
  city?: string | null;
  postalCode?: string | null;
  streetName?: string | null;
  streetNumber?: string | null;
}

class MapboxService {
  async forwardGeocode(addressToForwardGeocode: Address): Promise<ApiResponse<IGeocoderResult>> {
    try {
      const searchText = this.createSearchText(addressToForwardGeocode);
      const geoCodedAddresses = await mapboxClient.get<IGeocoderResult>(
        `/${MapboxEndpoint.PLACES}/${searchText}.json?access_token=${MAPBOX_ACCESS_TOKEN}&types=${MapboxPlaceType.ADDRESS}&autocomplete=false`,
      );

      return geoCodedAddresses;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  private createSearchText(address: Address): string {
    // Filter out any fields that are null or undefined and join the rest into a string
    const searchText = Object.values(address)
      .filter((value) => value !== null && value !== undefined)
      .join(' ');

    // Truncate the string to 256 characters
    let truncatedText = searchText.slice(0, 256);

    // If there are more than 20 words, keep only the first 20
    const words = truncatedText.split(/\s+/);
    if (words.length > 20) {
      truncatedText = words.slice(0, 20).join(' ');
    }

    // URL encode the string and replace any semicolons
    let encodedText = encodeURIComponent(truncatedText);
    encodedText = encodedText.replace(/%3B/g, ''); // %3B is the URL-encoded semicolon

    return encodedText;
  }
}

export const mapboxService = new MapboxService();
