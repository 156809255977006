export const SETTINGS_TRANSLATIONS_ENGLISH = {
  setting: {
    api: {
      label: 'API',
      title: 'API Settings',
      general_description: 'Common API settings',
      url_title: 'API URL',
      url_description: 'The Public Posito API URL you can use for integrations.',
      documentation_title: 'API Documentation',
      documentation_description: 'The Open API Documentation of the API.',
      keys_title: 'API Keys',
      keys_description: 'Create and manage the API keys that allow acces to the Public API.',
      manage_keys_title: 'Manage API Keys',
      manage_keys_description: 'You can create, rename, enable/disable and remove API Keys below.',
      add: 'Add API Key',
      add_title: 'Add API Key',
      add_name_label: 'Name',
      add_description: 'Enter a name for the new API Key',
      add_success: 'API Key added successfully',
      add_name_validation: 'Name must be between 1 and 25 characters',
      update_success: 'API Key updated successfully',
      delete: 'Delete API Key',
      delete_confirmation: 'Are you sure you want to delete this API Key?',
      delete_success: 'API Key deleted successfully',
      name: 'Name',
      created: 'Created',
      key: 'Key',
      active: 'Active',
    },
    connector: {
      title: 'Connector Settings',
      unaconnect_title: 'UnaConnect',
      unaconnect_description: 'A Portal that can send Tracker Events to Posito.',
      unaconnect_url_title: 'UnaTag Webhook URL',
      unaconnect_url_description: 'The URL to send UnaTag events to. Configure this in UnaConnect.',
      nedap_title: 'Nedap Harmony',
      nedap_description: 'A Portal that can send Tracker Events to Posito.',
      nedap_url_title: 'Nedap Harmony Webhook URL',
      nedap_url_description: 'The URL to send Nedap Harmony events to. Configure this in a Nedap Harmony Hook.',
      epc_title: 'EPC Events',
      epc_description: 'An endpoint to send EPC events towards.',
      epc_url_title: 'EPC Webhook URL',
      epc_url_description: 'The URL to send EPC events to. Configure this in your custom software.',
    },
    event: {
      title: 'Event Settings',
      creation_title: 'Event Creation',
      creation_description: 'Configure how Events are created.',
      auto_create_trackers_title: 'Automatically create Trackers',
      auto_create_trackers_description:
        'When a Tracker is observed (scanned/manual input) which doesn’t match any identifier of the current Trackers in the system, a new Tracker with the observed identifier will be created.',
      update_success: 'Updated setting: Automatically create Trackers',
    },
  },
};
