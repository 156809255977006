/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from '@mui/material';
import { isNumber } from 'lodash-es';
import { FunctionComponent } from 'react';
import { LabelColor } from '../api/label.contracts';
import { useTranslation } from '@/lib';

type LabelColorSelectProps = {
  selectedColor: number | null | undefined;
  onChange: (color: number | null) => void;
};

const LabelColorSelect: FunctionComponent<LabelColorSelectProps> = ({ selectedColor, onChange }) => {
  const { t } = useTranslation();
  const colorClass = 'bg-transparent';

  function getColorClass(color: LabelColor) {
    let colorClass = 'bg-transparent';
    switch (color) {
      case LabelColor.Red:
        colorClass = 'bg-red-500 dark:bg-red-400';
        break;
      case LabelColor.Yellow:
        colorClass = 'bg-yellow-500 dark:bg-yellow-400';
        break;
      case LabelColor.Green:
        colorClass = 'bg-green-500 dark:bg-green-400';
        break;
      case LabelColor.Blue:
        colorClass = 'bg-blue-500 dark:bg-blue-400';
        break;
      case LabelColor.Indigo:
        colorClass = 'bg-indigo-500 dark:bg-indigo-400';
        break;
      case LabelColor.Purple:
        colorClass = 'bg-purple-500 dark:bg-purple-400';
        break;
      case LabelColor.Pink:
        colorClass = 'bg-pink-500 dark:bg-pink-400';
        break;
      case LabelColor.Gray:
        colorClass = 'bg-gray-500 dark:bg-gray-400';
        break;
    }
    return colorClass;
  }
  return (
    <div className="flex flex-col">
      <div className="mb-2">
        <Typography variant="h5" className="" color="text.secondary">
          {t('label.color')}
        </Typography>
      </div>
      <div className="flex space-x-2">
        {Object.values(LabelColor)
          .filter((value) => isNumber(value))
          .map((color) => color as number)
          .map((color) => (
            <div
              key={color}
              className={`w-8 h-8 rounded-full cursor-pointer ring-2 ring-offset-2 ring-offset-white dark:ring-offset-gray-800 ring-black dark:ring-white transition-all ${
                color === selectedColor ? 'ring-opacity-100' : 'ring-opacity-0 dark:ring-opacity-0'
              } ${getColorClass(color)}`}
              onClick={() => {
                // Get number value from color enum

                console.log('colorNumber', color);
                onChange(color);
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default LabelColorSelect;
