import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useEffect, useState } from 'react';
import { locationService } from '../../api/locations/location.service';
import { LocationAssetModel } from '../../types/LocationAssetModel';
import { AssetsAtLocationGrid } from './AssetsAtLocationGrid';
import { useParams } from 'react-router-dom';
import { useFormatting } from '@/hooks/useFormatting';
import { DisplayMode } from '@/types/display-mode.enum';
import LocationHierarchyToggleButton from '../LocationHierarchyToggleButton';

export const LocationAssetsTab: FunctionComponent = () => {
  const { formatNumber } = useFormatting();
  const { locationId } = useParams();
  if (!locationId) {
    return;
  }
  const locationIdNumber = Number.parseInt(locationId);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.INDIVIDUAL);

  const {
    data: assetData,
    isLoading: assetIsLoading,
    isError: assetIsError,
    fetchData: fetchAssets,
    setApiCallArg,
  } = useApiCall<LocationAssetModel[]>(() => locationService.getAssets({ locationId: locationIdNumber }));

  const onAssetsMoved = () => {
    fetchAssets();
  };

  useEffect(() => {
    if (displayMode === DisplayMode.HIERARCHICAL) {
      setApiCallArg(() => locationService.getAssetsHierarchical({ locationId: locationIdNumber }));
    } else {
      setApiCallArg(() => locationService.getAssets({ locationId: locationIdNumber }));
    }
  }, [displayMode]);

  return (
    <div className="flex h-full flex-1 flex-grow  max-w-[1920px] ">
      <div className="flex flex-col h-full w-full">
        <div className="text-md flex items-center justify-between  w-full font-bold">
          {assetData && (
            <div className="">
              {formatNumber(assetData?.length)}
              {assetData?.length === 1000 && '+'} Asset{assetData?.length > 1 && 's'} found{' '}
              {assetData.length >= 1000 && `(Showing first ${formatNumber(assetData?.length)})`}
            </div>
          )}
          <div className="text-xs flex items-center scale-75 origin-right">
            <LocationHierarchyToggleButton displayMode={displayMode} onChange={setDisplayMode} reverseOrder />
          </div>
        </div>

        <AssetsAtLocationGrid
          data={assetData}
          isError={assetIsError}
          isLoading={assetIsLoading}
          onAssetsMoved={onAssetsMoved}
        ></AssetsAtLocationGrid>
      </div>
    </div>
  );
};
