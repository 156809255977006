import FilterBar from '@/components/filterbar/FilterBar';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import AssetTypesFilter from '@/components/filterbar/filters/AssetTypesFilter';
import LabelValuesFilter from '@/components/filterbar/filters/LabelValuesFilter';
import { useApiCall } from '@/hooks/useApiCall';
import { useTranslation } from '@/lib';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { locationService } from '@/modules/locations/api/locations/location.service';
import ClusterMap, { MarkerProps } from '@/modules/map/components/ClusterMap';
import { Refresh } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';

export const AssetsMapPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data, fetchData, setApiCallArg } = useApiCall(() =>
    locationService.getAssetTotals({
      includeInTransitAssets: true,
      includeEmptyLocations: true,
      assetTypeIds: undefined,
    }),
  );
  const [locationMarkers, setLocationMarkers] = useState<MarkerProps[]>([]);

  useEffect(() => {
    if (data) {
      const markers = data
        .filter((d) => d.hasValidCoordinates)
        .map<MarkerProps>((d) => {
          return {
            lat: d.coordinates ? d.coordinates[1] : 0,
            lng: d.coordinates ? d.coordinates[0] : 0,
            id: d.id,
            title: d.name,
            count: d.totalBalance,
            inTransitAssetId: d.inTransitAssetId,
          };
        });
      setLocationMarkers(markers);
    }
  }, [data]);

  const onSearch = (filterValues: FilterValues) => {
    setApiCallArg(() =>
      locationService.getAssetTotals({
        includeInTransitAssets: true,
        includeEmptyLocations: true,
        assetTypeIds: filterValues[FilterFieldName.assetTypeIds] as number[],
        labelValueIds: filterValues[FilterFieldName.labelValueIds] as number[],
      }),
    );
  };

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          actions={
            <Button variant="outlined" startIcon={<Refresh></Refresh>} onClick={fetchData}>
              {t('refresh')}
            </Button>
          }
        >
          <TabbedLayoutTopBarHeader icon={null}>{t('map.title')}</TabbedLayoutTopBarHeader>
          <TabbedLayoutTopBarVerticalSeperator />

          <FilterBar showAsPopover onSearch={onSearch}>
            <div className="flex flex-col gap-y-2">
              <div className="w-72">
                <AssetTypesFilter label={'Asset Types'} />
              </div>
              <div className="w-72">
                <LabelValuesFilter filterFieldName={FilterFieldName.labelValueIds} />
              </div>
            </div>
            <FilterBarSearchButton />
          </FilterBar>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        <div className=" absolute inset-0 ">
          <ClusterMap markers={locationMarkers}></ClusterMap>
        </div>
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
