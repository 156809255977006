import { AccountTreeRounded } from '@mui/icons-material';
import { ImportType, ValidatedRow } from '../../api/importer.contracts';
import { ImportDefinition } from '../import-definition';
import { lime } from '@mui/material/colors';
import { AxiosProgressEvent } from 'axios';
import { importerService } from '../../api/importer.service';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import i18n from '@/lib/i18n/i18nConfig';
import { ApiResponse } from '@/lib/api/api-response';

export interface ParentLocationImportSummary {
  counts: ParentLocationImportCounts[];
  locations: ParentLocationDto[];
}

export interface ParentLocationImportCounts {
  parentLocationCode: string;
  childLocationCount: number;
}

export interface ParentLocationDto extends ValidatedRow {
  parentLocationCode: string;
  parentLocationId: number;
  childLocationCode: string;
  childLocationId: number;
}

export class ParentLocationImport implements ImportDefinition<ParentLocationImportSummary, ParentLocationDto> {
  title = i18n.t('importer.parent_location_import.title');
  description = i18n.t('importer.parent_location_import.description');
  icon = (<AccountTreeRounded sx={{ fontSize: 40 }} />);
  iconColor = lime[500];
  type = ImportType.PARENT_LOCATION;
  templateFileUrl = '/importers/parent-locations/parent_locations_import_template.csv';

  async preview(data: { file: File; onUploadProgress: (progressEvent: AxiosProgressEvent) => void }) {
    const response = await importerService.validateFile<ParentLocationImportSummary, ParentLocationDto>(
      this.type,
      data.file,
      data.onUploadProgress,
    );

    return response;
  }

  async import(payload: { summary: ParentLocationImportSummary }): Promise<ApiResponse<boolean>> {
    const response = await importerService.importLocationsParentLocations(payload.summary.locations);

    return response.processPayload((data) => response.isSuccess);
  }

  summaryColDefs: ColDefOrGroup<ParentLocationImportSummary>[] = [
    {
      field: 'locations',
      valueGetter: (params) => params.data?.locations.length,
      headerName: 'Number Locations to assign (new) Parent',
    },
  ];
  summaryMaxHeightInPx = 200;
  summaryMaxWidthInPx = 400;

  errorRowColDefs: ColDefOrGroup<ParentLocationDto>[] = [
    {
      headerName: 'Parent Location Code',
      field: 'parentLocationCode',
    },
    {
      headerName: 'Child Location Code',
      field: 'childLocationCode',
    },
    {
      field: 'isValid',
      headerName: 'Valid',
      cellDataType: 'text',
      valueFormatter: (params) => (params.value ? 'Valid' : 'Invalid'),
      cellClassRules: {
        'text-green-500': (params) => params.value,
        'text-red-500': (params) => !params.value,
      },
    },
    {
      headerName: 'Error Reason',
      field: 'errorReason',
      valueFormatter: (params) => {
        return i18n.t(`importer.import_error.${params.value}`);
      },
    },
  ];
}
