import { FunctionComponent } from 'react';
import { SuccessCircle } from '@/components/feedback/SuccessCircle';
import { ErrorCircle } from '@/components/feedback/ErrorCircle';
import { useWizardContext } from './ImportWizardContext';
import { Button, CircularProgress } from '@mui/material';

export const ImportWizardStepImport: FunctionComponent = () => {
  const {
    isUploadingFile,
    uploadProgress,
    previewResponse,
    importDefinition,
    moveForward,
    moveBackward,
    currentStepTitle,
    importResponse,
    isImporting,
    startImport,
  } = useWizardContext();
  return (
    <div className="flex flex-col">
      <div className="mb-4 text-center text-2xl font-semibold">{currentStepTitle}</div>
      {!isImporting && !importResponse && (
        <div className=" flex flex-col items-center justify-center">
          <div className="mb-8 max-w-lg text-center">
            You are now ready to import the data into Posito. Click the button below to start the import process.
          </div>
          <Button variant="contained" color="primary" onClick={startImport}>
            Start Import
          </Button>
        </div>
      )}
      {isImporting && !importResponse ? (
        <div className="flex flex-col items-center justify-center">
          <div className="relative flex">
            <CircularProgress variant="indeterminate" size={125} thickness={1.5} />
          </div>
          <div className="mt-4 text-lg font-semibold">Importing data...</div>
        </div>
      ) : importResponse?.isSuccess ? (
        <div className="flex flex-col items-center justify-center">
          <SuccessCircle />
          <div className="mt-4 text-lg font-semibold">Import successful</div>
        </div>
      ) : (
        importResponse &&
        !importResponse.isSuccess && (
          <div className="flex flex-col items-center justify-center">
            <ErrorCircle />
            <div className="mt-4 text-lg font-semibold">Import failed</div>
            <div className="mt-4 text-lg font-semibold">{importResponse?.message}</div>
          </div>
        )
      )}
    </div>
  );
};
