import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { AssetCreateFormSchema, AssetFormModel, AssetFormSchema } from './AssetFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import AssetFormDisplay from './AssetFormDisplay';

interface AssetFormLogicProps {
  initialValues: AssetFormModel;
  onSubmit: (data: AssetFormModel) => Promise<boolean>;
  isCreate?: boolean;
  assetId?: number;
}

const AssetFormLogic: FunctionComponent<AssetFormLogicProps> = ({ initialValues, onSubmit, isCreate, assetId }) => {
  const form = useForm<AssetFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? AssetCreateFormSchema : AssetFormSchema),
  });

  const handleSubmit = async (data: AssetFormModel) => {
    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return <AssetFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} assetId={assetId} />;
};

export default AssetFormLogic;
