import { ActionBar } from '@/modules/application';
import { Card, CardContent, Typography, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { UserFormModel } from './UserFormModel';
import { LoadingButton } from '@mui/lab';
import { ActionBarDeleteButton } from '@/modules/application/components/ActionBarDeleteButton';
import { userService } from '@/modules/users/api/users/users.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import RoleSelect from '@/modules/users/submodules/roles/components/RoleSelect';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useUser } from '@/modules/users/contexts/UserContext';

interface UserFormDisplayProps {
  form: UseFormReturn<UserFormModel>;
  onSubmit: (data: UserFormModel) => void;
  isCreate?: boolean;
  userId?: number;
}

const UserFormDisplay: FunctionComponent<UserFormDisplayProps> = ({ form, onSubmit, isCreate = false, userId }) => {
  const { formState, register, handleSubmit, getValues, control, watch } = form;
  const { errors, isSubmitting } = formState;
  const navigate = useNavigate();
  const hasUserEditPermission = useUser().hasPermission(Permission.USER_EDIT);

  const deleteUser = async () => {
    if (userId) {
      const response = await userService.deleteUser(userId);
      if (!response.isSuccess) {
        toast.error('Error deleting User');
      } else {
        toast.success('User deleted');
        navigate('/app/configuration/users');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3 ">
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              Details
            </Typography>
            <div className="flex w-full flex-col gap-y-2">
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <TextField label="Name" {...field} />}
              />
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField error={errors.email !== undefined} helperText={errors.email?.message} label="Email" {...field} />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    error={errors.password !== undefined}
                    helperText={errors.password?.message ?? 'Enter a Password to change it, leave blank for no change.'}
                    label="Password"
                    {...field}
                  />
                )}
              />

              {/* Add Role select */}
              <Controller
                name="roleIds"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <RoleSelect
                    selectedRoleId={field.value[0]}
                    onChange={(selectedRole) => field.onChange([selectedRole?.dto.id])}
                    helperText={errors.roleIds?.message}
                  />
                )}
              />
            </div>
          </CardContent>
        </Card>
      </div>

      <ActionBar visible={hasUserEditPermission}>
        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
          Save
        </LoadingButton>
        <ActionBarDeleteButton
          deleteConfirmationQuestion="Are you sure you would like to delete this User?"
          onDeleteConfirmed={deleteUser}
          isVisible={!isCreate}
          isLoading={isSubmitting}
        />
      </ActionBar>
    </form>
  );
};

export default UserFormDisplay;
