import React from 'react';
import usePagination from '@mui/material/usePagination';
import { Skeleton } from '@mui/material';

interface PaginationControlsProps {
  totalPageCount: number;
  currentPage: number;
  totalElements?: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  isLoading?: boolean;
}

export default function PaginationControls({ totalPageCount, currentPage, onChange, totalElements, isLoading }: PaginationControlsProps) {
  const { items } = usePagination({
    count: totalPageCount,
    page: currentPage,
    onChange,
    disabled: false,
    boundaryCount: 1,
    siblingCount: 2,
  });

  if (isLoading) {
    return (
      <div className="flex h-9">
        <Skeleton height={35} width={400}></Skeleton>
      </div>
    );
  }

  return (
    <div className="flex gap-x-2 py-1.5 ">
      {items.map(({ page, type, selected, disabled, ...item }, index) => {
        let children = null;

        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = '…';
        } else if (type === 'page') {
          children = (
            <button
              type="button"
              className={`rounded p-1 min-w-7 ${
                selected ? 'bg-gray-600 text-white' : ' bg-gray-300 dark:bg-gray-400 hover:bg-gray-400 cursor-pointer'
              }`}
              {...item}
            >
              {page}
            </button>
          );
        } else {
          children = (
            <button
              type="button"
              className={`rounded  px-2 
                ${
                  disabled
                    ? 'bg-gray-400 bg-opacity-50 text-gray-900 opacity-40 cursor-not-allowed '
                    : ' bg-gray-300 dark:bg-gray-400 hover:bg-gray-400 cursor-pointer'
                }
            `}
              {...item}
              disabled={disabled}
            >
              {type}
            </button>
          );
        }

        return (
          <div className="flex" key={index}>
            {children}
          </div>
        );
      })}
    </div>
  );
}
