import { InfinitySpinner, ColorModeContext } from '@/modules/application';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { AuthUserContext, useFirebaseAuth } from '@/modules/auth';
import { useContext, useEffect, useState } from 'react';
import { Environment } from '@/config/Environment';
import { useLocation, useNavigate, Outlet, useSearchParams } from 'react-router-dom';

import { useTheming } from './config/theme/useTheming';

// Toasts
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SettingsProvider } from './modules/settings/contexts/settings.context';
import { settingService } from './modules/settings/api/settings/setting.service';
import { SettingModel } from './modules/settings/types/SettingModel';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { userSettingService } from './modules/users/api/user-settings/user-setting.service';
import { UserProvider } from './modules/users/contexts/UserContext';
import { UserSettingsProvider } from './modules/users/contexts/UserSettingsContext';
import { UserModel } from './modules/users/types/UserModel';
import { UserSettingModel } from './modules/users/types/UserSettingModel';
import { userService } from './modules/users/api/users/users.service';
import { UserSettingKey } from './modules/users/api/user-settings/user-setting.contracts';
import { useScreenSize } from './hooks/useScreenSize';
import { CacheProvider } from './providers/cache-provider/CacheProvider';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [initialSettings, setInitialSettings] = useState<SettingModel[]>([]);
  const [initialUserSettings, setInitialUserSettings] = useState<UserSettingModel[]>([]);
  const [initialUser, setInitalUser] = useState<UserModel>();
  const { colorMode, mode, theme, setMode } = useTheming();
  const { toggleColorMode } = useContext(ColorModeContext);

  const { isBelowMD } = useScreenSize();
  const auth = useFirebaseAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (Environment.isDevelopment()) {
    // console.log(`Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`);
  }

  useEffect(() => {
    // Don't log in automatically if there is no connection
    if (searchParams.get('noConnection')) {
      setIsLoading(false);
      return;
    }

    const handleLogin = async () => {
      setIsLoading(true);
      try {
        // Try to login with stored token
        const autoLoggedInUser = await auth.autoLogin();

        if (autoLoggedInUser) {
          try {
            // Load Application Settings
            const settings = await settingService.getAll();
            if (settings.isSuccess) {
              setInitialSettings(settings.payload);
            } else {
              navigate('/login');
              toast.error('Error loading settings');
              return;
            }

            // Load user
            const getPositoUser = await userService.getMe(autoLoggedInUser.uid);
            if (getPositoUser.isSuccess) {
              setInitalUser(getPositoUser.payload);
            } else {
              navigate('/login');
              toast.error('Error loading user');
              return;
            }

            // Load User Settings
            const userSettings = await userSettingService.getAll(getPositoUser.payload.id);
            if (userSettings.isSuccess) {
              setInitialUserSettings(userSettings.payload);

              // Set application specific values

              const isDarkModeEnabledSetting = userSettings.payload.find((setting) => setting.key === UserSettingKey.DARK_MODE_ENABLED);

              if (isDarkModeEnabledSetting?.value === true && mode === 'light') {
                toggleColorMode();
              } else if (isDarkModeEnabledSetting?.value === false && mode === 'dark') {
                toggleColorMode();
              }

              setMode(isDarkModeEnabledSetting?.value === true ? 'dark' : 'light');
            } else {
              navigate('/login');
              toast.error('Error loading user settings');
              return;
            }

            if (isBelowMD) {
              navigate('/mobile');
            } else {
              navigate(location.pathname.startsWith('/app') ? location : '/app');
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    handleLogin();
  }, []);

  return (
    <div className={`${mode} flex h-full flex-grow overflow-hidden text-sm`}>
      <CacheProvider>
        <StyledEngineProvider injectFirst>
          <ColorModeContext.Provider value={{ toggleColorMode: colorMode.toggleColorMode, mode, isDarkMode: mode === 'dark' }}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {/* {isLoading || (auth.authUser && !location.pathname.startsWith('/app')) ? ( */}
              {isLoading ? (
                <div className="flex h-screen w-full items-center justify-center">
                  <InfinitySpinner></InfinitySpinner>
                </div>
              ) : (
                <SettingsProvider initialSettings={initialSettings}>
                  <UserProvider initialUser={initialUser}>
                    <UserSettingsProvider initialSettings={initialUserSettings}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <AuthUserContext.Provider value={auth}>
                          <Outlet />
                        </AuthUserContext.Provider>
                      </LocalizationProvider>
                      <ToastContainer theme={mode === 'dark' ? 'dark' : 'light'} position={'top-center'} />
                    </UserSettingsProvider>
                  </UserProvider>
                </SettingsProvider>
              )}
            </ThemeProvider>
          </ColorModeContext.Provider>
        </StyledEngineProvider>
      </CacheProvider>
    </div>
  );
}

export default App;
