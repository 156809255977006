import { useFormatting } from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { FunctionComponent } from 'react';

interface ResultDataTextProps {
  data: unknown[];
  name: string;
  maxLength: number;
}

const ResultDataText: FunctionComponent<ResultDataTextProps> = ({ data, name, maxLength = 1000 }) => {
  const { formatNumber } = useFormatting();
  const { t } = useTranslation();

  return (
    <div className="h-full self-end pb-2 pl-3">
      {formatNumber(data?.length)}
      {data?.length === maxLength && '+'} {name} found {data.length >= maxLength && `(${t('showing_first', { amount: data?.length ?? 0 })})`}
    </div>
  );
};

export default ResultDataText;
