import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useEffect, useState } from 'react';
import { eventService } from '@/modules/events/api/events/event.service';
import { EventModel } from '@/modules/events/types/EventModel';

import { TimelineItem } from '@/components/timeline/TimelineItem';
import { TimelineItemContentRight } from '@/components/timeline/TimelineItemContentRight';
import { TimelineItemContentLeft } from '@/components/timeline/TimelineItemContentLeft';
import TimelineItemCenterPathAndIcon from '@/components/timeline/TimelineItemCenterPathAndIcon';
import { Timeline } from '@/components/timeline/Timeline';
import { LoginRounded, LogoutRounded, PlaceRounded } from '@mui/icons-material';
import { useDate } from '@/hooks/useDate';
import { EventHistoryMap } from '@/modules/map/components/EventHistoryMap';
import { Link, Skeleton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { assetService } from '../../api/assets/asset.service';
import { EventMapBreadcrumbDTO } from '../../api/assets/asset.contracts';
import FilterBar from '@/components/filterbar/FilterBar';
import DateFilter from '@/components/filterbar/filters/DateFilter';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import dayjs from 'dayjs';

interface AssetHistoryTabProps {
  assetId: number;
}

interface TimelineItem {
  locationId: number;
  locationName: string;
  firstEventDate: Date;
  lastEventDate: Date;
}

export const AssetHistoryTab: FunctionComponent<AssetHistoryTabProps> = ({ assetId }) => {
  const initialFilterValues: FilterValues = {
    dateFrom: dayjs().subtract(1, 'month'),
    dateTo: dayjs(),
  };

  const { data, isLoading, isError, fetchData, setApiCallArg } = useApiCall<EventModel[]>(() =>
    eventService.get({
      assetId,
      dateFrom: initialFilterValues[FilterFieldName.dateFrom],
      dateTo: initialFilterValues[FilterFieldName.dateTo],
    }),
  );
  const {
    data: breadcrumbData,
    isLoading: isLoadingBreadcrumbData,
    isError: isBreadCrumbDataError,
    fetchData: fetchBreadcrumbData,
    setApiCallArg: setBreadcrumbApiCallArg,
  } = useApiCall<EventMapBreadcrumbDTO[]>(() =>
    assetService.getBreadcrumbs({
      assetId,
      dateFrom: initialFilterValues[FilterFieldName.dateFrom],
      dateTo: initialFilterValues[FilterFieldName.dateTo],
    }),
  );

  const [selectedTimelineItem, setSelectedTimelineItem] = useState<TimelineItem | undefined>(undefined);

  const [timeline, setTimeline] = useState<TimelineItem[]>([]);
  const { formatDateTimestampShort, formatDuration } = useDate();

  const createTimeline = (events: EventModel[]): TimelineItem[] => {
    const timeline: TimelineItem[] = [];
    if (!events || events.length === 0) {
      return timeline;
    }

    events.sort((a, b) => new Date(a.occurredAt).getTime() - new Date(b.occurredAt).getTime());

    let currentLocationId = events[0].location.id;
    let firstEventDate = new Date(events[0].occurredAt);
    let lastEventDate = new Date(events[0].occurredAt);

    for (let i = 1; i < events.length; i++) {
      const event = events[i];

      if (event.location.id !== currentLocationId) {
        timeline.push({
          locationId: currentLocationId,
          locationName: events[i - 1].location.name,
          firstEventDate,
          lastEventDate,
        });

        currentLocationId = event.location.id;
        firstEventDate = new Date(event.occurredAt);
      }

      lastEventDate = new Date(event.occurredAt);
    }

    timeline.push({
      locationId: currentLocationId,
      locationName: events[events.length - 1].location.name,
      firstEventDate,
      lastEventDate,
    });

    return timeline.reverse();
  };

  useEffect(() => {
    if (data) {
      setTimeline(createTimeline(data));
    }
  }, [data]);

  // function onTimeLineItemClicked(event: TimelineItem): void {
  //   setSelectedTimelineItem(event);
  // }

  function onFilterChange(filterValues: FilterValues) {
    console.log(JSON.stringify(filterValues));
    setApiCallArg(() =>
      eventService.get({
        assetId: assetId,
        dateFrom: filterValues.dateFrom,
        dateTo: filterValues.dateTo,
      }),
    );

    setBreadcrumbApiCallArg(() =>
      assetService.getBreadcrumbs({
        assetId: assetId,
        dateFrom: filterValues.dateFrom,
        dateTo: filterValues.dateTo,
      }),
    );
  }

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      <div className=" flex flex-1 h-full ">
        <div className="flex w-96 grow flex-col mr-4 ">
          <div className=" pb-2 border-b mb-2 dark:border-b-gray-500">
            <FilterBar onChange={onFilterChange} initialFilterValues={initialFilterValues}>
              <div className="w-44">
                <DateFilter label="Date From" filterField={FilterFieldName.dateFrom} />
              </div>
              <div className="w-44">
                <DateFilter label="Date To" filterField={FilterFieldName.dateTo} />
              </div>
            </FilterBar>
          </div>
          <div className="overflow-y-auto">
            {isLoading ? (
              <div className="flex flex-col gap-y-2">
                {Array(12)
                  .fill(1)
                  .map((val, i) => (
                    <Skeleton variant="rounded" height={80} key={i} />
                  ))}
              </div>
            ) : !timeline || timeline.length == 0 ? (
              <div className="flex justify-center mt-4">No events found.</div>
            ) : (
              // </div>
              <Timeline>
                {timeline?.map((event, i, arr) => (
                  <TimelineItem key={i} isFirst={i === 0} isLast={i === arr.length - 1}>
                    <TimelineItemContentLeft>
                      <div className="flex items-center text-sm font-bold ">
                        {formatDateTimestampShort(event.lastEventDate)}
                        {i > 0 ? (
                          <LogoutRounded fontSize="inherit" className="ml-1" color="error" />
                        ) : (
                          <PlaceRounded fontSize="inherit" className="ml-1" color="secondary" />
                        )}
                      </div>
                      <div className="flex items-center text-xs">
                        {formatDateTimestampShort(event.firstEventDate)}
                        <LoginRounded fontSize="inherit" className="ml-1 mr-0.5" color="success" />
                      </div>
                    </TimelineItemContentLeft>
                    <TimelineItemCenterPathAndIcon
                      icon={<PlaceRounded fontSize="small" />}
                      // onClick={() => onTimeLineItemClicked(event)}
                    ></TimelineItemCenterPathAndIcon>
                    <TimelineItemContentRight>
                      <div className="flex flex-col">
                        <Link
                          to={{ pathname: `/app/locations/${event.locationId}` }}
                          target="_blank"
                          component={RouterLink}
                          underline="hover"
                          color={'inherit'}
                          className="text-sm font-bold"
                        >
                          {event.locationName}
                        </Link>
                        <div className="text-xs">
                          For{' '}
                          {i === 0
                            ? formatDuration(event.firstEventDate, new Date())
                            : // compare with previous event last date
                              formatDuration(event.firstEventDate, event.lastEventDate)}
                        </div>
                      </div>
                    </TimelineItemContentRight>
                  </TimelineItem>
                ))}
              </Timeline>
            )}
          </div>
        </div>
        <div className="00 h-full w-full ">
          <EventHistoryMap
            markers={
              breadcrumbData?.map((ev) => {
                return {
                  lat: ev.gpsLat,
                  lng: ev.gpsLong,
                  eventId: ev.eventId,
                };
              }) ?? []
            }
          />
        </div>
      </div>
    </div>
  );
};
