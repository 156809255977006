import { useAuthUserContext } from '@/modules/auth';
import LogoutRounded from '@mui/icons-material/LogoutRounded';
import AccountCircleRounded from '@mui/icons-material/AccountCircleRounded';

import { Avatar, Badge, Card, CardContent, CardHeader, Divider, Popover, Switch } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ColorModeContext } from '../context/ColorModeContext';
import TextLink from '@/components/TextLink';
import { MainMenuButton } from './MainMenuButton';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { useUserSettings } from '@/modules/users/contexts/UserSettingsContext';
import { Environment } from '@/config/Environment';

export const LogOutButton: FunctionComponent = () => {
  const { mode, toggleColorMode } = React.useContext(ColorModeContext);
  const { setUserSettingByKey } = useUserSettings();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { logOut, authUser } = useAuthUserContext();
  const [activePathnames, setActivePathnames] = useState(['/app/profile']);
  const [isRouteActive, setIsRouteActive] = useState(false);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  useEffect(() => {
    if (currentLocation.pathname) {
      setIsRouteActive(activePathnames.some((r) => currentLocation.pathname.startsWith(r)));
    }
  }, [activePathnames, currentLocation.pathname]);

  function onToggleDarkModeClicked() {
    setUserSettingByKey(UserSettingKey.DARK_MODE_ENABLED, mode === 'dark' ? false : true, false);
    toggleColorMode();
  }

  return (
    <>
      <MainMenuButton
        isActive={isRouteActive}
        label={authUser?.displayName ?? authUser?.email ?? 'Profile'}
        icon={<AccountCircleRounded fontSize="inherit" />}
        onClick={handleClick}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Card className={'w-72'}>
          <CardHeader
            avatar={
              <Badge color="success" overlap="circular" variant="dot">
                {' '}
                <Avatar sx={{ bgcolor: deepOrange[700] }} aria-label="recipe">
                  {authUser?.displayName?.charAt(0).toUpperCase() ?? authUser?.email?.charAt(0).toUpperCase() ?? 'U'}
                </Avatar>
              </Badge>
            }
            title={authUser?.displayName}
            subheader={authUser?.email}
          />
          <CardContent sx={{ paddingTop: 0, paddingBottom: 12 }}>
            <div className="flex flex-col text-sm">
              <div className="flex w-full items-center justify-between ">
                <TextLink className={'py-2'} onClick={toggleColorMode}>
                  Dark Mode
                </TextLink>
                <Switch
                  className={'py-2'}
                  edge="end"
                  onChange={onToggleDarkModeClicked}
                  checked={mode === 'dark'}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-wifi',
                  }}
                />
              </div>
              {/* <TextLink className={'py-2'} to={'configuration/profile'}>
                My Settings
              </TextLink> */}
              <Divider flexItem className="my-2" />
              <TextLink
                className={'py-2'}
                onClick={logOut}
                leftIcon={<LogoutRounded className="text-red-500" fontSize="inherit"></LogoutRounded>}
              >
                Log out
              </TextLink>
            </div>
          </CardContent>
          <div className="flex w-full bg-gray-200 px-3 py-2 text-xs text-gray-500 dark:bg-gray-700 dark:text-gray-400 ">
            <div>
              <span className="font-bold pr-1">Environment:</span>
              <span className="first-letter:uppercase">{Environment.isPositoDevelopment() ? 'Development' : 'Production'}</span>
            </div>
          </div>
        </Card>
      </Popover>
    </>
  );
};
