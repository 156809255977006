import React, { useState, useEffect, useRef } from 'react';
import { v4 } from 'uuid';
import LocationInfoDrawer from '@/modules/locations/components/location-drawer/LocationInfoDrawer';
import { useFormatting } from '@/hooks/useFormatting';

// Leaflet related imports
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Leaflet Marker Cluster related imports
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { isEqual, isNil } from 'lodash-es';
import useResizeObserver from '@/hooks/useResizeObserver';
import AssetInfoDrawer from '@/modules/assets/components/asset-info-drawer/AssetInfoDrawer';
import { useTranslation } from '@/lib';

export interface MarkerProps {
  id?: number;
  title?: string;
  lat: number;
  lng: number;
  count: number;
  inTransitAssetId?: number;
}

interface CustomMarkerProps extends MarkerProps {
  customProperties: {
    id: number | undefined;
    title: string | undefined;
    count: number;
    inTransitAssetId?: number;
  };
}

interface MapProps {
  markers: MarkerProps[];
}

type ClusterMarker<T> = L.Marker & T;

const Map: React.FC<MapProps> = ({ markers }) => {
  const [map, setMap] = useState<L.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapWrapperContainerRef = useResizeObserver(100, () => {
    if (map) {
      map.invalidateSize();
    }
  });
  const [mapContainerId] = useState(v4());
  const { formatNumberToDisplaySize } = useFormatting();
  const { t } = useTranslation();

  const [markersState, setMarkersState] = useState<MarkerProps[]>(markers);

  const [isLocationInfoDrawerOpen, setIsLocationInfoDrawerOpen] = useState(false);
  const [locationDrawerLocationId, setLocationDrawerLocationId] = useState<number>();

  const [isAssetInfoDrawerOpen, setIsAssetInfoDrawerOpen] = useState(false);
  const [assetDrawerLatitude, setAssetDrawerLatitude] = useState<number>();
  const [assetDrawerLongitude, setAssetDrawerLongitude] = useState<number>();
  const [assetDrawerAssetId, setAssetDrawerAssetId] = useState<number>();

  useEffect(() => {
    if (!map && mapContainerRef.current) {
      if (!(mapContainerRef.current as HTMLDivElement & { _leaflet_id: string })._leaflet_id) {
        // This line checks if the map was already initialized on the container
        const initialMap = L.map(mapContainerRef.current, {
          center: [52.0167, 4.5833],
          zoom: 5,
          attributionControl: true,
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(initialMap);

        // Add legend
        /*Legend specific*/
        const legend = L.control.attribution({ position: 'bottomleft' });

        legend.onAdd = function (map) {
          const div = L.DomUtil.create('div', 'bg-white text-black p-2 shadow-md rounded-md flex flex-col w-28');
          div.innerHTML += `<div style="font-weight: 600;">${t('map.legend')}</div>`;
          // div.innerHTML += '<div className="bg-blue-700 w-4 h-4 rounded-full flex"><span>Water</span></div>';
          div.innerHTML += `<div style=" display:flex; align-items: center;"><div style="background: rgb(234 88 12);width: 1rem; border-radius:5px; margin-right: 0.25rem;  height: 0.5rem;"></div><span>${t(
            'map.in_transit',
          )}</span></div>`;
          div.innerHTML += `<div style=" display:flex; align-items: center;"><div style="width: 1rem; border-radius:5px; margin-right: 0.25rem;  height: 0.5rem; background:rgb(25 118 210);"></div><span>${t(
            'map.on_location',
          )}</span></div>`;

          return div;
        };

        legend.addTo(initialMap);

        setMap(initialMap);
      }
    }
  }, [map]);

  function createFeatureCollectionFromMarkers(markers: MarkerProps[]) {
    return markers.map<L.Marker>((marker) => {
      const isAssetMarker = !isNil(marker.inTransitAssetId);

      const latLng = new L.LatLng(marker.lat, marker.lng);
      const icon = L.divIcon({
        className: `!h-7 !w-7 !-ml-3.5 !-mt-3.5 ${
          isAssetMarker ? 'bg-orange-600' : 'bg-blue-700'
        } text-white rounded-full  font-bold !text-xs flex justify-center items-center `,
        html: `<div class="marker-count">${formatNumberToDisplaySize(marker.count)}</div>`,
      });

      const markerInstance = L.marker(latLng, { icon }) as ClusterMarker<CustomMarkerProps>;
      markerInstance.customProperties = {
        id: marker.id,
        title: marker.title,
        count: marker.count,
        inTransitAssetId: marker.inTransitAssetId,
      };

      markerInstance.on('click', () => {
        if (!isAssetMarker) {
          setIsAssetInfoDrawerOpen(false);
          setLocationDrawerLocationId(marker.id);
          setIsLocationInfoDrawerOpen(true);
        } else {
          setIsLocationInfoDrawerOpen(false);
          setAssetDrawerAssetId(marker.inTransitAssetId);
          setAssetDrawerLatitude(marker.lat);
          setAssetDrawerLongitude(marker.lng);
          setIsAssetInfoDrawerOpen(true);
        }
      });
      return markerInstance;
    });
  }

  useEffect(() => {
    if (map) {
      // If markers are the same as ones in state, just return
      if (isEqual(markersState, markers)) {
        return;
      } else {
        setMarkersState(markers);
      }
      // Remove any existing markers and sources from the map
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker || layer instanceof L.LayerGroup || layer instanceof L.MarkerClusterGroup) {
          map.removeLayer(layer);
        }
      });

      // Create a feature collection from the markers
      const markerFeatures = createFeatureCollectionFromMarkers(markers);

      const lineCoordinates: L.LatLngExpression[] = [];

      markers.forEach((marker, index) => {
        const latLng = new L.LatLng(marker.lat, marker.lng);
        lineCoordinates.push(latLng);
      });

      // Add the markers to the map
      const clusters = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          const markers = cluster
            .getAllChildMarkers()
            .map<ClusterMarker<CustomMarkerProps>>((marker) => marker as ClusterMarker<CustomMarkerProps>);
          let assetCount = 0;
          for (let i = 0; i < markers.length; i++) {
            assetCount += markers[i].customProperties.count;
          }

          let c = ' marker-cluster-';
          if (assetCount < 10) {
            c += 'small';
          } else if (assetCount < 100) {
            c += 'medium';
          } else {
            c += 'large';
          }

          return new L.DivIcon({
            html: '<div><span>' + formatNumberToDisplaySize(assetCount) + '</span></div>',
            className: 'marker-cluster' + c,
            iconSize: new L.Point(40, 40),
          });
        },
      });
      const markerLayer = L.layerGroup(markerFeatures);

      clusters.addLayer(markerLayer);
      map.addLayer(clusters);

      // Fit the map to the bounds of the markers
      // Get the bounds of the market instances
      if (lineCoordinates.length > 0) {
        const bounds = L.latLngBounds(lineCoordinates);
        map.fitBounds(bounds, {
          padding: [25, 25],
        });
      }
    }
  }, [map, markers]);

  return (
    <div ref={mapWrapperContainerRef} className="w-full h-full">
      <div ref={mapContainerRef} id={mapContainerId} style={{ width: '100%', height: '100%' }} />
      {locationDrawerLocationId !== undefined && (
        <LocationInfoDrawer
          locationId={locationDrawerLocationId}
          isOpen={isLocationInfoDrawerOpen}
          onClose={() => setIsLocationInfoDrawerOpen(false)}
        />
      )}

      {assetDrawerAssetId !== undefined && assetDrawerLatitude && assetDrawerLongitude && (
        <AssetInfoDrawer
          assetId={assetDrawerAssetId}
          isOpen={isAssetInfoDrawerOpen}
          onClose={() => setIsAssetInfoDrawerOpen(false)}
          latitude={assetDrawerLatitude}
          longitude={assetDrawerLongitude}
        />
      )}
    </div>
  );
};

export default Map;
