import { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { FormControl, InputLabel, Select, MenuItem, FormHelperText, SelectChangeEvent } from '@mui/material';
import { RoleModel } from '@/modules/users/submodules/roles/types/RoleModel';
import { roleService } from '../api/roles.service';

interface RoleSelectProps {
  selectedRoleId?: number;
  onChange: (selectedRole?: RoleModel) => void;
  helperText?: string;
}

const RoleSelect: FunctionComponent<RoleSelectProps> = ({ selectedRoleId, onChange, helperText }) => {
  const [roles, setRoles] = useState<RoleModel[]>([]);

  async function getAllRoles() {
    const roles = await roleService.getAllRoles();
    if (roles.isSuccess) {
      setRoles(roles.payload);
    }
  }
  useEffect(() => {
    getAllRoles();
  }, []);

  function onRoleChanged(event: SelectChangeEvent<number>, child: ReactNode) {
    const selectedRole = roles.find((at) => at.dto.id === event.target.value);

    onChange(selectedRole);
  }
  return (
    <FormControl className="w-full">
      <InputLabel>Role</InputLabel>
      <Select value={selectedRoleId && roles.length > 0 ? selectedRoleId : ''} label={'Role'} onChange={onRoleChanged}>
        {roles.map((role) => {
          return (
            <MenuItem key={role.dto.id} value={role.dto.id}>
              {role.nameInTitleCase}
            </MenuItem>
          );
        })}
      </Select>
      {helperText && <FormHelperText>{helperText} </FormHelperText>}
    </FormControl>
  );
};

export default RoleSelect;
