import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { ValueGetterParams } from 'ag-grid-community';
import { EventGroupAssetDto } from '@/modules/events/api/events/event.contracts';
import { useTranslation } from '@/lib';

interface EventGroupAssetsGridProps {
  data?: EventGroupAssetDto[];
}

export const EventGroupAssetsGrid: FunctionComponent<EventGroupAssetsGridProps> = ({ data }) => {
  const { t } = useTranslation();
  if (!data) {
    return <div>{t('noData')}</div>;
  }
  const navigate = useNavigate();

  // const customGridOptions: GridOptions<AssetModel> = {
  //   onRowDoubleClicked(asset: RowDoubleClickedEvent<AssetModel, any>) {
  //     navigate(`/app/assets/${asset.data?.id}`);
  //   },
  // };

  const columns: ColDefOrGroup<EventGroupAssetDto>[] = [
    {
      headerName: t('code'),
      valueGetter: (params: ValueGetterParams<EventGroupAssetDto>) => {
        return params.data?.code;
      },
    },
    {
      headerName: t('type'),
      valueGetter: (params: ValueGetterParams<EventGroupAssetDto>) => {
        return params.data?.assetType.name;
      },
    },
  ];

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      <PoGrid isLoading={false} colDefs={columns} rowData={data} />
    </div>
  );
};
