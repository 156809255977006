import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import LocationFormLogic from './LocationFormLogic';
import { LocationFormModel } from './LocationFormModel';
import { useNavigate, useParams } from 'react-router-dom';
import { isNumber } from 'lodash-es';
import { LocationModel } from '../../types/LocationModel';
import { locationService } from '../../api/locations/location.service';
import { ApiResponseCode } from '@/lib/api/api-response-code';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

const LocationFormApi: FunctionComponent = () => {
  const { locationId } = useParams();
  const locationIdNumber = locationId ? Number.parseInt(locationId) : 0;
  const isCreate = locationId === 'create';

  const navigate = useNavigate();
  const hasLocationEditPermission = useUser().hasPermission(Permission.LOCATIONS_EDIT);

  const handleSubmit = async (data: LocationFormModel) => {
    if (!hasLocationEditPermission) {
      toast.error('You do not have permission to edit locations');
      return false;
    }

    // Process data
    if (isCreate) {
      const response = await locationService.create({
        name: data.name,
        code: data.code,
        description: data.description,
        geofence: data.geofence,
        country: data.country,
        city: data.city,
        postalCode: data.postalCode,
        streetName: data.streetName,
        streetNumber: data.streetNumber,
        parentLocationId: data.parentLocationId,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast('The code is already in use, and must be unique. Please provide a different one.');
        } else {
          toast('Error Creating Location', {
            type: 'error',
          });
        }
      } else {
        toast.success('Location created');
        navigate('/app/locations/overview');
      }
    } else if (isNumber(locationIdNumber)) {
      const response = await locationService.update(locationIdNumber, {
        name: data.name,
        code: data.code,
        description: data.description,
        geofence: data.geofence,
        country: data.country,
        city: data.city,
        postalCode: data.postalCode,
        streetName: data.streetName,
        streetNumber: data.streetNumber,
        parentLocationId: data.parentLocationId,
      });

      if (!response.isSuccess) {
        if (response.responseCode === ApiResponseCode.DUPLICATE_ENTITY_CODE) {
          toast('The code is already in use, and must be unique. Please provide a different one.');
        } else {
          toast('Error Updating Location', {
            type: 'error',
          });
        }
      } else {
        toast.success('Location updated');
        navigate('/app/locations/overview/');
      }
    }
    return true;
  };

  if (isCreate) {
    const newLocation: LocationFormModel = {
      name: '',
      code: '',
      description: '',
      geofence: undefined,
      country: undefined,
      city: undefined,
      postalCode: undefined,
      streetNumber: undefined,
      streetName: undefined,
      parentLocationId: undefined,
      isSystem: false,
    };
    return <LocationFormLogic initialValues={newLocation} onSubmit={handleSubmit} isCreate />;
  } else {
    const { data, isLoading, isError, fetchData } = useApiCall<LocationModel>(() => locationService.getById(locationIdNumber));

    // return early if initial form data isn't loaded
    if (isLoading) return <LoadingBlock />;
    if (isError || !data) return <div>No Data Error</div>;

    return (
      <LocationFormLogic
        initialValues={{
          name: data.dto.name,
          code: data.dto.code,
          description: data.dto.description,
          city: data.dto.city,
          country: data.dto.country,
          geofence: data.dto.geofence,
          parentLocationId: data.parentLocationId,
          postalCode: data.dto.postalCode,
          streetName: data.dto.streetName,
          streetNumber: data.dto.streetNumber,
          isSystem: data.dto.isSystem,
        }}
        onSubmit={handleSubmit}
        isCreate={isCreate}
        locationId={locationIdNumber}
      />
    );
  }
};

export default LocationFormApi;
