import { z } from 'zod';

export const UserFormSchema = z.object({
  name: z.string(),
  email: z.string().min(1, { message: 'This field has to be filled.' }).email('This is not a valid email.'),
  roleIds: z.array(z.number()).refine((arr) => arr.length > 0, { message: 'You must select a role.' }),
  // Password is optional during edits
  password: z.string().optional(),
});

// Make password a required field during create
export const UserCreateFormSchema = UserFormSchema.extend({
  // Firebase requires at least 6 characters password
  password: z.string().min(6, {
    message: 'A password with at least 6 characters is required',
  }),
});

export type UserFormModel = z.infer<typeof UserFormSchema>;
