import { WidgetsRounded } from '@mui/icons-material';
import { ImportType, ImportError, ValidatedRow } from '../../api/importer.contracts';
import { ImportDefinition } from '../import-definition';
import { purple } from '@mui/material/colors';
import { AxiosProgressEvent } from 'axios';
import { importerService } from '../../api/importer.service';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import i18n from '@/lib/i18n/i18nConfig';
import { ApiResponse } from '@/lib/api/api-response';

export interface AssetImportSummary {
  trackersToCreate: number;
  assetsToCreate: number;
  assetsWithTrackers: AssetTrackerDto[];
}

export interface AssetTrackerDto {
  isValid: boolean;
  errorReason?: ImportError;
  assetCode: string;
  assetTypeId: number;
  trackerBarcode?: string;
  trackerEPC?: string;
  trackerUnatagDeviceId?: string;
}

export interface AssetImportRow extends ValidatedRow {
  assetCode: string;
  assetTypeId?: number;
  trackerBarcode?: string;
  trackerEPC?: string;
  trackerUnatagDeviceId?: string;
}

export class AssetImport implements ImportDefinition<AssetImportSummary, AssetImportRow> {
  title = i18n.t('importer.asset_import.title');
  description = i18n.t('importer.asset_import.description');
  icon = (<WidgetsRounded sx={{ fontSize: 40 }} />);
  iconColor = purple[500];
  type = ImportType.ASSET;
  templateFileUrl = '/importers/assets/asset_import_template.csv';

  async preview(data: { file: File; onUploadProgress: (progressEvent: AxiosProgressEvent) => void }) {
    const response = await importerService.validateFile<AssetImportSummary, AssetImportRow>(this.type, data.file, data.onUploadProgress);

    return response;
  }

  async import(payload: { summary: AssetImportSummary }): Promise<ApiResponse<boolean>> {
    const response = await importerService.importAssets(payload.summary.assetsWithTrackers);

    return response.processPayload((data) => response.isSuccess);
  }

  summaryColDefs: ColDefOrGroup<AssetImportSummary>[] = [
    {
      field: 'assetsToCreate',
      headerName: 'Assets to Create',
    },
    {
      field: 'trackersToCreate',
      headerName: 'Trackers to Create',
    },
  ];
  summaryMaxHeightInPx = 200;
  summaryMaxWidthInPx = 400;

  errorRowColDefs: ColDefOrGroup<AssetImportRow>[] = [
    {
      headerName: 'Asset Code',
      field: 'assetCode',
    },
    {
      field: 'isValid',
      headerName: 'Valid',
      cellDataType: 'text',
      valueFormatter: (params) => (params.value ? 'Valid' : 'Invalid'),
      cellClassRules: {
        'text-green-500': (params) => params.value,
        'text-red-500': (params) => !params.value,
      },
    },
    {
      headerName: 'Error Reason',
      field: 'errorReason',
      valueFormatter: (params) => {
        return i18n.t(`importer.import_error.${params.value}`);
      },
    },
  ];
}
