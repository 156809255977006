import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Chip, Skeleton } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { FunctionComponent } from 'react';
import { UserModel } from '../types/UserModel';
import { Link } from 'react-router-dom';
import { Label } from '@/components/Label';

interface UserListProps {
  users: UserModel[] | undefined;
  isLoading: boolean;
  isReadOnly?: boolean;
}

export const UserList: FunctionComponent<UserListProps> = ({ users = [], isLoading, isReadOnly = false }) => {
  return (
    <div className="grid w-full grid-cols-1 gap-x-4 gap-y-3  md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4">
      {isLoading || users === undefined ? (
        <>
          {Array(15)
            .fill(1)
            .map((val, i) => (
              <Skeleton key={i} variant="rounded" width={'full'} height={120} />
            ))}
        </>
      ) : (
        users.map((u, i) => {
          return (
            <div className="w-full" key={u.id}>
              <Card variant="outlined" title={u.name}>
                <Link to={{ pathname: u.id.toString() }} className="text-inherit no-underline">
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: blueGrey[500] }} aria-label="recipe">
                        {u.name[0]}
                      </Avatar>
                    }
                    title={u.name}
                    subheader={u.email}
                    action={
                      <Label variant="soft" color="success">
                        Active
                      </Label>
                    }
                  />
                </Link>
                <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Chip color="secondary" variant="outlined" size="small" label={u.roles[0].description} />
                  {/* <div className="flex flex-wrap gap-2">
                    {u.roles.map((role) => {
                      return (
                        <Chip
                          key={role.id}
                          color="secondary"
                          variant="outlined"
                          label={role.nameInTitleCase}
                          className={'cursor-pointer'}
                          onDelete={console.log}
                        />
                      );
                    })}
                  </div> */}
                </CardContent>
                <CardActions>
                  {!isReadOnly && (
                    <Button variant="text" component={Link} to={{ pathname: u.id.toString() }}>
                      Edit
                    </Button>
                  )}
                  {/* <Button color="secondary">Delete</Button> */}
                </CardActions>
              </Card>
            </div>
          );
        })
      )}
    </div>
  );
};
