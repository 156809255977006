import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { PoGrid } from '@/components/grid/PoGrid';
import { AssetTypeModel } from '../../types/AssetTypeModel';
import { generateAssetTypeColumns } from '../../components/asset-type-grid.columns';
import { assetTypeService } from '../../api/asset-types/asset-type.service';
import { useApiCall } from '@/hooks/useApiCall';
import { GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { AddOutlined } from '@mui/icons-material';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

export const AssetTypesPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useApiCall<AssetTypeModel[]>(() => assetTypeService.getAll());
  const { t } = useTranslation();

  const customGridOptions: GridOptions<AssetTypeModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<AssetTypeModel, unknown>) {
      navigate(`./${event.data?.id}`);
    },
  };
  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          actions={
            <RequirePermissionComponent permission={Permission.ASSET_TYPES_EDIT}>
              <Button startIcon={<AddOutlined></AddOutlined>} onClick={() => navigate('./create')}>
                {t('assetTypes.add')}
              </Button>
            </RequirePermissionComponent>
          }
        >
          <TabbedLayoutTopBarHeader icon={null}>{t('assetTypes.title')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        {isError ? (
          <ErrorLoadingDataAlert />
        ) : (
          <PoGrid isLoading={isLoading} colDefs={generateAssetTypeColumns()} rowData={data} gridOptions={customGridOptions} />
        )}
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
