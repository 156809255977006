import {
  TextField,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { TrackerModalFormModel } from './TrackerModalFormModel';
import { LoadingButton } from '@mui/lab';
import { TrackerType } from '../../api/trackers/tracker.contracts';

interface TrackerFormDisplayProps {
  form: UseFormReturn<TrackerModalFormModel>;
  onSubmit: (data: TrackerModalFormModel) => void;
  isCreate?: boolean;
  isOpen: boolean;
  onClose: () => void;
  trackerId?: number;
}

const TrackerFormDisplay: FunctionComponent<TrackerFormDisplayProps> = ({ form, onSubmit, isCreate = false, isOpen, onClose, trackerId }) => {
  const { formState, register, handleSubmit, getValues, control, watch } = form;
  const { errors, isSubmitting } = formState;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create a new Tracker</DialogTitle>
        <DialogContent>
          <DialogContentText>Please specify what type of Tracker you would like to add.</DialogContentText>

          <div className="mt-2 flex w-full flex-col gap-y-2">
            <Controller
              name="type"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                  <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" label={'Type'} {...field}>
                    <MenuItem value={TrackerType.RFID}>RFID</MenuItem>
                    <MenuItem value={TrackerType.Barcode}>Barcode</MenuItem>
                  </Select>
                  <FormHelperText>{errors.type?.message} </FormHelperText>
                </FormControl>
              )}
            />

            {watch('type') === TrackerType.RFID && (
              <Controller
                name="epc"
                control={control}
                render={({ field }) => <TextField error={errors.epc !== undefined} helperText={errors.epc?.message} label="EPC" {...field} />}
              />
            )}

            {watch('type') === TrackerType.Barcode && (
              <Controller
                name="barcode"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.barcode !== undefined} helperText={errors.barcode?.message} label="Barcode" {...field} />
                )}
              />
            )}

            {watch('type') === TrackerType.UnaTag && (
              <Controller
                name="deviceId"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.deviceId !== undefined}
                    helperText={errors.deviceId?.message}
                    label="Device Identifier"
                    {...field}
                  />
                )}
              />
            )}

            {/* <TextField id="outlined-basic" label="Password" variant="outlined" /> */}
          </div>

          {/* <ActionBar>
          <LoadingButton variant="contained" size="large" type="submit" loading={isSubmitting}>
            Save
          </LoadingButton>
        </ActionBar> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color={'secondary'}>
            Cancel
          </Button>
          <LoadingButton variant="contained" onClick={handleSubmit(onSubmit)} loading={isSubmitting}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TrackerFormDisplay;
