import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAuthUserContext } from '@/modules/auth';
import { FunctionComponent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SettingsContext } from '@/modules/settings/contexts/settings.context';
import { LoadingButton } from '@mui/lab';

import warehouseImage from '@/assets/warehouse.webp';
import { Alert, Collapse } from '@mui/material';
import { toast } from 'react-toastify';
import { userService } from '@/modules/users/api/users/users.service';
import { useUser } from '@/modules/users/contexts/UserContext';
import { useUserSettings } from '@/modules/users/contexts/UserSettingsContext';
import { useScreenSize } from '@/hooks/useScreenSize';

export const LoginPage: FunctionComponent = () => {
  const { signIn, authError } = useAuthUserContext();
  const navigate = useNavigate();
  const { loadSettings } = useContext(SettingsContext);
  const [isLoading, setIsLoading] = useState(false);
  const { loadUserSettings } = useUserSettings();
  const { setUser } = useUser();
  const { isBelowMD } = useScreenSize();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    try {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const credentials = {
        email: data.get('email')?.toString() ?? '',
        password: data.get('password')?.toString() ?? '',
      };

      const loggedInUser = await signIn(credentials.email, credentials.password);

      // Load Application Settings
      const settings = await loadSettings();
      if (settings.length === 0) {
        toast.error('Error loading settings');
        return;
      }

      // Load user
      const getPositoUser = await userService.getMe(loggedInUser.uid);
      if (getPositoUser.isSuccess) {
        setUser(getPositoUser.payload);
      } else {
        toast.error('Error loading user');
        return;
      }

      // Load User Settings
      const userSettings = await loadUserSettings(getPositoUser.payload.id);
      if (!userSettings.isSuccess) {
        toast.error('Error loading user settings');
        return;
      }

      if (isBelowMD) {
        navigate('/mobile');
      } else {
        navigate('/app');
      }
    } catch (err) {
      // toast.error('Something went wrong. Please contact us if the problem persists.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="  flex">
      <div
        className=" h-screen hidden md:block w-2/5 bg-primary-600  lg:w-3/5"
        style={{
          backgroundImage: `url(${warehouseImage})`,
          backgroundSize: 'cover',
        }}
      ></div>
      <div className="h-screen flex w-full md:w-3/5 flex-col items-center justify-center px-8 lg:w-2/5">
        <a className="title-font mb-14 flex items-center  font-medium text-inherit no-underline md:mb-14" href="/">
          <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.2454 11.6227C23.2454 14.9394 22.1074 17.5222 20.3349 19.3712C20.207 19.5046 20.0758 19.6342 19.9415 19.7599L11.6033 28L8.01547 24.4385V17.4709C9.12408 18.2609 10.4805 18.7255 11.9455 18.7255C15.69 18.7255 18.7254 15.6901 18.7254 11.9456C18.7254 8.20117 15.69 5.16571 11.9455 5.16571C8.20108 5.16571 5.16561 8.20117 5.16561 11.9456C5.16561 12.0612 5.1685 12.1761 5.17422 12.2902H5.16557V21.6096L3.27067 19.7287C3.14794 19.6127 3.02783 19.4935 2.91051 19.3712C1.13803 17.5222 0 14.9394 0 11.6227C0 5.20366 5.20366 0 11.6227 0C18.0417 0 23.2454 5.20366 23.2454 11.6227ZM15.7592 11.9455C15.7592 14.0518 14.0517 15.7592 11.9455 15.7592C9.83923 15.7592 8.13178 14.0518 8.13178 11.9455C8.13178 9.8393 9.83923 8.13185 11.9455 8.13185C14.0517 8.13185 15.7592 9.8393 15.7592 11.9455Z"
              className=" fill-primary-600"
            />
          </svg>

          <span className="ml-3  text-4xl font-semibold tracking-wide">Posito</span>
        </a>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            disabled={isLoading}
            margin="normal"
            size="medium"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <TextField
            disabled={isLoading}
            margin="normal"
            size="medium"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
          <Collapse in={authError !== null}>
            <Alert variant="standard" severity="warning" className="mt-4">
              {authError}
            </Alert>
          </Collapse>
          <LoadingButton
            loading={isLoading}
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            sx={{ mt: 25, mb: 2, py: 12 }}
            className="flex"
          >
            Sign In
          </LoadingButton>
          <Typography variant="body2" color="text.secondary" align="center" className="mt-4">
            {'Copyright © '}
            <Link color="inherit" href="https://posito.io/">
              Posito
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
      </div>
    </div>
  );
};

// Attribution
{
  /* <a href="https://www.freepik.com/free-photo/interior-large-distribution-warehouse-with-shelves-stacked-with-palettes-goods-ready-market_11451217.htm#query=supply%20chain&position=9&from_view=search&track=ais">Image by aleksandarlittlewolf</a> on Freepik */
}
