import { EventSourceType, EventType, GpsSourceType } from '../api/events/event.contracts';

export const EVENT_TRANSLATIONS_ENGLISH = {
  event: {
    title: 'Events',
    all: 'All',
    handheld: 'Handheld',
    event_one: 'Event',
    event_other: 'Events',
    occurred_at: 'Occurred At',
    source: 'Source',
    coordinates_source: 'Coordinates Source',
    coordinates_accuracy: 'Coordinates Accuracy',
    asset_code: 'Asset Code',
    tracker_type: 'Tracker Type',
    location: 'Location',
    tracker: 'Tracker',
    device_name: 'Device Name',
    rfids: 'RFIDs',
    barcodes: 'Barcodes',
    mutations: 'Mutations',
    assets: 'Assets',
    trackers: 'Trackers',

    type: {
      title: 'Type',
      [EventType.Observation]: 'Observation',
      [EventType.Incoming]: 'Incoming',
      [EventType.Outgoing]: 'Outgoing',
      [EventType.Inventory]: 'Inventory',
      [EventType.ADMINISTRATIVE]: 'Administrative',
      undefined: 'Unknown',
      all: 'All Event Types',
    },

    event_source: {
      [EventSourceType.GATE]: 'Gate',
      [EventSourceType.MANUAL]: 'Manual',
      [EventSourceType.TRACKER]: 'Tracker',
      [EventSourceType.IMPORTER]: 'Importer',
      [EventSourceType.HANDHELD]: 'Handheld',
      undefined: 'Unknown',
    },

    gps_source: {
      [GpsSourceType.GPS]: 'GPS',
      [GpsSourceType.SIGFOX_ATLAS_NATIVE]: 'Sigfox triangulation',
      [GpsSourceType.CERTIFIED_LOCATION_WIFI]: 'Certified WiFi',
      [GpsSourceType.WIFI]: 'General WiFi',
      [GpsSourceType.CERTIFIED_LOCATION_BLE]: 'Certified BLE',
      [GpsSourceType.CELLULAR]: 'Cellular triangulation',
      [GpsSourceType.LOCATION_CODE]: 'Location Code',
      undefined: 'Unknown',
      null: '',
    },

    errors: {
      date_from_after: 'Date from cannot be after date to',
      date_to_before: 'Date to cannot be before date from',
      too_large_daterange: 'The date range is larger than 1 month. Please use a smaller range.',

    }

  },
};
