import { GroupPerTimeType } from '../components/widgets/events-over-time-widget/group-per-time-type.enum';
import { TopAssetTypesMetric } from '../components/widgets/top-assets-widget/TopAssetTypesWidgetConfiguration';
import { TopLocationsMetric } from '../components/widgets/top-locations-widget/TopLocationsWidgetConfiguration';

export const DASHBOARD_TRANSLATIONS_ENGLISH = {
  dashboard: {
    top_asset_types_metric: {
      [TopAssetTypesMetric.assetCount]: 'Asset Count',
      [TopAssetTypesMetric.assetTotalMonetaryValue]: 'Asset Total Monetary Value',
    },
    top_locations_metric: {
      [TopLocationsMetric.assetCount]: 'Asset Count',
      [TopLocationsMetric.assetTotalMonetaryValue]: 'Asset Total Monetary Value',
    },
    group_per_time_type: {
      [GroupPerTimeType.DAY]: 'Day',
      [GroupPerTimeType.HOUR]: 'Hour',
      [GroupPerTimeType.WEEK]: 'Week',
    },
    balance_over_time_period: {
      last_30_days: 'Last 30 days',
      last_90_days: 'Last 90 days',
    },
    dashboard: 'Dashboard',
    home: 'Home',
    admin: 'Admin',
    create: {
      title: 'Create a new Dashboard',
      description: 'Enter the name of the Dashboard you would like to create:',
      nameLabel: 'Name',
      helperText: 'At least 3 characters',
      cancel: 'Cancel',
      createButton: 'Create',
      success: 'Dashboard created!',
    },
    rename: {
      title: 'Rename Dashboard',
      description: 'Enter the new name for the Dashboard:',
      newNameLabel: 'New Name',
      helperText: 'At least 3 characters',
      cancel: 'Cancel',
      renameButton: 'Rename',
      success: 'Dashboard renamed!',
    },
    widget: {
      deleteButton: 'Delete Widget',
      editWidget: "Edit '{{widgetName}}' Widget",
      closeAriaLabel: 'close',
      editConfiguration: 'Edit the configuration of this widget below.',
    },
    widgetCatalog: {
      addWidgets: 'Add Widgets',
      closeAriaLabel: 'close',
      dragDropInstruction: 'Drag and drop a widget from the list below onto the dashboard to start using it.',
    },
    balanceOverTimeWidget: {
      noBalancesFound: 'No balances found.',
      title: 'Location Balance Over Time',
      description: 'Shows the Balances of a Location over time, grouped by a specified timespan.',
    },
    balanceOverTimeWidgetConfiguration: {
      title: 'Balance per day',
    },
    countWidget: {
      title: 'Asset Count',
      description: 'Shows the count of Assets based on Location or Label filters.',
    },
    countWidgetConfiguration: {
      title: 'Total Assets',
    },
    eventsOverTimeByTypeWidget: {
      title: 'Events Over Time by Type',
      description: 'Shows the total amount of Events of the last 7 days, grouped by Type.',
    },
    eventsOverTimeByTypeWidgetConfiguration: {
      title: 'Events Over Time by Type',
    },
    eventsOverTimeWidget: {
      title: 'Events Over Time',
      description: 'Shows the total amount of Events, grouped by a specified timespan.',
    },
    eventsOverTimeWidgetConfiguration: {
      title: 'Events per day',
    },
    mapWidget: {
      title: 'Map',
      description: 'Shows the position of your Assets on the map.',
    },
    mapWidgetConfiguration: {
      title: 'Map',
    },
    systemTotalWidget: {
      title: 'Total',
      description: 'Shows the total amount of Assets, Trackers, Events or Locations in the system.',
    },
    systemTotalWidgetConfiguration: {
      title: 'Total',
    },
    topAssetTypesWidget: {
      title: 'Top Asset Types',
      description: 'A Top 10 of Asset Types based on a metric you select.',
    },
    topAssetTypesWidgetConfiguration: {
      title: 'Top Asset Types',
    },
    topLocationsWidget: {
      title: 'Top Locations',
      description: 'A Top 10 of Locations based on a metric you select.',
    },
    topLocationsWidgetConfiguration: {
      title: 'Top Locations by Asset Count',
    },
    deleteConfirmation: {
      questionText: 'Are you sure you want to delete this dashboard?',
      questionTitle: 'Delete Dashboard',
    },
    header: 'Dashboards',
    addTab: '+ Dashboard',
    actions: {
      showFullscreen: 'Show Fullscreen',
      editWidgets: 'Edit Widgets',
      deleteDashboard: 'Delete Dashboard',
      renameDashboard: 'Rename Dashboard',
      saveDashboard: 'Save Dashboard',
    },
  },
};
