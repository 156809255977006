import { DeviceReadDTO } from '../api/device/device.contracts';

export class DeviceModel {
  id: number;
  code: string;
  name: string;
  brand: string;
  type: string;
  serialNumber: string;
  lastEventReceivedAt?: Date;

  constructor(dto: DeviceReadDTO) {
    this.id = dto.id;
    this.code = dto.code;
    this.name = dto.name;
    this.brand = dto.brand;
    this.type = dto.type;
    this.serialNumber = dto.serialNumber;
    this.lastEventReceivedAt = dto.lastEventReceivedAt;
  }

  toDTO(): DeviceReadDTO {
    return {
      id: this.id,
      code: this.code,
      name: this.name,
      brand: this.brand,
      type: this.type,
      serialNumber: this.serialNumber,
      lastEventReceivedAt: this.lastEventReceivedAt,
    };
  }

  static fromDTO(dto: DeviceReadDTO): DeviceModel {
    return new DeviceModel({
      id: dto.id,
      code: dto.code,
      name: dto.name,
      brand: dto.brand,
      type: dto.type,
      serialNumber: dto.serialNumber,
      lastEventReceivedAt: dto.lastEventReceivedAt,
    });
  }
}
