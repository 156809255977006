import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { DeviceCreateFormSchema, DeviceFormModel, DeviceFormSchema } from './DeviceFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import DeviceFormDisplay from './DeviceFormDisplay';

interface DeviceFormLogicProps {
  initialValues: DeviceFormModel;
  onSubmit: (data: DeviceFormModel) => Promise<boolean>;
  isCreate?: boolean;
}

const DeviceFormLogic: FunctionComponent<DeviceFormLogicProps> = ({ initialValues, onSubmit, isCreate }) => {
  const form = useForm<DeviceFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? DeviceCreateFormSchema : DeviceFormSchema),
  });

  const handleSubmit = async (data: DeviceFormModel) => {
    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return <DeviceFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} />;
};

export default DeviceFormLogic;
