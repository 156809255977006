import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridOptions, ICellRendererParams, RowDoubleClickedEvent } from 'ag-grid-community';
import LinkCellRenderer, { LinkCellRendererParams } from '@/components/grid/cells/LinkCellRenderer';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { ReadAssetTypeBalanceDTO } from '../../api/asset-types/asset-type.contracts';
import { useTranslation } from '@/lib';

interface AssetTypeBalanceGridProps {
  data?: ReadAssetTypeBalanceDTO[];
  isError: boolean;
}

export const AssetTypeBalanceGrid: FunctionComponent<AssetTypeBalanceGridProps> = ({ data, isError }) => {
  const { t } = useTranslation();
  if (!data) {
    return <div>{t('noData')}</div>;
  }
  const navigate = useNavigate();

  const customGridOptions: GridOptions<ReadAssetTypeBalanceDTO> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<ReadAssetTypeBalanceDTO, any>) {
      navigate(`/app/locations/${event.data?.locationId}`);
    },
  };

  const columns: ColDefOrGroup<ReadAssetTypeBalanceDTO>[] = [
    {
      field: 'locationCode',
      headerName: t('assetTypes.balance.locationCode'),
    },
    {
      field: 'locationName',
      headerName: t('assetTypes.balance.locationName'),
      cellRenderer: LinkCellRenderer,
      cellRendererParams: (params: ICellRendererParams<ReadAssetTypeBalanceDTO>): LinkCellRendererParams => ({
        pathname: `/app/locations/${params.data?.locationId}`,
      }),
    },
    {
      field: 'currentBalance',
      headerName: t('assetTypes.balance.current'),
    },
  ];

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      {isError ? <ErrorLoadingDataAlert /> : <PoGrid isLoading={false} colDefs={columns} rowData={data} gridOptions={customGridOptions} />}
    </div>
  );
};
