import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { EventModel } from '../../types/EventModel';
import {
  EventCountOverTimeByTypeDTO,
  EventCountOverTimeDTO,
  EventCreateDTO,
  EventGroupDto,
  EventGroupListDto,
  EventReadDTO,
  EventType,
  EventUpdateDTO,
} from './event.contracts';
import { Dayjs } from 'dayjs';
import { GroupPerTimeType } from '@/modules/dashboards/components/widgets/events-over-time-widget/group-per-time-type.enum';

export class EventService {
  public basePath = 'event';

  async create(eventToCreate: EventCreateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.post<undefined>(this.basePath + '/', eventToCreate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(eventId: number, eventToUpdate: EventUpdateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.patch<undefined>(`${this.basePath}/${eventId}`, eventToUpdate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAll(): Promise<ApiResponse<EventModel[]>> {
    try {
      const applicationResponse = await apiClient.get<EventReadDTO[]>(this.basePath);
      return applicationResponse.processPayload((payload) => {
        return payload.map(EventModel.fromDTO);
      });
    } catch (error) {
      console.log(error);

      return ApiResponse.UnknownErrorResponse();
    }
  }

  async get(
    query: {
      assetId?: number;
      locationId?: number;
      dateFrom?: Dayjs;
      dateTo?: Dayjs;
      limit?: number;
      eventType?: EventType;
    } = { limit: 1000 },
  ): Promise<ApiResponse<EventModel[]>> {
    try {
      console.log({ query });

      const applicationResponse = await apiClient.get<EventReadDTO[]>(this.basePath, {
        ...query,
        dateFrom: query.dateFrom?.toISOString(),
        dateTo: query.dateTo?.toISOString(),
      });
      return applicationResponse.processPayload((payload) => {
        return payload.map(EventModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(id: number): Promise<ApiResponse<EventModel>> {
    try {
      const applicationResponse = await apiClient.get<EventReadDTO>(`${this.basePath}/${id}`);
      return applicationResponse.processPayload(EventModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getCount(): Promise<ApiResponse<number>> {
    try {
      const applicationResponse = await apiClient.get<{ count: number }>(`${this.basePath}/count`);
      return applicationResponse.processPayload((payload) => {
        return payload.count;
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getCountsByType(): Promise<ApiResponse<EventCountOverTimeByTypeDTO[]>> {
    try {
      const applicationResponse = await apiClient.get<EventCountOverTimeByTypeDTO[]>(`${this.basePath}/type/count`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getEventGroups(): Promise<ApiResponse<EventGroupListDto[]>> {
    try {
      const eventGroupResponse = await apiClient.get<EventGroupListDto[]>(`${this.basePath}/group`);
      return eventGroupResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getEventGroupByUuid(eventGroupUuid: string): Promise<ApiResponse<EventGroupDto>> {
    try {
      const eventGroupResponse = await apiClient.get<EventGroupDto>(`${this.basePath}/group/${eventGroupUuid}`);
      return eventGroupResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getEventCountGroupedByTime(groupBy: GroupPerTimeType): Promise<ApiResponse<EventCountOverTimeDTO[]>> {
    try {
      const eventGroupResponse = await apiClient.get<EventCountOverTimeDTO[]>(`${this.basePath}/counts`, { groupBy: groupBy });
      return eventGroupResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const eventService = new EventService();
