import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FunctionComponent } from 'react';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import ReorderRoundedIcon from '@mui/icons-material/ReorderRounded';
import { isNil } from 'lodash-es';
import { DisplayMode } from '@/types/display-mode.enum';
import { useTranslation } from '@/lib';

interface LocationHierarchyToggleButtonProps {
  displayMode: DisplayMode;
  onChange: (displayMode: DisplayMode) => void;
  reverseOrder?: boolean;
}

const LocationHierarchyToggleButton: FunctionComponent<LocationHierarchyToggleButtonProps> = ({ displayMode, onChange, reverseOrder }) => {
  const { t } = useTranslation();
  const onButtonGroupValueChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: DisplayMode | null) => {
    if (!isNil(value)) {
      onChange(value);
    }
  };

  const buttons = [
    <ToggleButton key="hierarchical" value={DisplayMode.HIERARCHICAL} aria-label="Hierarchical">
      <AccountTreeRoundedIcon />
      <span className="pl-2">{t('balance.hierarchical')}</span>
    </ToggleButton>,
    <ToggleButton key="individual" value={DisplayMode.INDIVIDUAL} aria-label="Individual">
      <ReorderRoundedIcon />
      <span className="pl-2">{t('balance.individual')}</span>
    </ToggleButton>,
  ];

  return (
    <ToggleButtonGroup value={displayMode} exclusive onChange={onButtonGroupValueChanged} aria-label="Hierarchy">
      {reverseOrder ? buttons.reverse() : buttons}
    </ToggleButtonGroup>
  );
};

export default LocationHierarchyToggleButton;
