export const IMPORTER_TRANSLATIONS_ENGLISH = {
  importer: {
    upload_a_file: 'Upload a File',
    import_error: {
      NON_UNIQUE_ASSET_CODE: 'NON_UNIQUE_ASSET_CODE',
      NON_UNIQUE_TRACKER_BARCODE: 'NON_UNIQUE_TRACKER_BARCODE',
      NON_UNIQUE_TRACKER_EPC: 'NON_UNIQUE_TRACKER_EPC',
      NON_UNIQUE_TRACKER_DEVICE_ID: 'NON_UNIQUE_TRACKER_DEVICE_ID',
      NON_EXISTENT_ASSET_TYPE_CODE: "Asset Type Doesn't Exist",
      BARCODE_AND_EPC_CODE_AND_UNATAG_ID_NOT_DEFINED: 'BARCODE_AND_EPC_CODE_AND_UNATAG_ID_NOT_DEFINED',
      NON_EXISTENT_LOCATION_CODE: "Location Doesn't Exist",
    },
    next: 'Next',
    previous: 'Previous',
    preview: {
      preview: 'Preview',
      file_contains_errors: 'The file contains {{count}} errors that need to be resolved. Once resolved, try uploading the file again.',
      file_upload_failed: 'File upload failed. Please try again.',
    },
    upload: {
      file_details: 'File Details',
      files_you_can_imported: 'Select a CSV file to be imported.',
      file_size: 'Size',
      select_file: 'Select file',
      select_csv_file: 'Select CSV file',
      change_file: 'Change File',
    },
    import: {
      import: 'Import',
    },
    asset_import: {
      title: 'Assets',
      description: 'Import Assets, including their Barcode or EPC code or Unatag Device Id.',
    },
    location_import: {
      title: 'Locations',
      description: 'Import Locations',
    },
    parent_location_import: {
      title: 'Location Parents',
      description: 'Set Location Parents',
    },
  },
};
