import { z } from 'zod';

export const LocationFormSchema = z.object({
  name: z.string().min(1),
  code: z.string().min(1),
  description: z.string(),
  geofence: z.array(z.array(z.number())).nullish().optional(),
  country: z.string().nullish().optional(),
  city: z.string().nullish().optional(),
  postalCode: z.string().nullish().optional(),
  streetName: z.string().nullish().optional(),
  streetNumber: z.string().nullish().optional(),
  parentLocationId: z.number().nullish().optional(),
  isSystem: z.boolean(),
});

export const LocationCreateFormSchema = LocationFormSchema.extend({});

export type LocationFormModel = z.infer<typeof LocationFormSchema>;
