import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import BarcodeScanner from './BarcodeScanner';
import { Close } from '@mui/icons-material';

interface BarcodeScanModalProps {
  open: boolean;
  onCancel: () => void;
  onScan: (barcode: string) => void;
}

const BarcodeScanModal: React.FC<BarcodeScanModalProps> = ({ open, onScan, onCancel }) => {
  return (
    <Dialog fullScreen open={open} onClose={onCancel}>
      <div className="flex items-center  justify-between">
        <DialogTitle>Scan a Barcode</DialogTitle>
        <IconButton className="mr-6" edge="start" color="inherit" onClick={onCancel} aria-label="close">
          <Close />
        </IconButton>
      </div>
      <DialogContent>
        <div className="flex justify-center mb-6">Position the barcode in the center of the screen.</div>

        <BarcodeScanner
          onBarcodeScanned={(barcode) => {
            onScan(barcode);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BarcodeScanModal;
