import { Autocomplete, TextField } from '@mui/material';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { locationService } from '../api/locations/location.service';
import { LocationModel } from '../types/LocationModel';

interface LocationSelectProps {
  onSelected: (selectedLocation: LocationModel | null) => void;
  exludedLocationIds?: number[];
  clearSelectionOnSelect?: boolean;
  label?: string;
  helperText?: string;
  selectedLocationId?: number;
  onlyEligibleParentsForLocationId?: number;
}

export const LocationSelect: FunctionComponent<LocationSelectProps> = ({
  onSelected,
  exludedLocationIds,
  clearSelectionOnSelect,
  helperText,
  selectedLocationId,
  label = 'Select a Location',
  onlyEligibleParentsForLocationId,
}) => {
  const [selectedOption, setSelectedOption] = useState<LocationModel | null>(null);
  const [inputValue, setInputValue] = useState('');
  const exludedLocationIdsMemoed = useMemo(() => exludedLocationIds?.toString(), [exludedLocationIds]);

  const handleOptionChange = (event: unknown, value: LocationModel | null) => {
    if (!clearSelectionOnSelect) {
      setSelectedOption(value);
    } else {
      setInputValue('');
      loadOptions('');
      setSelectedOption(null);
    }
    onSelected(value);
  };

  const [options, setOptions] = useState<LocationModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadOptions = async (query: string) => {
    setIsLoading(true);
    try {
      let locations: LocationModel[] = [];
      if (onlyEligibleParentsForLocationId) {
        const response = await locationService.getEligibleParentsForLocationId({ locationId: onlyEligibleParentsForLocationId, query });
        if (response.isSuccess) {
          locations = response.payload;
        }
      } else {
        const response = await locationService.getByQuery({ query });
        if (response.isSuccess) {
          locations = response.payload;
        }
      }

      if (exludedLocationIds) {
        locations = locations.filter((t) => !exludedLocationIds.includes(t.dto.id));
      }

      setOptions(locations);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedLocationId) return;

    const loadLocationById = async () => {
      const locationResponse = await locationService.getById(selectedLocationId);
      if (locationResponse.isSuccess) {
        setSelectedOption(locationResponse.payload);
      }
    };
    loadLocationById();
  }, [selectedLocationId]);

  useEffect(() => {
    console.log('loading exludedLocationIds', { exludedLocationIds });

    loadOptions('');
  }, [exludedLocationIdsMemoed]);

  return (
    <Autocomplete
      value={selectedOption}
      inputValue={inputValue}
      className="w-full"
      options={options}
      onOpen={() => loadOptions('')}
      isOptionEqualToValue={(option, value) => option.dto.id === value.dto.id}
      getOptionLabel={(option) => `${option.dto.name} (${option.dto.code})`}
      onInputChange={(event, newInputValue) => {
        loadOptions(newInputValue);
        setInputValue(newInputValue);
      }}
      filterOptions={(x) => x}
      onChange={(_, value) => {
        handleOptionChange(_, value);
      }}
      loading={isLoading}
      renderInput={(params) => <TextField {...params} label={label} helperText={helperText} InputLabelProps={{ shrink: true }} />}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.dto.id}>
            {option.dto.name} ({option.dto.code})
          </li>
        );
      }}
    />
  );
};
