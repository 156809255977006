import { BASE_GRID_OPTIONS, PoGrid } from '@/components/grid/PoGrid';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { useApiCall } from '@/hooks/useApiCall';
import { locationService } from '@/modules/locations/api/locations/location.service';
import { LocationModel } from '@/modules/locations/types/LocationModel';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IDetailCellRendererParams,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterBar from '@/components/filterbar/FilterBar';
import { FilterValues } from '@/components/filterbar/FilterBarContext';
import TextFilter from '@/components/filterbar/filters/TextFilter';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { Button, Checkbox, Tooltip } from '@mui/material';
import { useLayoutActions } from '@/providers/layout-actions-provider/LayoutActionsProvider';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import LocationHierarchyToggleButton from '../components/LocationHierarchyToggleButton';
import { ActionBar } from '@/modules/application';
import ManageLabelAssignmentsDrawer from '@/modules/labels/components/ManageLabelAssignmentsDrawer';
import { AssignedLabelDTO, LabelAssignmentEntityType } from '@/modules/labels/api/label.contracts';
import LabelCellRenderer, { LabelCellRendererParams } from '@/components/grid/cells/LabelCellRenderer';
import { DisplayMode } from '@/types/display-mode.enum';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';

export const LocationsPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { setActions } = useLayoutActions();
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.INDIVIDUAL);
  const { hasPermission } = useUser();

  const { filters, setFiltersToUrl } = useQueryParamsFilters(
    {
      searchText: '',
    },
    CacheKey.LOCATION_FILTERS,
  );

  const [selectedRows, setSelectedRows] = useState<LocationModel[]>([]);
  const [level2SelectedRows, setLevel2SelectedRows] = useState<LocationModel[]>([]);
  const [level3SelectedRows, setLevel3SelectedRows] = useState<LocationModel[]>([]);
  const [level1GridApi, setLevel1GridApi] = useState<GridApi<LocationModel>>();
  const [level2GridApi, setLevel2GridApi] = useState<GridApi<LocationModel>>();
  const [level3GridApi, setLevel3GridApi] = useState<GridApi<LocationModel>>();

  const [isManageLabelAssignmentsDrawerOpen, setIsManageLabelAssignmentsDrawerOpen] = useState(false);

  const { data, isLoading, isError, fetchData, setApiCallArg } = useApiCall<LocationModel[]>(() => {
    if (displayMode === DisplayMode.HIERARCHICAL) {
      return locationService.getAllHierarchical({ query: filters.current.searchText ?? '' });
    } else {
      return locationService.getAll({ query: filters.current.searchText ?? '' });
    }
  });

  const columnDefs = useMemo<ColDef<LocationModel>[]>(() => {
    const checkboxColumn: ColDef<LocationModel> = {
      colId: 'selection',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: false,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      suppressColumnsToolPanel: true,
      suppressMenu: true,
      lockVisible: true,
      sortable: false,
    };

    const columns: ColDef<LocationModel>[] = [
      {
        cellRenderer: 'agGroupCellRenderer',
        field: 'dto.name',
        headerName: 'Name',
      },
      {
        field: 'dto.code',
        headerName: 'Code',
        width: 150,
      },

      {
        field: 'totalChildrenCount',
        headerName: 'Sublocations',
        valueFormatter: (params: ValueFormatterParams<LocationModel>) => {
          return params.data?.totalChildrenCount && params.data.totalChildrenCount > 0 ? params.data?.totalChildrenCount.toString() : '-';
        },
        hide: displayMode !== DisplayMode.HIERARCHICAL,
      },
      {
        field: 'dto.labels',
        headerName: 'Labels',
        cellRenderer: LabelCellRenderer,
        cellRendererParams: (params: ICellRendererParams<LocationModel>): LabelCellRendererParams => {
          const labels: AssignedLabelDTO[] = params.data?.dto.labels ?? [];
          return {
            labels,
          };
        },
        valueFormatter: (params: ValueFormatterParams<LocationModel>) => {
          return params.data?.dto.labels?.map((label) => label.value.value).join(', ') ?? '-';
        },
        flex: 1,
      },
    ];

    if (hasPermission(Permission.LOCATIONS_EDIT)) {
      columns.unshift(checkboxColumn);
    }

    return columns;
  }, [displayMode]);

  const detailCellRendererParams = useMemo<IDetailCellRendererParams<LocationModel>>(() => {
    return {
      // level 2 grid options
      detailGridOptions: {
        columnDefs: columnDefs,
        defaultColDef: {
          flex: 1,
        },
        // groupDefaultExpanded: 1,

        masterDetail: true,
        detailRowHeight: 240,
        detailRowAutoHeight: true,
        onRowDoubleClicked(event: RowDoubleClickedEvent<LocationModel, unknown>) {
          navigate(`../${event.data?.dto.id}`);
        },
        isRowMaster: (loc) => {
          if (!loc.children || loc.children.length < 1) {
            return false;
          } else {
            return true;
          }
        },
        rowSelection: 'multiple',
        onGridReady: (params) => {
          if (!params.api) {
            return;
          }

          setLevel2GridApi(params.api);
        },
        onSelectionChanged: (event: SelectionChangedEvent<LocationModel, unknown>) => {
          console.log({
            rows: event.api.getSelectedRows(),
            EventSource: event.source,
          });

          setLevel2SelectedRows([...event.api.getSelectedRows()]);
        },
        detailCellRendererParams: {
          // level 3 grid options
          detailGridOptions: {
            columnDefs: columnDefs,
            defaultColDef: {
              flex: 1,
            },
            domLayout: 'autoHeight',
            onRowDoubleClicked(event: RowDoubleClickedEvent<LocationModel, unknown>) {
              navigate(`../${event.data?.dto.id}`);
            },
            rowSelection: 'multiple',
            onSelectionChanged: (event: SelectionChangedEvent<LocationModel, unknown>) => {
              console.log(event.api.getSelectedRows());
              setLevel3SelectedRows(event.api.getSelectedRows());
            },
            onGridReady: (params) => {
              if (!params.api) {
                return;
              }

              setLevel3GridApi(params.api);
            },
          },
          getDetailRowData: (params) => {
            params.successCallback(params.data.children ?? []);
          },
        } as IDetailCellRendererParams<LocationModel>,
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.children ?? []);
      },
    } as IDetailCellRendererParams<LocationModel>;
  }, [
    columnDefs,
    onLevel2GridReady,
    selectedRows,
    level2SelectedRows,
    level3SelectedRows,
    setSelectedRows,
    setLevel2SelectedRows,
    setLevel3SelectedRows,
  ]);

  function onLevel2GridReady(api: GridApi<LocationModel>) {
    // Select the rows that were selected in the parent grid
    console.log({
      selectedRows,
      level2SelectedRows,
      level3SelectedRows,
    });

    if (level2SelectedRows.length > 0) {
      console.log(level2SelectedRows, 'selected');
      api.forEachNode((node) => {
        if (level2SelectedRows.some((row) => row.dto.id === node.data?.dto.id)) {
          node.setSelected(true);
          api.setNodesSelected({ nodes: [node], newValue: true });
        } else {
          node.setSelected(false);
          api.setNodesSelected({ nodes: [node], newValue: false });
        }
      });
    }
  }

  useEffect(() => {
    if (level2GridApi) {
      // Logic to select rows based on level2SelectedRows
      level2GridApi.forEachNode((node) => {
        if (level2SelectedRows.some((row) => row.dto.id === node.data?.dto.id)) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    }
  }, [level2SelectedRows, level2GridApi]);

  useEffect(() => {
    if (level3GridApi) {
      // Logic to select rows based on level3SelectedRows
      level3GridApi.forEachNode((node) => {
        if (level3SelectedRows.some((row) => row.dto.id === node.data?.dto.id)) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    }
  }, [level3SelectedRows, level3GridApi]);

  const customGridOptions: GridOptions<LocationModel> = {
    ...BASE_GRID_OPTIONS,
    onRowDoubleClicked(event: RowDoubleClickedEvent<LocationModel, unknown>) {
      navigate(`../${event.data?.dto.id}`);
    },
    getRowId: (data) => data.data.dto.id.toString(),
    masterDetail: true,
    detailRowHeight: 240,
    detailRowAutoHeight: true,
    detailCellRendererParams: detailCellRendererParams,
    suppressRowClickSelection: true,
    isRowMaster: (loc) => {
      if (!loc.children || loc.children.length < 1) {
        return false;
      } else {
        return true;
      }
    },
    rowSelection: 'multiple',
    onSelectionChanged: (event: SelectionChangedEvent<LocationModel, unknown>) => {
      console.log(event.api.getSelectedRows());
      setSelectedRows(event.api.getSelectedRows());
    },
    onGridReady: onGridReady,
    // onRowSelected: (event) => {
    //   if (!event.data) {
    //     return;
    //   }

    //   // TODO: Somehow determine if the selected node is a level 1, 2 or 3 node
    //   // And update the right states for the selected rows

    //   const children1LevelBelow = event.data.children ?? [];
    //   const children2LevelsBelow = children1LevelBelow.flatMap((row) => row.children ?? []);
    //   if (event.node.isSelected()) {
    //     setSelectedRows((prev) => {
    //       if (event.data) {
    //         return prev.concat(event.data);
    //       }
    //       return prev;
    //     });
    //     setLevel2SelectedRows((prev) => {
    //       return prev.concat(children1LevelBelow);
    //     });
    //     setLevel3SelectedRows((prev) => {
    //       return prev.concat(children2LevelsBelow);
    //     });
    //   } else {
    //     setSelectedRows((prev) => {
    //       return prev.filter((row) => row.dto.id !== event.data?.dto.id);
    //     });
    //     setLevel2SelectedRows((prev) => {
    //       return prev.filter((row) => !children1LevelBelow.some((r) => r.dto.id === row.dto.id));
    //     });
    //     setLevel3SelectedRows((prev) => {
    //       return prev.filter((row) => !children2LevelsBelow.some((r) => r.dto.id === row.dto.id));
    //     });
    //   }
    // },
  };

  const handleSearch = (filterValues: FilterValues) => {
    setFiltersToUrl(filterValues);

    searchWithCurrentFilterValues(displayMode, filterValues);
  };

  function searchWithCurrentFilterValues(displayMode: DisplayMode, filterValues: FilterValues) {
    if (!filterValues) {
      return;
    }

    clearSelections();

    if (displayMode === DisplayMode.HIERARCHICAL) {
      setApiCallArg(() => locationService.getAllHierarchical({ query: filterValues.searchText ?? '' }));
    } else {
      setApiCallArg(() => locationService.getAll({ query: filterValues.searchText ?? '' }));
    }
  }

  useEffect(() => {
    if (hasPermission(Permission.LOCATIONS_EDIT)) {
      setActions(
        <Button variant="contained" onClick={onCreateLocationClicked}>
          Create
        </Button>,
      );
    }

    return () => {
      setActions('');
    };
  }, []);

  function clearSelections() {
    setSelectedRows([]);
    setLevel2SelectedRows([]);
    setLevel3SelectedRows([]);

    // clear the selected rows
    level1GridApi?.deselectAll();
    level2GridApi?.deselectAll();
    level3GridApi?.deselectAll();
  }

  function onCreateLocationClicked() {
    navigate('../create');
  }

  function onLocationDisplayChanged(displayMode: DisplayMode) {
    setDisplayMode(displayMode);
    searchWithCurrentFilterValues(displayMode, filters.current);
  }

  const allSelectedRows = selectedRows.concat(level2SelectedRows).concat(level3SelectedRows);
  const hasSelectedRows = allSelectedRows.length > 0;

  function onSetLabelsClicked() {
    setIsManageLabelAssignmentsDrawerOpen(true);
  }

  function onGridReady(params: GridReadyEvent<LocationModel>) {
    if (!params.api) {
      return;
    }

    setLevel1GridApi(params.api);
  }

  function onDeselectAllClicked() {
    clearSelections();
  }

  return (
    <TabbedPageLayout>
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col">
          {isError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <>
              <div className="mb-2 flex justify-between items-center">
                <FilterBar onSearch={handleSearch} initialFilterValues={filters.current}>
                  <div className="w-44">
                    <TextFilter label="Search" />
                  </div>

                  <FilterBarSearchButton />
                </FilterBar>

                <div>
                  <LocationHierarchyToggleButton displayMode={displayMode} reverseOrder onChange={onLocationDisplayChanged} />
                </div>
              </div>
              <PoGrid
                isLoading={isLoading}
                colDefs={columnDefs}
                rowData={data}
                gridOptions={customGridOptions}
                disableResizeColumnsToFit
                disableDefaultGridOptions
              />
            </>
          )}
        </div>

        <ActionBar visible={hasSelectedRows}>
          <div className="flex items-center  ">
            {/* <CheckBoxOutlined fontSize="small" className="mr-2" /> */}
            <Tooltip title="Clear Selection">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" onClick={onDeselectAllClicked} />
            </Tooltip>
            <div className="mr-16 font-semibold">{selectedRows.length + level2SelectedRows.length + level3SelectedRows.length} Locations</div>
            <div>
              <Button variant="contained" onClick={onSetLabelsClicked}>
                Set Label(s)...
              </Button>
            </div>
          </div>
        </ActionBar>

        <ManageLabelAssignmentsDrawer
          isOpen={isManageLabelAssignmentsDrawerOpen}
          onClose={() => {
            setIsManageLabelAssignmentsDrawerOpen(false);
            clearSelections();
            fetchData();
          }}
          entityType={LabelAssignmentEntityType.LOCATION}
          labelAssignments={allSelectedRows.flatMap(
            (row) =>
              row.dto.labels?.flatMap((label) => {
                return {
                  labelId: label.id,
                  labelValueId: label.value.id,
                  entityType: LabelAssignmentEntityType.LOCATION,
                  entityId: row.dto.id,
                };
              }) ?? [],
          )}
          entityIds={allSelectedRows.map((row) => row.dto.id)}
        />
      </TabbedPageLayoutBody>
    </TabbedPageLayout>
  );
};
