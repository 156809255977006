import { LocationSelect } from '@/modules/locations/components/LocationSelect';
import { TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { CountWidgetConfiguration } from './CountWidgetConfiguration';
import { useDashboardContext } from '@/modules/dashboards/contexts/DashboardContextProvider';
import LabelValuesSelect from '@/modules/labels/components/LabelValuesSelect';
import AssetTypesSelect from '@/modules/asset-types/components/AssetTypesSelect';

interface CountWidgetConfigurationFormProps {
  configuration: CountWidgetConfiguration;
  id: string;
}

const CountWidgetConfigurationForm: FunctionComponent<CountWidgetConfigurationFormProps> = ({ configuration, id }) => {
  const { updateWidgetConfiguration } = useDashboardContext();

  return (
    <>
      <TextField
        label="Name"
        value={configuration.title}
        onChange={(e) => {
          updateWidgetConfiguration<CountWidgetConfiguration>(id, {
            title: e.currentTarget.value,
          });
        }}
      />

      <LocationSelect
        selectedLocationId={configuration.locationId}
        onSelected={(selectedLocation) => {
          updateWidgetConfiguration<CountWidgetConfiguration>(id, {
            locationId: selectedLocation?.dto.id,
          });
        }}
        helperText={'Select a Location to filter on.'}
      />

      <LabelValuesSelect
        selectedValues={configuration.labelValueIds ?? []}
        onChange={(values) => {
          updateWidgetConfiguration<CountWidgetConfiguration>(id, {
            labelValueIds: values,
          });
        }}
      />

      <AssetTypesSelect
        selectedAssetTypeIds={configuration.assetTypeIds ?? []}
        onChange={(values) => {
          updateWidgetConfiguration<CountWidgetConfiguration>(id, {
            assetTypeIds: values.map((at) => at.id),
          });
        }}
      />
    </>
  );
};

export default CountWidgetConfigurationForm;
