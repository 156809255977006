import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabMenuItem } from '@/modules/application/types/MenuItem';
import { LayoutActionsProvider } from '@/providers/layout-actions-provider/LayoutActionsProvider';
import { PlaceRounded } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';

export function LocationsLayout() {
  const locationTabItems: TabMenuItem[] = [
    {
      activeRouteIds: ['/app/locations', '/app/locations/overview'],
      label: 'Overview',
      url: '/app/locations',
    },
  ];

  return (
    <LayoutActionsProvider>
      <TabbedLayout
        topBar={<TabbedLayoutTopBar header="Locations" headerIcon={<PlaceRounded fontSize={'small'} />} tabItems={locationTabItems} />}
      >
        <Outlet />
      </TabbedLayout>
    </LayoutActionsProvider>
  );
}
