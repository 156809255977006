import { FunctionComponent } from 'react';
import { useApiCall } from '@/hooks/useApiCall';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { roleService } from '../api/roles.service';
import { RoleModel } from '../types/RoleModel';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { Permission } from '../api/permissions.contracts';
import RoleEditor from '../components/RoleEditor';

export const RolesPage: FunctionComponent = () => {
  const { data, isError, fetchData } = useApiCall<RoleModel[]>(() => roleService.getAllRoles());

  async function onPermissionChanged(role: RoleModel, permissions: Permission[], value: boolean) {
    const updatedRoleResponse = await roleService.updateRolePermissions({
      roleId: role.dto.id,
      permissions: permissions,
    });

    if (updatedRoleResponse.isSuccess) {
      fetchData();
    } else {
      console.error('Failed to update role permissions', updatedRoleResponse.message);
    }
  }

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar>
          <TabbedLayoutTopBarHeader>Roles</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        {isError ? (
          <ErrorLoadingDataAlert />
        ) : !data ? (
          <LoadingBlock />
        ) : (
          <div className="flex flex-col">
            {/* <div className="pb-2">Here you can configure what Roles can do. Changes to Roles are saved instantly.</div> */}
            <div className="max-w-screen-lg">
              <RoleEditor roles={data} onPermissionChanged={onPermissionChanged} />
            </div>
          </div>
        )}
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
