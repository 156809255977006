import { createContext } from 'react';
import { Dayjs } from 'dayjs';
import { ApplicationLogCategory, ApplicationLogSeverity } from '@/modules/application-log/api/application-log.contracts';
import { EventType } from '@/modules/events/api/events/event.contracts';
import { PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';

export enum FilterFieldName {
  locationId = 'locationId',
  assetTypeId = 'assetTypeId',
  assetId = 'assetId',
  searchText = 'searchText',
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
  logSeverity = 'logSeverity',
  logCategory = 'logCategory',
  eventType = 'eventType',
  messageId = 'messageId',
  labelValueIds = 'labelValueIds',
  assetTypeIds = 'assetTypeIds',
  pageNumber = 'pageNumber',
  sortOption = 'sortOption',
  sortDirection = 'sortDirection',
  searchNumber = 'searchNumber',
  stayTimeInDays = 'stayTimeInDays',
}

export interface FilterValues {
  [FilterFieldName.assetId]?: number;
  [FilterFieldName.locationId]?: number;
  [FilterFieldName.assetTypeId]?: number;
  [FilterFieldName.searchText]?: string;
  [FilterFieldName.dateFrom]?: Dayjs;
  [FilterFieldName.dateTo]?: Dayjs;
  [FilterFieldName.logSeverity]?: ApplicationLogSeverity;
  [FilterFieldName.logCategory]?: ApplicationLogCategory;
  [FilterFieldName.eventType]?: EventType;
  [FilterFieldName.messageId]?: string;
  [FilterFieldName.labelValueIds]?: number[];
  [FilterFieldName.assetTypeIds]?: number[];
  [FilterFieldName.pageNumber]?: number;
  [FilterFieldName.sortOption]?: PageSortOption;
  [FilterFieldName.sortDirection]?: PageSortOrder;
  [FilterFieldName.searchNumber]?: number;
  [FilterFieldName.stayTimeInDays]?: number;
}

export const isFilterFieldName = (key: string): key is FilterFieldName => {
  return Object.values(FilterFieldName).includes(key as FilterFieldName);
};

interface FilterBarContextProps {
  filterValues: FilterValues;
  setFilterValue: (field: FilterFieldName, value: unknown) => void;
  triggerSearch: () => void;
}

// Initialize the context
export const FilterBarContext = createContext<FilterBarContextProps>({
  filterValues: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFilterValue: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  triggerSearch: () => {},
});
