import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { EventModel, EventModelFieldNames } from '@/modules/events/types/EventModel';
import { GridOptions, RowDoubleClickedEvent, ValueGetterParams } from 'ag-grid-community';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import { useTranslation } from '@/lib';

interface LocationEventsGridProps {
  data?: EventModel[];
  isError: boolean;
}

export const LocationEventsGrid: FunctionComponent<LocationEventsGridProps> = ({ data, isError }) => {
  const { t } = useTranslation();
  if (!data) {
    return <div>{t('noData')}</div>;
  }
  const navigate = useNavigate();

  const customGridOptions: GridOptions<EventModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<EventModel>) {
      navigate(`/app/events/${event.data?.id}`);
    },
  };

  const columns: ColDefOrGroup<EventModel>[] = [
    {
      field: EventModelFieldNames.OccurredAt,
      cellRenderer: RelativeDateCellRenderer,
      width: 175,
      headerName: t('occurred_at'),
    },
    {
      field: EventModelFieldNames.AssetCode,
      headerName: t('location.asset_code'),
    },
    {
      headerName: t('type'),
      valueGetter: (params: ValueGetterParams<EventModel>) => {
        return params.data?.type;
      },
      flex: 1,
    },
  ];

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      {isError ? <ErrorLoadingDataAlert /> : <PoGrid isLoading={false} colDefs={columns} rowData={data} gridOptions={customGridOptions} />}
    </div>
  );
};
