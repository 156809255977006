import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { FunctionComponent, useState } from 'react';
import { LocationsBalanceGrid } from '../components/LocationsBalanceGrid';
import { useApiCall } from '@/hooks/useApiCall';
import { balanceService } from '../api/balances/balance.service';
import { BalancesAtLocationModel } from '../models/BalanceAtLocationModel';
import FilterBar from '@/components/filterbar/FilterBar';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import LocationFilter from '@/components/filterbar/filters/LocationFilter';
import dayjs from 'dayjs';
import LabelValuesFilter from '@/components/filterbar/filters/LabelValuesFilter';
import LocationHierarchyToggleButton from '@/modules/locations/components/LocationHierarchyToggleButton';
import { LocationsBalanceisHierarchicalGrid } from '../components/LocationsBalanceisHierarchicalGrid';
import TextFilter from '@/components/filterbar/filters/TextFilter';
import { DisplayMode } from '@/types/display-mode.enum';
import { useTranslation } from '@/lib';

interface BalanceOverviewPageProps {}

const BalanceOverviewPage: FunctionComponent<BalanceOverviewPageProps> = () => {
  const { t } = useTranslation();

  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.INDIVIDUAL);
  const [currentFilterValues, setCurrentFilterValues] = useState<FilterValues>({
    searchText: '',
  });

  const { data, isError, isLoading, setApiCallArg } = useApiCall<BalancesAtLocationModel[]>(() => {
    if (displayMode === DisplayMode.HIERARCHICAL) {
      return balanceService.getBalancesForLocationsHierarchical();
    } else {
      return balanceService.getBalancesForLocations();
    }
  });

  function onFilterChange(filterValues: FilterValues) {
    setCurrentFilterValues(filterValues);
  }

  const handleSearch = (filterValues: FilterValues) => {
    searchWithCurrentFilterValues(displayMode, filterValues);
  };

  function onBalancenDisplayChanged(displayMode: DisplayMode) {
    setDisplayMode(displayMode);
    searchWithCurrentFilterValues(displayMode, currentFilterValues);
  }

  function searchWithCurrentFilterValues(displayMode: DisplayMode, filterValues: FilterValues) {
    if (!filterValues) {
      return;
    }

    // clearSelections();

    if (displayMode === DisplayMode.HIERARCHICAL) {
      setApiCallArg(() =>
        balanceService.getBalancesForLocationsHierarchical({
          query: filterValues.searchText ?? '',
          labelValueIds: filterValues.labelValueIds,
          locationId: filterValues.locationId,
        }),
      );
    } else {
      setApiCallArg(() =>
        balanceService.getBalancesForLocations({
          query: filterValues.searchText ?? '',
          labelValueIds: filterValues.labelValueIds,
          locationId: filterValues.locationId,
        }),
      );
    }
  }

  return (
    <TabbedPageLayout>
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col  ">
          <div className="mb-2 flex items-center justify-between">
            <FilterBar
              onSearch={handleSearch}
              onChange={onFilterChange}
              initialFilterValues={{
                dateFrom: dayjs().subtract(1, 'month'),
              }}
              showAsPopover
            >
              <div className="flex flex-col gap-y-2 w-96">
                <div className="">
                  <TextFilter filterFieldName={FilterFieldName.searchText} label={t('search')} />
                </div>
                <div className="">
                  <LocationFilter label={t('location.name')} />
                </div>
                <div className="w-full">
                  <LabelValuesFilter filterFieldName={FilterFieldName.labelValueIds} />
                </div>
              </div>
              <FilterBarSearchButton isLoading={isLoading} />
            </FilterBar>
            {/* {data !== undefined && !isLoading && (
                    <div className="h-full  self-end pb-1  pl-3">
                      {formatNumber(data?.length)}
                      {data?.length === EVENT_FETCH_LIMIT && '+'} {data?.length === 1 ? 'Event' : 'Events'} Found{' '}
                      {data.length >= EVENT_FETCH_LIMIT && `(Showing first ${formatNumber(data?.length)})`}
                    </div>
                  )} */}

            <div>
              <LocationHierarchyToggleButton displayMode={displayMode} onChange={onBalancenDisplayChanged} reverseOrder />
            </div>
          </div>
          {displayMode === DisplayMode.HIERARCHICAL ? (
            <LocationsBalanceisHierarchicalGrid data={data} isError={isError} isLoading={isLoading} />
          ) : (
            <LocationsBalanceGrid data={data} isError={isError} isLoading={isLoading} />
          )}
        </div>
      </TabbedPageLayoutBody>
    </TabbedPageLayout>
  );
};

export default BalanceOverviewPage;
