import { z } from 'zod';
import { TrackerType } from '../../api/trackers/tracker.contracts';

export const baseTrackerProps = z
  .object({
    type: z.nativeEnum(TrackerType),
    barcode: z.string().nullish(),
    epc: z.string().nullish(),
    deviceId: z.string().nullish(),
  })
  .superRefine((values, ctx) => {
    if (!values.barcode && values.type === TrackerType.Barcode) {
      ctx.addIssue({
        message: 'Barcode must be filled in.',
        code: z.ZodIssueCode.custom,
        path: ['barcode'],
      });
    }
    if (!values.epc && values.type === TrackerType.RFID) {
      ctx.addIssue({
        message: 'Epc must be filled in.',
        code: z.ZodIssueCode.custom,
        path: ['epc'],
      });
    }
    if (!values.deviceId && values.type === TrackerType.UnaTag) {
      ctx.addIssue({
        message: 'Device Identifier must be filled in.',
        code: z.ZodIssueCode.custom,
        path: ['deviceId'],
      });
    }
  });

export const TrackerFormSchema = baseTrackerProps;
export const TrackerCreateFormSchema = baseTrackerProps;

export type TrackerFormModel = z.infer<typeof TrackerFormSchema>;
