import { PoGrid } from '@/components/grid/PoGrid';
import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiCall } from '@/hooks/useApiCall';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { trackerService } from '../../api/trackers/tracker.service';
import { TrackerModel } from '../../types/TrackerModel';
import { GridOptions, RowDoubleClickedEvent, ValueGetterParams } from 'ag-grid-community';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { TrackerTypeCellRenderer } from '@/components/grid/cells/TrackerTypeCellRenderer';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { AddOutlined } from '@mui/icons-material';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import FilterBar from '@/components/filterbar/FilterBar';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import TextFilter from '@/components/filterbar/filters/TextFilter';
import { FilterValues } from '@/components/filterbar/FilterBarContext';
import { ColumnID } from '@/components/grid/column-ids';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import ResultDataText from '@/components/filterbar/ResultDataText';
import { useTranslation } from '@/lib';

const TRACKER_FETCH_LIMIT = 1000;

export const TrackersPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading, isError, setApiCallArg } = useApiCall<TrackerModel[]>(() =>
    trackerService.getAll({
      limit: TRACKER_FETCH_LIMIT,
    }),
  );

  const customGridOptions: GridOptions<TrackerModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<TrackerModel, unknown>) {
      navigate(`../detail/${event.data?.id}`);
    },
  };

  const gridColumns: ColDefOrGroup<TrackerModel>[] = [
    {
      colId: ColumnID.TRACKER_TYPE,
      field: 'type',
      cellRenderer: TrackerTypeCellRenderer,
      headerName: t('tracker.type.title'),
    },
    {
      colId: ColumnID.TRACKER_NAME,
      valueGetter: (params: ValueGetterParams<TrackerModel>) => {
        if (!params.data) {
          return '';
        }
        return params.data?.uniqueIdentifier;
      },
      headerName: t('tracker.unique_identifier'),
      flex: 1,
    },
  ];

  const handleSearch = (filterValues: FilterValues) => {
    setApiCallArg(() =>
      trackerService.getAll({
        searchText: filterValues.searchText,
        limit: TRACKER_FETCH_LIMIT,
      }),
    );
  };

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          actions={
            <RequirePermissionComponent permission={Permission.TRACKERS_EDIT}>
              <Button startIcon={<AddOutlined></AddOutlined>} onClick={() => navigate('../create')}>
                {t('tracker.add')}
              </Button>
            </RequirePermissionComponent>
          }
        >
          <TabbedLayoutTopBarHeader icon={null}>{t('tracker.title')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        {isError ? (
          <ErrorLoadingDataAlert />
        ) : (
          <div className="flex h-full flex-col">
            <div className="mb-2 flex items-center justify-between">
              <FilterBar onSearch={handleSearch}>
                <div className="w-44">
                  <TextFilter label={t('search')} />
                </div>

                <FilterBarSearchButton isLoading={isLoading} />
                {data !== undefined && !isLoading && (
                  <ResultDataText data={data} name={t('tracker.tracker', {count: data.length ?? 0})} maxLength={TRACKER_FETCH_LIMIT} />
                )}
              </FilterBar>
            </div>
            <PoGrid isLoading={isLoading} colDefs={gridColumns} rowData={data} gridOptions={customGridOptions} disableResizeColumnsToFit />
          </div>
        )}
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
