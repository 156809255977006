import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { AssetTypeCreateFormSchema, AssetTypeFormModel, AssetTypeFormSchema } from './AssetTypeFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import AssetTypeFormDisplay from './AssetTypeFormDisplay';

interface AssetTypeFormLogicProps {
  initialValues: AssetTypeFormModel;
  onSubmit: (data: AssetTypeFormModel) => Promise<boolean>;
  isCreate?: boolean;
}

const AssetTypeFormLogic: FunctionComponent<AssetTypeFormLogicProps> = ({ initialValues, onSubmit, isCreate }) => {
  const form = useForm<AssetTypeFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? AssetTypeCreateFormSchema : AssetTypeFormSchema),
  });

  const handleSubmit = async (data: AssetTypeFormModel) => {
    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return <AssetTypeFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} />;
};

export default AssetTypeFormLogic;
