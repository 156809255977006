import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import DeviceFormApi from '../../components/device-form/DeviceFormApi';
import { BackButton } from '@/components/BackButton';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedPageLayout } from '@/modules/application/layouts/TabbedPageLayout';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Skeleton, Tab } from '@mui/material';
import { deviceService } from '../../api/device/device.service';
import { DeviceModel } from '../../types/DeviceModel';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { useTranslation } from '@/lib';

interface DeviceDetailPageProps {
  isCreate?: boolean;
}

export const DeviceDetailPage: FunctionComponent<DeviceDetailPageProps> = ({ isCreate }) => {
  const { deviceId } = useParams();
  const { t } = useTranslation();
  // return <DeviceFormApi isCreate={isCreate} deviceId={deviceId ? Number.parseInt(deviceId) : undefined} />;

  const [value, setValue] = useState('1');
  const [device, setDevice] = useState<DeviceModel | undefined>(undefined);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (deviceId) {
      deviceService.getById(Number.parseInt(deviceId)).then((device) => {
        if (device.isSuccess) {
          setDevice(device.payload);
        }
      });
    }
  }, []);

  return (
    <TabContext value={value}>
      <TabbedLayout
        topBar={
          <TabbedLayoutTopBar>
            <BackButton pathname="../" />
            <TabbedLayoutTopBarHeader icon={null}>
              {isCreate ? t('device.new') : device?.name ? `${t('device.device')} - ${device.name}` : <Skeleton width={200} />}
            </TabbedLayoutTopBarHeader>
            <TabbedLayoutTopBarVerticalSeperator />
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Details" value="1" />
            </TabList>
          </TabbedLayoutTopBar>
        }
      >
        <TabbedPageLayout>
          <TabbedPageLayoutBody>
            <div className="flex h-full flex-grow flex-col ">
              <TabPanel className="p-0" value="1" style={{ flexGrow: 1, height: '100%' }}>
                <DeviceFormApi isCreate={isCreate} deviceId={deviceId ? Number.parseInt(deviceId) : undefined} />
              </TabPanel>
            </div>
          </TabbedPageLayoutBody>
        </TabbedPageLayout>
      </TabbedLayout>
    </TabContext>
  );
};
