import { Drawer, IconButton, Portal } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useApiCall } from '@/hooks/useApiCall';
import LoadingBlock from '@/components/feedback/LoadingBlock';

import { useDate } from '@/hooks/useDate';
import { LocationMap } from '@/modules/map/components/LocationMap';
import { assetService } from '../../api/assets/asset.service';
import { TrackerUniqueIdentifierChip } from '@/modules/trackers/components/TrackerUniqueIdentifierChip';
import { trackerUniqueIdentifier } from '@/modules/trackers/lib/tracker.utils';
import { eventService } from '@/modules/events/api/events/event.service';
import { useTranslation } from '@/lib';

interface AssetInfoDrawerProps {
  assetId: number;
  latitude: number;
  longitude: number;
  isOpen: boolean;
  onClose: () => void;
}

const AssetInfoDrawer: FunctionComponent<AssetInfoDrawerProps> = ({ assetId, isOpen, onClose, latitude, longitude }) => {
  const { data, fetchData, isLoading, setApiCallArg } = useApiCall(() => assetService.getById(assetId));
  const {
    data: eventData,
    fetchData: fetchEventData,
    isLoading: isEventDataLoading,
    setApiCallArg: setEventDataApiCallArg,
  } = useApiCall(() => eventService.get({ assetId }));

  const { formateRelativeDate } = useDate();
  const [locationMarker, setLocationMarker] = useState<[number, number] | undefined>(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    setApiCallArg(() => assetService.getById(assetId));
    setEventDataApiCallArg(() => eventService.get({ assetId }));
  }, [assetId]);

  useEffect(() => {
    if (data) {
      if (longitude && latitude) {
        setLocationMarker([longitude, latitude]);
      } else {
        setLocationMarker(undefined);
      }
    }
  }, [data]);

  return (
    <Portal>
      <Drawer anchor={'right'} open={isOpen} hideBackdrop variant="persistent">
        <div className="flex h-full  w-[512px] flex-col  justify-between  px-6 py-4">
          <div className="flex h-full  flex-col ">
            {!data || !eventData || isLoading || isEventDataLoading ? (
              <LoadingBlock />
            ) : (
              <>
                {/* header */}
                <div className="div flex items-center justify-between">
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 dark:text-gray-400 ">Asset</div>
                    <div className="text-lg font-medium ">
                      {data.code}
                      <span className="pl-1 text-base text-gray-500 dark:text-gray-400 ">
                        {t('last_seen')} {formateRelativeDate(eventData[0] && eventData[0].occurredAt)}
                      </span>
                    </div>
                  </div>
                  <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <Close />
                  </IconButton>
                </div>
                {/* content */}
                <div className="flex  h-full flex-col ">
                  <div className="mt-4 flex flex-col">
                    <div className="mb-1 font-medium">
                      {t('location.name')}: {data.location?.name}
                    </div>
                    <LocationMap locationMarker={locationMarker} />
                  </div>
                  <div className="my-2 flex  flex-grow flex-col">
                    <div className="mb-1 text-lg font-medium ">{t('tracker.tracker_other')}</div>
                    {data.trackers?.map((tracker) => (
                      <div className="flex items-center" key={tracker.id}>
                        <TrackerUniqueIdentifierChip trackerType={tracker.type} uniqueIdentifier={trackerUniqueIdentifier(tracker, t)} />
                      </div>
                    ))}
                  </div>
                  {/* <div>{JSON.stringify(data)}</div> */}
                </div>
              </>
            )}
          </div>
          <div className="w-full"></div>
        </div>
      </Drawer>
    </Portal>
  );
};

export default AssetInfoDrawer;
