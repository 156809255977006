import { FunctionComponent } from 'react';
import { EChartsOption } from 'echarts';
import { ReactECharts } from '@/components/chart/ReactEcharts';
import dayjs from 'dayjs';
import { BalanceLocationDTO } from '@/modules/balances/api/balances/balance.contracts';
import { useTranslation } from '@/lib';
import { GroupPerTimeType } from '@/modules/dashboards/components/widgets/events-over-time-widget/group-per-time-type.enum';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import NoDataBlock from '@/components/feedback/NoDataBlock';

interface BalanceOverTimeGraphProps {
  balancesOverTime: BalanceLocationDTO[];
  isLoading: boolean;
  balanceOverTimeType: GroupPerTimeType;
  layoutWidth: number;
  displayLimit?: number;
}

const BalanceOverTimeGraph: FunctionComponent<BalanceOverTimeGraphProps> = ({
  balancesOverTime,
  isLoading,
  balanceOverTimeType,
  layoutWidth,
  displayLimit,
}) => {
  const { t } = useTranslation();

  // Get highest balance
  let highestBalance = 0;
  balancesOverTime.forEach((balanceLocation) => {
    balanceLocation.balances.forEach((balance) => {
      if (balance.balance > highestBalance) {
        highestBalance = balance.balance;
      }
    });
  });

  // If there is a display limit, slice the balances, but first order them by highest balance
  if (displayLimit && balancesOverTime.length > displayLimit) {
    balancesOverTime = balancesOverTime
      .sort((a, b) => {
        const aHighestBalance = a.balances.reduce((acc, balance) => (balance.balance > acc ? balance.balance : acc), 0);
        const bHighestBalance = b.balances.reduce((acc, balance) => (balance.balance > acc ? balance.balance : acc), 0);
        return bHighestBalance - aHighestBalance;
      })
      .slice(0, displayLimit);
  }

  // Calculate left margin based on highest balance
  let leftMargin = 30;
  if (highestBalance > 100) leftMargin += 8;
  if (highestBalance > 1000) leftMargin += 8;
  if (highestBalance > 10000) leftMargin += 8;
  if (highestBalance > 100000) leftMargin += 8;

  // Calculate bottom margin based on number of balances and length of asset type names
  let bottomMargin = 50;
  const balanceOverTimeNameLengthTotal =
    balancesOverTime.reduce((acc, balanceLocation) => acc + balanceLocation.assetTypeName.length, 0) * 8 + balancesOverTime.length * 20;

  // Calculate the width of the widget
  const widgetLineWidth = layoutWidth;

  // Calculate the bottom margin based on the length of the asset type names
  if (balanceOverTimeNameLengthTotal / widgetLineWidth > 1) bottomMargin = 70;
  if (balanceOverTimeNameLengthTotal / widgetLineWidth > 2) bottomMargin = 90;
  if (balanceOverTimeNameLengthTotal / widgetLineWidth > 3) bottomMargin = 115;

  const option: EChartsOption = {
    grid: {
      left: leftMargin,
      top: 10,
      right: 20,
      bottom: bottomMargin,
    },
    xAxis: {
      type: 'category',
      data:
        balancesOverTime.length > 0
          ? balancesOverTime[0].balances.map((balance) => {
              switch (balanceOverTimeType) {
                case GroupPerTimeType.HOUR:
                  return dayjs(balance.date).format('HH:mm');
                case GroupPerTimeType.DAY:
                  return dayjs(balance.date).format('D MMM');
                case GroupPerTimeType.WEEK:
                  return 'Wk ' + dayjs(balance.date).week();
                default:
                  return '';
              }
            })
          : [],
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: balancesOverTime.map((balanceLocation) => ({
      name: `${balanceLocation.assetTypeName}`,
      type: 'line',
      data: balanceLocation.balances.map((balance) => balance.balance),
    })),
    tooltip: {
      trigger: 'axis',
      renderMode: 'html',
      appendTo: 'body',
      extraCssText: 'z-index: 9999999;', // This is needed to make sure the tooltip is on top of the drawer
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      bottom: 5,
      orient: 'horizontal',
      align: 'auto',
      padding: [0, 0, 0, 0],
      data: balancesOverTime.map((balanceLocation) => `${balanceLocation.assetTypeName}`),
    },
  };

  if (isLoading) {
    return <LoadingBlock />;
  }

  if (balancesOverTime.length === 0) {
    return <NoDataBlock type={'light-block'} classNames="mt-2" />;
  }

  return (
    <ReactECharts
      option={option}
      settings={{
        notMerge: true,
      }}
    />
  );
};

export default BalanceOverTimeGraph;
