import { ChangeEvent, FunctionComponent, useContext } from 'react';
import { SettingsContext } from '../contexts/settings.context';
import { Switch } from '@mui/material';
import { SettingKey } from '../api/settings/setting.contracts';
import { SettingEditor } from '../components/SettingEditor';
import { SettingsGroup } from '../components/SettingsGroup';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import SettingsGroupList from '../components/SettingsGroupList';
import { useUser } from '@/modules/users/contexts/UserContext';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useTranslation } from '@/lib';

interface EventSettingsPageProps {}

export const EventSettingsPage: FunctionComponent<EventSettingsPageProps> = () => {
  const { getSettingByKey, updateSettingValueByKey } = useContext(SettingsContext);
  const hasEditEventSettingsPermission = useUser().hasPermission(Permission.EVENT_SETTINGS_EDIT);
  const autoGenerateTrackersSetting = getSettingByKey(SettingKey.GENERATE_NEW_TRACKER_WHEN_UNKNOWN);
  const { t } = useTranslation();

  async function onAutoGenerateTrackersChanged(event: ChangeEvent<HTMLInputElement>, checked: boolean) {
    updateSettingValueByKey({
      key: SettingKey.GENERATE_NEW_TRACKER_WHEN_UNKNOWN,
      value: checked,
      showToast: true,
      toastTitle: `Updated setting: 'Automatically create Trackers'`,
    });
  }

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar>
          <TabbedLayoutTopBarHeader icon={null}>{t('setting.event.title')}</TabbedLayoutTopBarHeader>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        <SettingsGroupList>
          <SettingsGroup title={t('setting.event.creation_title')} subtitle={t('setting.event.creation_description')}>
            <SettingEditor
              title={t('setting.event.auto_create_trackers_title')}
              description={t('setting.event.auto_create_trackers_description')}
              prefixSlot={
                <Switch
                  defaultChecked={autoGenerateTrackersSetting?.value as boolean}
                  onChange={onAutoGenerateTrackersChanged}
                  disabled={!hasEditEventSettingsPermission}
                />
              }
            />
          </SettingsGroup>
        </SettingsGroupList>
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
