export const LABELS_TRANSLATIONS_ENGLISH = {
  label: {
    title: 'Labels',
    assignments: 'Assignments',
    value: 'Value',
    values: 'Values',
    label_one: 'Label',
    label_other: 'Labels',
    create: 'Create Label',
    new: 'New Label',
    created: 'Label created',
    updated: 'Label updated',
    deleted: 'Label deleted',
    name: 'Label Name',
    color: 'Color',
    add_value: 'Add Value',
    value_caption: 'Add one or more Label values that can help categorize Locations.',
    delete_confirmation: 'Are you sure you would like to delete this label?',
    label_values: 'Label Values',
    helper_text: 'Select one or more label values.',

    errors: {
      permission: 'You do not have permission to edit labels',
      duplicate: 'The name is already in use, and must be unique. Please provide a different one.',
      creating: 'Error creating Label',
      updating: 'Error updating Label',
      deleting: 'Error deleting Label',
      values_unique: 'Values must be unique',
      values_empty: 'Values cannot be empty',
    },
  },
};
