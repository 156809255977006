import { createContext, useState, useEffect, FC, PropsWithChildren } from 'react';

export interface TimerContextProps {
  seconds: number;
  loopCount: number;
}

export const TimerContext = createContext<TimerContextProps>({
  seconds: 0,
  loopCount: 0,
});

interface TimerProviderProps extends PropsWithChildren {
  initialSeconds: number;
}

export const TimerProvider: FC<TimerProviderProps> = ({ children, initialSeconds }) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [loopCount, setLoopCount] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          setLoopCount((prevLoopCount) => prevLoopCount + 1);
          return initialSeconds;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const value: TimerContextProps = { seconds, loopCount };

  return <TimerContext.Provider value={value}>{children}</TimerContext.Provider>;
};
