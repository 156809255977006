import { PoGrid } from '@/components/grid/PoGrid';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridOptions, RowDoubleClickedEvent, ValueGetterParams } from 'ag-grid-community';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { EventModel, EventModelFieldNames } from '@/modules/events/types/EventModel';
import { RelativeDateCellRenderer } from '@/components/grid/cells/RelativeDateCellRenderer';
import { useTranslation } from '@/lib';

interface LocationEventsGridProps {
  data?: EventModel[];
  isError: boolean;
}

export const LocationEventsGrid: FunctionComponent<LocationEventsGridProps> = ({ data, isError }) => {
  if (!data) {
    return <div>No Data</div>;
  }
  const { t } = useTranslation();
  const navigate = useNavigate();

  const customGridOptions: GridOptions<EventModel> = {
    onRowDoubleClicked(event: RowDoubleClickedEvent<EventModel, unknown>) {
      navigate(`/app/events/${event.data?.id}`);
    },
  };

  const columns: ColDefOrGroup<EventModel>[] = [
    {
      field: EventModelFieldNames.AssetCode,
    },
    {
      field: EventModelFieldNames.OccurredAt,
      cellRenderer: RelativeDateCellRenderer,
    },
    {
      headerName: 'Type',
      valueGetter: (params: ValueGetterParams<EventModel>) => {
        return params.data?.type;
      },
      flex: 1,
    },
    {
      headerName: 'Source',
      valueGetter: (params: ValueGetterParams<EventModel>) => {
        return t(`event.event_source.${params.data?.eventSource}`);
      },
    },
  ];

  return (
    <div className="flex h-full flex-1 flex-grow flex-col">
      {isError ? <ErrorLoadingDataAlert /> : <PoGrid isLoading={false} colDefs={columns} rowData={data} gridOptions={customGridOptions} />}
    </div>
  );
};
