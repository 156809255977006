import { useFormatting } from '@/hooks/useFormatting';
import { PagePaginationResultDto } from '@/lib/api/pagination.page.dto';
import { FunctionComponent } from 'react';

interface PagedResultDataTextProps {
  data?: PagePaginationResultDto<unknown>;
  name: string;
}

const PagedResultDataText: FunctionComponent<PagedResultDataTextProps> = ({ data, name }) => {
  const { formatNumber } = useFormatting();

  if (!data) {
    return null;
  }

  return (
    <div className="">
      {formatNumber(data?.totalElements)}
      &nbsp; {name} found
    </div>
  );
};

export default PagedResultDataText;
