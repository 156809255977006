import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { FilterFieldName, FilterValues, isFilterFieldName } from '@/components/filterbar/FilterBarContext';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import { useCache } from '@/providers/cache-provider/CacheProvider';
import { ApplicationLogCategory, ApplicationLogSeverity } from '@/modules/application-log/api/application-log.contracts';
import { EventType } from '@/modules/events/api/events/event.contracts';
import { PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';

const useQueryParamsFilters = (initialFilterValues: FilterValues, cacheKey: CacheKey) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getItem, setItem, hasItem } = useCache();

  const getFilterValuesFromParams = (params: URLSearchParams) => {
    const filterValues: FilterValues = { ...initialFilterValues };

    params.forEach((value, key) => {
      if (isFilterFieldName(key)) {
        switch (key) {
          case FilterFieldName.dateFrom:
          case FilterFieldName.dateTo:
            filterValues[key] = dayjs(value);
            break;
          case FilterFieldName.labelValueIds:
          case FilterFieldName.assetTypeIds:
            filterValues[key] = value.split(',').map((id) => Number.parseInt(id));
            break;
          case FilterFieldName.assetId:
          case FilterFieldName.locationId:
          case FilterFieldName.assetTypeId:
            filterValues[key] = Number.parseInt(value);
            break;
          case FilterFieldName.eventType:
            filterValues[key] = value as EventType;
            break;
          case FilterFieldName.logCategory:
            filterValues[key] = value as ApplicationLogCategory;
            break;
          case FilterFieldName.logSeverity:
            filterValues[key] = value as ApplicationLogSeverity;
            break;
          case FilterFieldName.messageId:
          case FilterFieldName.searchText:
            filterValues[key] = value;
            break;
          case FilterFieldName.pageNumber:
            filterValues[key] = Number.parseInt(value);
            break;
          case FilterFieldName.sortOption:
            filterValues[key] = value as PageSortOption;
            break;
          case FilterFieldName.sortDirection:
            filterValues[key] = value as PageSortOrder;
            break;
          case FilterFieldName.stayTimeInDays:
            filterValues[key] = Number.parseInt(value);
        }
      }
    });
    return filterValues;
  };

  const getInitialFilterValues = (initialFilterValues: FilterValues): FilterValues => {
    if (hasItem(cacheKey)) {
      return {
        ...initialFilterValues,
        ...(getItem<FilterValues>(cacheKey) as FilterValues),
      };
    } else {
      const filterValuesFromURL = getFilterValuesFromParams(searchParams);

      return {
        ...initialFilterValues,
        ...filterValuesFromURL,
      };
    }
  };

  const filters = useRef<FilterValues>(getInitialFilterValues(initialFilterValues));

  const updateUrlWithFilters = (filterValues: FilterValues) => {
    const newSearchParams = new URLSearchParams();
    Object.entries(filterValues).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        newSearchParams.set(key, value.toString());
      } else {
        newSearchParams.delete(key);
      }
    });
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (hasItem(cacheKey)) {
      const cachedFilterValues = getItem<FilterValues>(cacheKey) as FilterValues;
      updateUrlWithFilters(cachedFilterValues);
    }
  }, [cacheKey, getItem, hasItem, setSearchParams]);

  useEffect(() => {
    setItem(cacheKey, filters.current);
  }, [filters.current]);

  const setFiltersToUrl = (filterValues: FilterValues) => {
    updateUrlWithFilters(filterValues);
    setItem(cacheKey, filterValues);

    filters.current = filterValues;
  };

  return { setFiltersToUrl, filters };
};

export default useQueryParamsFilters;
