import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FunctionComponent } from 'react';
import TextLink from '@/components/TextLink';
import { AssetTypeTotalModel } from '../types/AssetTypeTotalModel';
import { DisplayMode } from '@/types/display-mode.enum';
import { useTranslation } from '@/lib';

interface AssetTypeTotalTableProps {
  assetTypeTotals: AssetTypeTotalModel[];
  displayMode?: DisplayMode;
}

export const AssetTypeTotalTable: FunctionComponent<AssetTypeTotalTableProps> = ({
  assetTypeTotals,
  displayMode = DisplayMode.INDIVIDUAL,
}) => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="font-bold">{t('assetTypes.assetType')}</TableCell>
            <TableCell className="font-bold" align="right">
              {t('total')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assetTypeTotals.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <TextLink to={{ pathname: `/app/configuration/assets/types/${row.id}` }}>{row.name}</TextLink>
              </TableCell>
              <TableCell align="right">{displayMode === DisplayMode.INDIVIDUAL ? row.individualBalance : row.hierarchicalBalance}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
