import { ActionBar } from '@/modules/application';
import { Card, CardContent, Typography, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { DeviceFormModel } from './DeviceFormModel';
import { LoadingButton } from '@mui/lab';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { useUser } from '@/modules/users/contexts/UserContext';
import { useTranslation } from '@/lib';

interface DeviceFormDisplayProps {
  form: UseFormReturn<DeviceFormModel>;
  onSubmit: (data: DeviceFormModel) => void;
  isCreate?: boolean;
}

const DeviceFormDisplay: FunctionComponent<DeviceFormDisplayProps> = ({ form, onSubmit, isCreate = false }) => {
  const { formState, register, handleSubmit, getValues, control, watch } = form;
  const { errors, isSubmitting } = formState;
  const hasTrackerEditPermission = useUser().hasPermission(Permission.TRACKERS_EDIT);
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3 ">
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" className="pb-2" color="text.secondary">
              {t('device.details')}
            </Typography>
            <div className="flex w-full flex-col gap-y-2">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.name !== undefined} helperText={errors.name?.message} label={t('device.name')} {...field} />
                )}
              />

              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.code !== undefined}
                    helperText={errors.code?.message ?? t('device.code_helper')}
                    label={t('device.code')}
                    {...field}
                  />
                )}
              />

              <Controller
                name="brand"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.brand !== undefined} helperText={errors.brand?.message} label={t('device.brand')} {...field} />
                )}
              />

              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <TextField error={errors.type !== undefined} helperText={errors.type?.message} label={t('device.type')} {...field} />
                )}
              />

              <Controller
                name="serialNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={errors.serialNumber !== undefined}
                    helperText={errors.serialNumber?.message}
                    label={t('device.serialNumber')}
                    {...field}
                  />
                )}
              />
            </div>
          </CardContent>
        </Card>
      </div>

      <ActionBar visible={hasTrackerEditPermission}>
        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
      </ActionBar>
    </form>
  );
};

export default DeviceFormDisplay;
